import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { NewStoreDialogForm } from './index';

function NewStoreDialog(props) {
  const { toggleNewStoreDialog, newStoreDialogOpen } = props;

  return (
    <Dialog
      maxWidth="sm"
      open={newStoreDialogOpen}
      onClose={toggleNewStoreDialog}
    >
      <DialogTitle>Skapa ny butik</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Skapa en ny butik genom att fylla i nedanstående, grundläggande
          information och klicka på &quot;skapa&quot;. För att komplettera med
          ytterligare butiksinformation klicka sedan på den nyskapade butiken i
          butikslistan.
        </DialogContentText>
        <NewStoreDialogForm {...props} />
      </DialogContent>
    </Dialog>
  );
}

export default NewStoreDialog;
