import { useState } from 'react';

function useFilterList() {
  const [open, setOpen] = useState({
    menu1: false,
    menu2: false,
  });

  const handleClick = (number) => {
    switch (number) {
      case 1:
        setOpen({ menu1: !open.menu1 });
        break;
      case 2:
        setOpen({ menu2: !open.menu2 });
        break;
      default:
        return;
    }
  };

  const distinct = (stores, parameter) => {
    return [
      ...new Set(
        stores
          .map((store) => store[parameter])
          .filter((item) => item !== null && item !== ''),
      ),
    ];
  };

  const objectCount = (stores, obj, key) => {
    return stores.filter((store) => store[key] === obj).length;
  };

  return {
    handleClick,
    distinct,
    objectCount,
    open,
  };
}

export default useFilterList;
