import React from 'react';
import * as styles from './header-styles';
import { Divider, Typography } from '@mui/material';
import { Breadcrumbs } from '../index';

export default function Header(props) {
  const { crumbs, pageTitle, headerButton } = props;

  return (
    <div style={styles.headerRoot}>
      <Typography variant="h4">{pageTitle}</Typography>
      <div style={styles.headerToolbar}>
        <Breadcrumbs crumbs={crumbs} />
        <div style={{ flexGrow: 1 }} />
        {headerButton}
      </div>
      <Divider sx={styles.headerDivider} />
    </div>
  );
}
