import { useCallback, useState } from 'react';
import axios from 'axios';
import { useStores } from '../../index';

function useZipCodesList() {
  const { showAlert, getStoresApiTokenRedirect } = useStores();

  const [selected, setSelected] = useState([]);

  const isSelected = (fileId) => {
    return selected.indexOf(fileId) >= 0;
  };

  const addToSelected = (fileId) => {
    if (isSelected(fileId)) {
      setSelected(selected.filter((s) => s !== fileId));
    } else {
      setSelected([...selected, fileId]);
    }
  };

  const [activateResponse, setActivateResponse] = useState({
    id: '',
    loading: false,
  });

  // eslint-disable-next-line no-unused-vars
  const [deleteResponse, setDeleteResponse] = useState({
    loading: false,
  });

  async function deleteFiles(ids, handleRequestId) {
    setDeleteResponse({ loading: true });
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/zipCodes`,
          data: ids,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Borttagning lyckades!', 'success');
            setDeleteResponse({
              loading: false,
            });
            handleRequestId();
          })
          .catch((error) => {
            showAlert('Borttagningen misslyckades!', 'error');
            setDeleteResponse({
              loading: false,
            });
            handleRequestId();
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function activateFile(handleRequestId, id) {
    setActivateResponse({
      id: id,
      loading: true,
    });
    try {
      const token = await getStoresApiTokenRedirect().then(
        (res) => res.accessToken,
      );

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/v2/zipCodes/activate/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => {
          showAlert('Aktiveringen av postnumren lyckades!', 'success');
        })
        .catch((uploadError) => {
          showAlert(
            `Något gick fel vid aktiveringen av postnumren, försök igen!`,
            'error',
          );
          throw uploadError;
        });
    } catch (error) {
      console.error(error);
    }
    setActivateResponse({
      id: '',
      loading: false,
    });
    handleRequestId();
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const toggleDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(!deleteDialogOpen);
  });

  const [editFileDialogOpen, setEditFileDialogOpen] = useState(false);
  const [fileIndex, setFileIndex] = useState(null);
  const toggleEditFileDialog = useCallback(() => {
    setEditFileDialogOpen(!editFileDialogOpen);
  });

  return {
    selected,
    addToSelected,
    isSelected,
    deleteFiles,
    setSelected,
    toggleDeleteDialog,
    deleteDialogOpen,
    toggleEditFileDialog,
    editFileDialogOpen,
    setFileIndex,
    fileIndex,
    activateFile,
    activateResponse,
  };
}

export default useZipCodesList;
