import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import * as styles from './new-zip-codes-file-dialog-styles';

function FileUpload(props) {
  const { getInputProps, getRootProps, fileRejections } = props;

  const fileRejectionItems = fileRejections.filter(
    (thing, index, self) =>
      index ===
      self.findIndex(
        (t) => t.code === thing.code && t.message === thing.message,
      ),
  );

  return (
    <div {...getRootProps()} style={styles.newZipCodesFileInputBaseStyle}>
      <input {...getInputProps()} />
      <Paper elevation={0} style={styles.newZipCodesFileDragAndDrop}>
        <Box component="div" p={5} align="center">
          <FolderIcon color="primary" fontSize="large" />
          <p>Släpp en fil här eller klicka för att välja fil</p>
        </Box>
      </Paper>

      {fileRejectionItems.map((item) => (
        <Box pt={3} sx={styles.newZipCodesFileFileRejectionMessageBox}>
          <ErrorOutlineIcon color="error" fontSize="small" />
          <Typography color="error">
            {item.errors[0].message}, try again!
          </Typography>
        </Box>
      ))}
    </div>
  );
}

export default FileUpload;
