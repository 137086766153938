import React from 'react';
import { Typography, Link, MenuItem, TextField, Switch } from '@mui/material';
import { storeEditFormsFunctions } from './functions/index';
import * as styles from './store-edit-forms-styles';

function OpeningHoursControls(props) {
  const { values, setFieldValue, handleChange, handleBlur, errors, dayOfWeek } =
    props;
  const { openingHourCell, closingHourCell, addHoursObject, weekdays } =
    storeEditFormsFunctions();

  const findNestedErrors = (field, index, hoursIndex) => {
    if (
      !errors['openingHours'] ||
      !errors['openingHours'][index] ||
      !errors['openingHours'][index].hours[hoursIndex][field]
    ) {
      return false;
    }

    return errors['openingHours'][index].hours[hoursIndex][field];
  };

  const getWeekday = (dayOfWeek) => {
    return weekdays.find((x) => x.value === dayOfWeek);
  };

  const openingHoursIndex = values.openingHours.findIndex(
    (h) => h.dayOfWeek === dayOfWeek,
  );

  const day = values.openingHours[openingHoursIndex];

  return (
    <>
      <div style={styles.StoreEditOpeningHoursControls}>
        <Typography display="inline" sx={styles.StoreEditWeekdaysLabel}>
          {getWeekday(dayOfWeek).label}
        </Typography>
        <Switch
          disabled
          name={`openinghours[${openingHoursIndex}].closed`}
          value={day.closed}
          onChange={(e) => {
            setFieldValue(
              `openingHours[${openingHoursIndex}].closed`,
              !e.target.checked,
            );
          }}
          onBlur={handleBlur}
          color="primary"
          checked={!day.closed}
        />
        <Typography display="inline">
          {values.openingHours[openingHoursIndex].closed ? 'Stängt' : 'Öppet'}
        </Typography>
      </div>

      <div>
        {!day.closed
          ? day.hours.map((openinghoursObject, hoursIndex) => (
            <div
              key={hoursIndex}
              style={styles.StoreEditOpeningHoursDropdownWrapper}
            >
              <TextField
                sx={styles.StoreEditOpeningHoursDropdown}
                required
                select
                variant="outlined"
                margin="none"
                size="small"
                name={`openingHours[${openingHoursIndex}].hours[${hoursIndex}].openingTime`}
                value={day.hours[hoursIndex].openingTime}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
                helperText={findNestedErrors(
                  'openingTime',
                  openingHoursIndex,
                  hoursIndex,
                )}
                error={
                  !!findNestedErrors(
                    'openingTime',
                    openingHoursIndex,
                    hoursIndex,
                  )
                }
              >
                {openingHourCell(day, hoursIndex).map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </TextField>

              <Typography display="inline" variant="overline">
                  &nbsp;-&nbsp;
              </Typography>

              <TextField
                sx={styles.StoreEditOpeningHoursDropdown}
                select
                required
                variant="outlined"
                margin="none"
                size="small"
                name={`openingHours[${openingHoursIndex}].hours[${hoursIndex}].closingTime`}
                value={day.hours[hoursIndex].closingTime}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
                error={findNestedErrors(
                  'closingTime',
                  openingHoursIndex,
                  hoursIndex,
                )}
              >
                {closingHourCell(day, hoursIndex).map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </TextField>
              {day.hours.length > 1 ? (
                hoursIndex === day.hours.length - 1 ? (
                  <>
                    <Link
                      style={styles.DisableLinkButton}
                      disabled
                      component="button"
                      variant="body2"
                      color="error"
                      type="button"
                      sx={styles.StoreEditRemoveLink}
                      onClick={() =>
                        setFieldValue(day.hours.splice(hoursIndex, 1))
                      }
                    >
                        TA BORT
                    </Link>
                    <Link
                      style={styles.DisableLinkButton}
                      disabled
                      component="button"
                      type="button"
                      variant="body2"
                      sx={styles.StoreEditAddLink}
                      onClick={() => {
                        setFieldValue(addHoursObject(day, hoursIndex));
                      }}
                    >
                        LÄGG TILL
                    </Link>
                  </>
                ) : (
                  <Link
                    style={styles.DisableLinkButton}
                    disabled
                    component="button"
                    variant="body2"
                    color="error"
                    type="button"
                    sx={styles.StoreEditRemoveLink}
                    onClick={() =>
                      setFieldValue(day.hours.splice(hoursIndex, 1))
                    }
                  >
                      TA BORT
                  </Link>
                )
              ) : (
                <Link
                  style={styles.DisableLinkButton}
                  disabled
                  component="button"
                  type="button"
                  variant="body2"
                  sx={styles.StoreEditAddLink}
                  onChange={handleChange}
                  onClick={() => {
                    setFieldValue(addHoursObject(day, hoursIndex));
                  }}
                >
                    LÄGG TILL
                </Link>
              )}
            </div>
          ))
          : null}
      </div>
    </>
  );
}

export default OpeningHoursControls;
