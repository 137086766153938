export const zipCodesListRoot = {
  width: '100%',
};

export const zipCodesListPaper = {
  width: '100%',
  marginBottom: '16px',
};

export const zipCodesListToolbarItem = {
  marginRight: '10px',
};

export const zipCodesListSearch = {
  position: 'relative',
  borderRadius: '4px',
};

export const zipCodesListSearchInput = {
  border: '1px solid rgba(0, 0, 0, 0.23)',
  marginRight: '16px',
  width: '100%',
  height: '35px',
};

export const zipCodesListSearchInputSvgIcon = {
  padding: '0 8px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const zipCodesListInputRoot = {
  color: 'inherit',
};

export const zipCodesListInputInput = {
  padding: '2px 2px 2px 0',
  transition: 'background-color 200ms ease-in-out',
  width: '100%',
  marginLeft: '40px',
};

export const zipCodesListInputInputPlaceholder = {
  color: 'rgba(0, 0, 0, 0.54)',
};

export const zipCodesListFileNameCell = {
  width: '30%',
};

export const zipCodesListActiveStatusChip = {
  color: 'white',
  backgroundColor: '#66bb6a',
};

export const zipCodesListComingStatusChip = {
  color: 'white',
  backgroundColor: '#f57c00',
};

export const zipCodesListInactiveStatusChip = {
  color: 'white',
  backgroundColor: '#f44336',
};
