import React from 'react';
import { Paper } from '@mui/material';
import { SimpleTabs } from './index';
import * as styles from './store-styles';

function Store(props) {
  const {
    store,
    stores,
    handleRequestId,
    setPageTitle,
    setBreadcrumbs,
    breadcrumbs,
    setFormId,
    setIsDirty,
  } = props;

  return (
    <div style={styles.storeRoot}>
      <Paper elevation={1} sx={styles.storePaper}>
        <SimpleTabs
          store={store}
          stores={stores}
          handleRequestId={handleRequestId}
          setPageTitle={setPageTitle}
          setBreadcrumbs={setBreadcrumbs}
          breadcrumbs={breadcrumbs}
          setFormId={setFormId}
          setIsDirty={setIsDirty}
        />
      </Paper>
    </div>
  );
}

export default Store;
