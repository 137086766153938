import { Alert } from '@mui/material';
import React from 'react';

function StoreErrorAlerts(props) {
  const { mappedErrors, store } = props;

  return (
    <Alert severity="error">
      {mappedErrors.stores[store.storeName].map((err) => (
        <div key={err}>{err}</div>
      ))}
    </Alert>
  );
}

export default StoreErrorAlerts;
