import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { EditFileDialogForm } from './index';

function EditFileDialog(props) {
  const { toggleEditFileDialog, editFileDialogOpen } = props;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={editFileDialogOpen}
      onClose={toggleEditFileDialog}
    >
      <DialogTitle>Redigera</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Redigera aktiveringsdatum och beskrivning. Vill du redigera filens
          innehåll får du helt enkelt ta bort filen och ladda upp den på nytt.
        </DialogContentText>
        <EditFileDialogForm {...props} />
      </DialogContent>
    </Dialog>
  );
}

export default EditFileDialog;
