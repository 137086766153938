import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Typography, List, ListItem } from '@mui/material';
import { ExceptionalOpeningHoursDialog, OpeningHours } from './index';
import { storeEditFormsFunctions } from './functions/index';
import { useStoreEditForms } from './hooks/index';
import * as styles from './store-edit-forms-styles';
import RedirectNewUI from './redirectNewUi';

function StoreOpeningHoursForm(props) {
  const { updateStore } = useStoreEditForms();
  const { weekdays, dropdownHours } = storeEditFormsFunctions();
  const {
    handleRequestId,
    store,
    usePageTitle,
    useBreadcrumbs,
    useFormId,
    PromptIfDirty,
  } = props;

  const formId = 'store-openingHours-form';

  usePageTitle('Öppettider');
  useBreadcrumbs([
    { text: 'Butiksregister', link: '/' },
    { text: 'Butiker', link: '/butiker' },
    { text: store.storeName },
  ]);
  useFormId(formId);

  return (
    <div>
      {store && (
        <Formik
          initialValues={{
            ...store,
            openingHours:
              store.openingHours.length > 0
                ? store.openingHours
                : weekdays.map((weekday) => ({
                  closed: true,
                  dayOfWeek: weekday.value,
                  hours: [
                    {
                      openingTime: '10:00',
                      closingTime: '19:00',
                    },
                  ],
                })) ?? [],
            exceptionalOpeningHours: store.exceptionalOpeningHours ?? [],
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            updateStore(values, handleRequestId, store.storeId);
          }}
          validationSchema={Yup.object().shape({
            openingHours: Yup.array().of(
              Yup.object().shape({
                closed: Yup.bool(),
                dayOfWeek: Yup.number().min(0).max(6),
                hours: Yup.array().of(
                  Yup.object().shape({
                    openingTime: Yup.string()
                      .test(
                        'test-name',
                        'öppningtid måste infalla före stängningstid',
                        function(item) {
                          return (
                            item.localeCompare(this.parent['closingTime']) < 0
                          );
                        },
                      )
                      .required('Ange öppningstid')
                      .oneOf(dropdownHours())
                      .max(5, 'Får inte vara längre än 5 tecken.'),
                    closingTime: Yup.string()
                      .required('Ange stängningstid')
                      .oneOf(dropdownHours())
                      .max(5, 'Får inte vara längre än 5 tecken.'),
                  }),
                ),
              }),
            ),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleReset,
          }) => (
            <div>
              <RedirectNewUI />
              <Form
                onSubmit={handleSubmit}
                id={formId}
              >
                <PromptIfDirty />
                <OpeningHours
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <div>
                  <Typography
                    variant="h5"
                    sx={styles.StoreEditFormHeadersLeftPadding}
                  >
                    Avvikande Öppettider
                  </Typography>
                  <List>
                    {values.exceptionalOpeningHours.map((object, index) => (
                      <ListItem
                        sx={styles.StoreEditListItemSpaceBetween}
                        key={index}
                        divider
                      >
                        <div>
                          <Typography
                            sx={styles.StoreEditCapitalize}
                            variant="body1"
                          >
                            {new Date(object.date).toLocaleDateString()}
                            {object.name ? ` - ${object.name}` : null}
                          </Typography>
                          {object.closed
                            ? 'Stängt'
                            : object.hours.map((objectHours, hoursIndex) => (
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                key={hoursIndex}
                              >
                                {object.hours[hoursIndex].openingTime}
                                  &nbsp;-&nbsp;
                                {object.hours[hoursIndex].closingTime}
                              </Typography>
                            ))}
                        </div>
                        <div>
                        </div>
                      </ListItem>
                    ))}

                    {values.exceptionalOpeningHours.length > 0 ? (
                      <ListItem
                        align="right"
                        sx={styles.StoreEditListItemFlexEnd}
                      >
                      </ListItem>
                    ) : (
                      <ListItem align="left">
                        <Typography>
                          Det finns inga avikande öppettider för denna butik.{' '}
                          <ExceptionalOpeningHoursDialog
                            edit={false}
                            anchorText="Klicka här"
                            variant="body1"
                            store={{ ...values }}
                            updateNewExceptionalHour={(exceptionalHour) => {
                              setFieldValue('exceptionalOpeningHours', [
                                ...values.exceptionalOpeningHours,
                                exceptionalHour,
                              ]);
                            }}
                          />{' '}
                          för att lägga till en avikande öppettid.
                        </Typography>
                      </ListItem>
                    )}
                  </List>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}

export default StoreOpeningHoursForm;
