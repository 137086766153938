import React from 'react';
import { Toolbar, Tooltip, Button, InputBase } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import * as styles from './zip-codes-styles';

const SearchField = ({ handleFilter }) => {
  return (
    <div style={styles.zipCodesListSearch}>
      <div style={styles.zipCodesListSearchInputSvgIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Sök..."
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleFilter}
        sx={(styles.zipCodesListInputRoot, styles.zipCodesListInputInput)}
      />
    </div>
  );
};

function EnhancedTableToolbar(props) {
  const { selected, handleFilter, toggleDeleteDialog } = props;

  return (
    <Toolbar disableGutters>
      <SearchField handleFilter={handleFilter} />
      <div style={{ flexGrow: 1 }} />

      <Tooltip
        title="Ta bort markerade butiker"
        sx={styles.zipCodesListToolbarItem}
      >
        <span>
          <Button
            disabled={!selected.length > 0}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              toggleDeleteDialog();
            }}
          >
            Ta bort
          </Button>
        </span>
      </Tooltip>
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
