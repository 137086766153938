import { Box, Button, DialogActions } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import { useOpeningHoursDialog } from './index';
import { parseISO } from 'date-fns';

function DeleteOpeningHoursForm(props) {
  const { toggleOpeningHoursDialog, handleRequestId, setSelected, stores } =
    props;
  const { deleteExceptionalOpeningHours } = useOpeningHoursDialog();
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));

  const [date, setdate] = useState(
    new Date(new Date()).toISOString().split('T')[0],
  );
  const handleDateChange = (date) => {
    setdate(new Date(new Date(date)).toISOString().split('T')[0]);
  };

  const handleSubmit = () => {
    deleteExceptionalOpeningHours({
      date: date,
      stores: stores,
    });
    toggleOpeningHoursDialog();
    handleRequestId();
    setSelected([]);
  };

  return (
    <div>
      <Box ml={1}>
        <DatePicker
          margin="normal"
          autoOk
          format="yyyy-MM-dd"
          label="Datum"
          inputVariant="outlined"
          size="small"
          value={date !== '' ? parseISO(date) : null}
          onChange={handleDateChange}
          minDate={todayMidnight}
        />
      </Box>
      <DialogActions>
        <Button color="primary" onClick={toggleOpeningHoursDialog}>
          Avbryt
        </Button>
        <Button onClick={() => handleSubmit()} color="primary">
          Fortsätt
        </Button>
      </DialogActions>
    </div>
  );
}

export default DeleteOpeningHoursForm;
