import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

function InputField({
  handleChange,
  handleBlur,
  errors,
  values,
  touched,
  setFieldValue,
  date,
  label,
  name,
  ...rest
}) {
  return date ? (
    <DatePicker
      fullWidth
      onChange={(value) => {
        !values[name]
          ? setFieldValue(name, new Date(value.setHours(0, 0, 0, 0)))
          : setFieldValue(name, value);
      }}
      onBlur={handleBlur}
      inputVariant="outlined"
      margin="normal"
      name={name}
      value={values[name]}
      label={label}
      autoOk
      format="yyyy-MM-dd"
      slotProps={{
        textField: {
          error: !!errors[name],
          helperText: errors[name],
        },
      }}
      {...rest}
    />
  ) : (
    <TextField
      fullWidth
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      margin="normal"
      name={name}
      value={values[name]}
      label={label}
      error={!!errors[name] && !!touched[name] && !!errors[name]}
      helperText={errors[name] && touched[name] && errors[name]}
      {...rest}
    />
  );
}

export default InputField;
