import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useStores } from '../../index';

function useStoreList() {
  const { showAlert, getStoresApiTokenRedirect } = useStores();

  const [selected, setSelected] = useState([]);

  const isSelected = (storeId) => {
    return selected.indexOf(storeId) >= 0;
  };

  const addToSelected = (storeId) => {
    if (isSelected(storeId)) {setSelected(selected.filter((s) => s !== storeId));} else {setSelected([...selected, storeId]);}
  };

  const [deleteResponse, setDeleteResponse] = useState({
    loading: false,
    completed: false,
    error: false,
  });

  async function deleteStores(selected, handleRequestId) {
    setDeleteResponse({ loading: true });
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores`,
          data: { storeIds: selected },
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Borttagning lyckades!', 'success');
            setDeleteResponse({
              loading: false,
              completed: true,
              error: false,
            });
            handleRequestId();
          })
          .catch((error) => {
            showAlert('Borttagning misslyckades!', 'error');
            setDeleteResponse({
              loading: false,
              completed: true,
              error: true,
            });
            handleRequestId();
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [syncToGoogleResponse, setSyncToGoogleResponse] = useState({
    loading: false,
    completed: false,
    error: false,
    errorData: null,
  });

  async function syncStoresToGoogle(selected) {
    setSyncToGoogleResponse({ loading: true });
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores/google-sync-some`,
          data: selected,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Synkronisering till Google lyckades!', 'success');
            setSyncToGoogleResponse({
              loading: false,
              completed: true,
              error: false,
              errorData: null,
            });
            setGoogleSyncDialogOpen(false);
          })
          .catch((error) => {
            showAlert('Synkronisering till Google misslyckades!', 'error');
            setSyncToGoogleResponse({
              loading: false,
              completed: true,
              error: true,
              errorData: error?.response?.data,
            });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [openingHoursDialogOpen, setOpeningHoursDialogOpen] = useState(false);
  const toggleOpeningHoursDialog = useCallback(() => {
    setOpeningHoursDialogOpen(!openingHoursDialogOpen);
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const toggleDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(!deleteDialogOpen);
  });

  const [googleSyncDialogOpen, setGoogleSyncDialogOpen] = useState(false);
  const toggleGoogleSyncDialog = useCallback(() => {
    setGoogleSyncDialogOpen(!googleSyncDialogOpen);
  });

  useEffect(() => {
    if (!googleSyncDialogOpen) {
      setSyncToGoogleResponse({
        loading: false,
        completed: false,
        error: false,
        errorData: null,
      });
    }
  }, [googleSyncDialogOpen]);

  return {
    selected,
    addToSelected,
    isSelected,
    deleteStores,
    deleteResponse,
    setSelected,
    toggleOpeningHoursDialog,
    openingHoursDialogOpen,
    toggleDeleteDialog,
    deleteDialogOpen,
    toggleGoogleSyncDialog,
    googleSyncDialogOpen,
    syncStoresToGoogle,
    syncToGoogleResponse,
  };
}

export default useStoreList;
