export const layoutRoot = {
  minHeight: '100%',
  marginLeft: '65px',
};

export const layoutMain = {
  padding: '60px',
  flexGrow: '1',
  maxWidth: '1850px',
};
