import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { OpeningHoursDialogForm, DeleteOpeningHoursForm } from './index';
import { DialogContentText } from '@mui/material';
import * as styles from './opening-hours-dialog-styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabsPanel(props) {
  const {
    toggleOpeningHoursDialog,
    handleRequestId,
    setSelected,
    stores,
    setUpdatingValues,
  } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={styles.tabPanelRoot}>
      <AppBar
        position="static"
        style={{ backgroundColor: 'rgb(247 249 251)' }}
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          TabIndicatorProps={{ style: { background: '#1976d2' } }}
        >
          <Tab label="Ändra & skapa" {...a11yProps(0)} />
          <Tab label="Ta bort" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {value === 0 ? (
        <Box mr={2} ml={1} mt={3} mb={2}>
          <DialogContentText sx={styles.dialogContentText}>
            Välj vilket datum du vill lägga till eller ändra en avvikande
            öppettid på. Öppning och stängningstid kommer då automatiskt sättas
            till butikens ordinarie tid för just den veckodagen. Därefter kan
            man ändra ett värde i den översta raden så kommer samtliga rader
            påverkas.
          </DialogContentText>
          <OpeningHoursDialogForm
            toggleOpeningHoursDialog={toggleOpeningHoursDialog}
            handleRequestId={handleRequestId}
            setUpdatingValues={setUpdatingValues}
            setSelected={setSelected}
            stores={stores}
          />
        </Box>
      ) : null}
      {value === 1 ? (
        <Box pr={2} pl={1} pt={3} pb={2}>
          <DialogContentText sx={(styles.dialogContentText, { mb: '20px' })}>
            Välj datum som du vill ta bort avvikande öppettider för, detta berör
            alltså bara de butiker som du valt i första steget.
          </DialogContentText>
          <DeleteOpeningHoursForm
            toggleOpeningHoursDialog={toggleOpeningHoursDialog}
            handleRequestId={handleRequestId}
            setSelected={setSelected}
            stores={stores}
          />
        </Box>
      ) : null}
    </div>
  );
}

export default TabsPanel;
