import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { DialogActions, Button } from '@mui/material';
import {
  useOpeningHoursDialog,
  EnhancedTableToolbar,
  ErrorAlerts,
  Storeslist,
} from './index';

const hours = () => {
  var arr = [];
  var i;
  var j;
  for (i = 0; i < 24; i++) {
    for (j = 0; j < 2; j++) {
      arr.push((i < 10 ? '0' : null) + i + ':' + (j === 0 ? '00' : 30 * j));
    }
  }
  return arr;
};

function OpeningHoursDialogForm(props) {
  const {
    toggleOpeningHoursDialog,
    stores,
    handleRequestId,
    setSelected,
    setUpdatingValues,
  } = props;
  const { globalValues, setGlobalValues, updateExceptionalOpeningHours } =
    useOpeningHoursDialog();

  const todayMidnight = new Date(
    new Date().setHours(12, 0, 0, 0),
  ).toISOString();

  const [mappedErrors, setMappedErrors] = useState({
    toolbar: [],
    stores: [],
  });

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          date: Yup.date()
            .required('Ange ett datum för den avvikande öppettiden.')
            .min(
              todayMidnight,
              'Den avvikande öppettiden får infalla som tidigast dagens datum.',
            ),
          stores: Yup.array().of(
            Yup.object().shape({
              closed: Yup.bool(),
              openingTime: Yup.string().when('closed', {
                is: (closed) => closed === true,
                then: () =>
                  Yup.string()
                    .oneOf(hours())
                    .max(5, 'Får inte vara längre än 5 tecken.'),
                otherwise: () =>
                  Yup.string()
                    .required('Ange öppningstid för den avvikande öppettiden')
                    .oneOf(hours())
                    .max(5, 'Får inte vara längre än 5 tecken.'),
              }),
              closingTime: Yup.string().when('closed', {
                is: (closed) => closed === true,
                then: () =>
                  Yup.string()
                    .oneOf(hours())
                    .max(5, 'Får inte vara längre än 5 tecken.'),
                otherwise: () =>
                  Yup.string()
                    .required('Ange stängningstid för den avvikande öppettiden')
                    .oneOf(hours())
                    .max(5, 'Får inte vara längre än 5 tecken.'),
              }),
              name: Yup.string()
                .required(
                  'Ange benämning för den avvikande öppettiden. Ex. Kvällsöppet.',
                )
                .max(150, 'Ange en kortare benämning'),
            }),
          ),
        })}
        initialValues={{
          date: new Date(new Date().setHours(12, 0, 0, 0)).toISOString(),
          stores: stores.map((store) => ({
            openingHours: store.openingHours,
            closed: globalValues.closed,
            openingTime: globalValues.openingTime,
            closingTime: globalValues.closingTime,
            name: '',
            storeName: store.storeName,
            storeId: store.storeId,
          })),
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          updateExceptionalOpeningHours({
            date: values.date,
            stores: values.stores.map((store) => {
              return {
                closed: store.closed,
                name: store.name,
                storeId: store.storeId,
                hours: store.closed
                  ? []
                  : [
                    {
                      openingTime: store.openingTime,
                      closingTime: store.closingTime,
                    },
                  ],
              };
            }),
          });
          toggleOpeningHoursDialog();
          handleRequestId();
          setSelected([]);
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
        }) => (
          <Form onSubmit={handleSubmit}>
            <ErrorAlerts
              values={values}
              errors={errors}
              setMappedErrors={setMappedErrors}
              mappedErrors={mappedErrors}
            />
            <EnhancedTableToolbar
              hours={hours}
              globalValues={globalValues}
              values={values}
              stores={stores}
              setFieldValue={setFieldValue}
              setGlobalValues={setGlobalValues}
              handleBlur={handleBlur}
              setUpdatingValues={setUpdatingValues}
              touched={touched}
              errors={errors}
            />
            <Storeslist
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              mappedErrors={mappedErrors}
            />

            <DialogActions>
              <Button color="primary" onClick={toggleOpeningHoursDialog}>
                Avbryt
              </Button>
              <Button type="submit" disabled={isSubmitting} color="primary">
                Fortsätt
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OpeningHoursDialogForm;
