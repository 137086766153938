import React from 'react';
import Popover from '@mui/material/Popover';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Tooltip, IconButton } from '@mui/material';
import { NestedList } from './index';
import * as styles from './filter-store-list-styles';

function FilterStoresList(props) {
  const {
    filteredStoresBySearch,
    filteredStoresByCategories,
    stores,
    filteredStores,
    selectedCategories,
    isSelectedCategory,
    addToSelectedCategories,
    handleClearFilters,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenNestedList = (event) => {
    setAnchorEl(event.currentTarget);
    selectedCategories.storeType = [];
    selectedCategories.ownerGroup = [];
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title="Filtrera butiker">
        <IconButton onClick={handleOpenNestedList} aria-describedby={id}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NestedList
          filteredStoresBySearch={filteredStoresBySearch}
          filteredStoresByCategories={filteredStoresByCategories}
          stores={stores}
          filteredStores={filteredStores}
          selectedCategories={selectedCategories}
          addToSelectedCategories={addToSelectedCategories}
          isSelectedCategory={isSelectedCategory}
          handleClearFilters={handleClearFilters}
          sx={styles.filterStoresListRoot}
        />
      </Popover>
    </div>
  );
}

export default FilterStoresList;
