import React, { Fragment } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { useFilterList } from './index';
import { Button, Divider } from '@mui/material';
import * as styles from './filter-store-list-styles';

function NestedList(props) {
  const {
    filteredStoresBySearch,
    isSelectedCategory,
    addToSelectedCategories,
    handleClearFilters,
  } = props;
  const { handleClick, distinct, open } = useFilterList();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      sx={styles.filterListRoot}
    >
      <ListItemButton onClick={() => handleClick(1)}>
        <ListItemText primary="Butikstyp" />
        {open.menu1 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.menu1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {distinct(filteredStoresBySearch, 'storeType').map((type) => (
            <ListItem key={type.toString()}>
              <Checkbox
                onClick={() => {
                  addToSelectedCategories(type, 'storeType');
                }}
                color="primary"
                checked={isSelectedCategory(type, 'storeType')}
              />
              <ListItemText>
                {type === 1
                  ? 'Varuhus'
                  : type === 2
                    ? 'Utlämningsställe'
                    : type === 3
                      ? 'Pop-up butik'
                      : type === 4
                        ? 'Reklamationsenhet'
                        : null}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItemButton onClick={() => handleClick(2)}>
        <ListItemText primary="Ägargrupp" />
        {open.menu2 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.menu2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {distinct(filteredStoresBySearch, 'ownerGroup').map((type) => (
            <ListItem key={type.toString()}>
              <Checkbox
                onClick={() => {
                  addToSelectedCategories(type, 'ownerGroup');
                }}
                color="primary"
                checked={isSelectedCategory(type, 'ownerGroup')}
              />
              <ListItemText>{type}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Fragment>
        <Divider variant="middle" />
        <ListItem>
          <Button
            color="error"
            variant="outlined"
            sx={styles.filterListClearFiltersButton}
            onClick={() => handleClearFilters()}
          >
            RENSA FILTER
          </Button>
        </ListItem>
      </Fragment>
    </List>
  );
}

export default NestedList;
