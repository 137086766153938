import React from 'react';
import Button from '@mui/material/Button';

import * as styles from './store-edit-forms-styles';
function RedirectNewUI() {
  return (
    <div style={styles.RedirectionTab}>
      <div style={styles.RedirectionText}>
        Öppettider har flyttat!
      </div>
      <Button
        style={styles.RedirectionButton}
        variant="contained"
        onClick={() => window.open(window.env.OPENINGHOURS_API_URL, '_blank')}
      >
        Gå till nya öppettider
      </Button>
      <div style={styles.RedirectionText}>
        Du kan fortfarande se öppettider för 2024 nedan.
      </div>
    </div>
  );
}

export default RedirectNewUI;
