import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import { storeEditFormsFunctions } from '../../../components/store-edit-forms/functions';
import * as styles from './openinghours-file-upload-overview-styles';

const tooltipContent = (
  storeName,
  value,
  exceptionalDateName,
) => {
  return (
    <>
      {storeName}, {value}
      {exceptionalDateName && (
        <>
          <br />
          Ny titel: {exceptionalDateName}
        </>
      )}
    </>
  );
};

const OpeningHoursFileUploadTable = ({
  tableData,
}) => {
  const { headers, rows } = tableData;

  const { weekdays } = storeEditFormsFunctions();

  const getWeekDay = (num) => {
    return weekdays.filter((day) => day.value === num)[0].label;
  };

  return (
    <Paper className={styles.openingHoursWidth}>
      <TableContainer className={styles.openingHoursMaxHeight}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={`header${index}`}
                  className={styles.openingHoursTableCell}
                  variant="head"
                >
                  {isNaN(Number(header)) ? header : getWeekDay(Number(header))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={`storeRow${rowIndex}`}>
                {row.map((cellData, cellIndex) => (
                  <Tooltip
                    key={`storeRow${rowIndex}cell${cellIndex}`}
                    placement="left"
                    title={tooltipContent(
                      row[1].value,
                      isNaN(Number(headers[cellIndex]))
                        ? headers[cellIndex]
                        : getWeekDay(Number(headers[cellIndex])),
                      cellData.newExceptionalOpeningHourName,
                    )}
                  >
                    <TableCell
                      style={{
                        backgroundColor:
                          (cellData.isChanged && cellData.newExceptionalOpeningHourName)
                            ? '#4caf50'
                            : cellData.isChanged
                              ? '#ff9800'
                              : cellData.newExceptionalOpeningHourName
                                ? '#03a9f4'
                                : 'inherit',
                        whiteSpace: 'nowrap',
                      }}
                      variant="body"
                    >
                      <span>{cellData.value}</span>
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default OpeningHoursFileUploadTable;
