/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { 
  Button,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

const OpeningHoursFileUploadHelp = () => {
  const [showHelp, setShowHelp] = useState(false);

  const toggleHelp = () => setShowHelp(!showHelp);

  return (
    <>
      <p>Ladda upp CSV-fil för att justera samtliga öppettider.</p>

      <Button
        variant="text"
        endIcon={showHelp ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleHelp}
      >
        {showHelp ? 'Dölj' : 'Visa'} hjälp
      </Button>

      {showHelp && (
        <>
          <h3>Snabbguide: Exportera Excel-fil till CSV</h3>
          <ol>
            <li>
              Öppna dokumentet i Excel. Välj 'Arkiv' {'>'} 'Spara som' {'>'}{' '}
              'Bläddra'
            </li>
            <li>
              I rullistan för 'Filformat'; välj 'CSV-UTF-8' och spara filen
            </li>
          </ol>
          <p>
            När du gjort detta kan du klicka på 'Läs in fil' här ovan och välja
            den CSV-fil du just sparade.
          </p>
          <p>
            För att funktionen ska fungera smärtfritt bör Excel-filen se ut på
            följande sätt
          </p>

          <ExcelLayoutExample />

          <p>Några regler att förhålla sig till:</p>
          <ol>
            <li>
              Ovanför NAMN-raden kan det finnas en eller flera rader med
              anteckningar etc
            </li>
            <li>
              NAMN - Dessa namn används på avvikande öppettider, om det är
              ifyllt
            </li>
            <li>
              Mellan NAMN- och START-raderna kan det finnas en eller flera med
              anteckningar etc
            </li>
            <li>
              START - Denna rad indikerar att nästa rad är den första raden för
              butiksdata. Denna rad tillhandahåller också vilken sorts data det
              är: MÅN-SÖN för ordinarie öppettider eller ett datum (yyyy-mm-dd)
              för en avvikande öppettid.
            </li>
            <li>
              Första kolumnen är butikens id. Det är detta som kommer användas
              för att knyta tider till rätt butik, så denna är viktig att den
              stämmer.
              <br /> Därefter löper tiderna på.
            </li>
          </ol>
          <p>
            Bindestreck avskiljer öppning från stängning. Punkt avskiljer timmar
            från minuter. Tider kan således skrivas på följande sätt:
          </p>
          <ul>
            <li>10-19</li>
            <li>10.30-19.00</li>
            <li>10-19.00</li>
            <li>Stängt</li>
          </ul>
        </>
      )}
    </>
  );
};

const ExcelLayoutExample = () => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>A</TableCell>
          <TableCell>B</TableCell>
          <TableCell>C</TableCell>
          <TableCell>D</TableCell>
          <TableCell>E</TableCell>
          <TableCell>F</TableCell>
          <TableCell>G</TableCell>
          <TableCell>H</TableCell>
          <TableCell>I</TableCell>
          <TableCell>J</TableCell>
          <TableCell>K</TableCell>
          <TableCell>L</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>1</TableCell>
          <TableCell>...</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>2</TableCell>
          <TableCell>NAMN</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>Nyårsdagen</TableCell>
          <TableCell>Nationaldagen</TableCell>
          <TableCell>Julafton</TableCell>
          <TableCell>osv...</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>3</TableCell>
          <TableCell>...</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>4</TableCell>
          <TableCell>START</TableCell>
          <TableCell>MÅN</TableCell>
          <TableCell>TIS</TableCell>
          <TableCell>ONS</TableCell>
          <TableCell>TORS</TableCell>
          <TableCell>FRE</TableCell>
          <TableCell>LÖR</TableCell>
          <TableCell>SÖN</TableCell>
          <TableCell>2023-01-01</TableCell>
          <TableCell>2023-06-06</TableCell>
          <TableCell>2023-12-24</TableCell>
          <TableCell>osv...</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>5</TableCell>
          <TableCell>481</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>11-17</TableCell>
          <TableCell>11-16</TableCell>
          <TableCell>Stängt</TableCell>
          <TableCell>11.30-15.30</TableCell>
          <TableCell>11-16.30</TableCell>
          <TableCell>osv...</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>6</TableCell>
          <TableCell>671</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>11-17</TableCell>
          <TableCell>11-16</TableCell>
          <TableCell>Stängt</TableCell>
          <TableCell>11.30-15.30</TableCell>
          <TableCell>11-16.30</TableCell>
          <TableCell>osv...</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>7</TableCell>
          <TableCell>111</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>10-19</TableCell>
          <TableCell>11-17</TableCell>
          <TableCell>11-16</TableCell>
          <TableCell>Stängt</TableCell>
          <TableCell>11.30-15.30</TableCell>
          <TableCell>11-16.30</TableCell>
          <TableCell>osv...</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default OpeningHoursFileUploadHelp;
