import React from 'react';
import { Typography, Link, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

function Breadcrumbs({ crumbs }) {
  const breadcrumbs = crumbs.map((crumb, index) => {
    return index === crumbs.length - 1 ? (
      <Typography key={index} variant="body1">
        {crumb.text}
      </Typography>
    ) : (
      <Link key={index} href={crumb.link}>
        {crumb.text}
      </Link>
    );
  });
  return <MuiBreadcrumbs>{breadcrumbs}</MuiBreadcrumbs>;
}

export default Breadcrumbs;
