export const dialogContent = {
  padding: 0,
};

export const dialogContentText = {
  paddingLeft: '16px',
  paddingRight: '16px',
};

export const errorAlert = {
  marginBottom: '15px',
};

export const tableToolbar = {
  paddingRight: '20px',
};

export const openingHoursRoot = {
  width: '100%',
};

export const tableCell1 = {
  width: '250px',
};

export const tableCell2 = {
  width: '100px',
};

export const tableCell3 = {
  width: '100px',
};

export const tableCell4 = {
  width: '100px',
};

export const tableCell5 = {
  width: '200px',
};

export const noBorder = {
  border: 'none',
};

export const openingHoursDropdown = {
  minWidth: '87px',
};

export const storesListContainer = {
  maxHeight: '300px',
  overflowY: 'scroll',
};

export const scrollbar = {
  width: '22px',
};

export const scrollbarTrack = {
  borderLeft: '20px white solid',
  background: '#F0F0F0',
  borderRadius: '0',
  boxShadow: 'inset 0px 0px 0px 0px #F0F0F0',
};

export const scrollbarThumb = {
  borderLeft: '20px white solid',
  background: '#B3AFB3',
  borderRadius: '0',
};

export const scrollbarThumbHover = {
  background: '#B3AFB3',
};

export const tabPanelRoot = {
  flexGrow: 1,
};

export const tab = {
  '& .MuiBox-root': {
    padding: '0',
  },
};
