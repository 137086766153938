import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../components/index';

function useStorePage() {
  const [stores, setStores] = useState({
    data: null,
    loading: false,
    completed: false,
    error: false,
  });

  const { getStoresApiTokenRedirect } = useStores();

  const [requestId, setRequestId] = useState(0);

  const handleRequestId = () => {
    setRequestId(new Date().getUTCMilliseconds());
  };

  useEffect(() => {
    setStores({ ...stores, loading: true });
    async function getStores() {
      await getStoresApiTokenRedirect()
        .then((res) => {
          axios({
            method: 'GET',
            url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores`,
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          })
            .then((res) =>
              setStores({
                ...stores,
                data: res.data,
                loading: false,
                completed: true,
                error: false,
              }),
            )
            .catch((error) =>
              setStores({
                ...stores,
                data: null,
                loading: false,
                completed: true,
                error: true,
              }),
            );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getStores();
  }, [requestId]);

  const { id } = useParams();

  let storeObject = stores.data && stores.data.find((x) => x.storeId === id);

  const store = storeObject
    ? JSON.parse(JSON.stringify(storeObject))
    : storeObject;

  const [pageTitle, setPageTitle] = useState();

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [formId, setFormId] = useState();

  const [isDirty, setIsDirty] = useState();

  return {
    stores,
    store,
    handleRequestId,
    pageTitle,
    setPageTitle,
    breadcrumbs,
    setBreadcrumbs,
    formId,
    setFormId,
    isDirty,
    setIsDirty,
  };
}

export default useStorePage;
