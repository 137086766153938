import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField, MenuItem, Typography, Box } from '@mui/material';
import { StoreEditDescriptionDialog } from './index';
import { useStoreEditForms } from './hooks/index';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as styles from './store-edit-forms-styles';
import { parseISO } from 'date-fns';

function StoreInformationForm(props) {
  const storeTypes = [
    {
      value: 1,
      label: 'Varuhus',
    },
    {
      value: 2,
      label: 'Utlämningsställe',
    },
    {
      value: 3,
      label: 'Pop-up butik',
    },
    {
      value: 4,
      label: 'Reklamationsenhet',
    },
  ];

  const { updateStore } = useStoreEditForms();
  const {
    handleRequestId,
    store,
    usePageTitle,
    useBreadcrumbs,
    useFormId,
    PromptIfDirty,
  } = props;

  const formId = 'store-information-form';

  usePageTitle(store.storeName);
  useBreadcrumbs([
    { text: 'Butiksregister', link: '/' },
    { text: 'Butiker', link: '/butiker' },
    { text: store.storeName },
  ]);
  useFormId(formId);

  return (
    <div>
      {store && (
        <Formik
          initialValues={{
            ...store,
            storeId: store.storeId ?? '',
            storeName: store.storeName ?? '',
            ownerGroup: store.ownerGroup ?? '',
            storeType: store.storeType ?? '',
            webActivationDate: store.webActivationDate ?? null,
            openingDate: store.openingDate ?? new Date(),
            closingDate: store.closingDate ?? null,
            slug: store.slug ?? '',
            address: store.address ?? '',
            zipCode: store.zipCode ?? '',
            city: store.city ?? '',
            country: store.country ?? '',
            location: {
              longitude: store.location?.longitude.toString() ?? '',
              latitude: store.location?.latitude.toString() ?? '',
            },
            email: store.email ?? '',
            phone: store.phone ?? '',
            keywords: store.keywords ?? '',
            description: store.description ?? '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            updateStore(values, handleRequestId, store.storeId);
          }}
          validationSchema={Yup.object().shape({
            storeName: Yup.string()
              .required('Ange ett namn för butiken')
              .max(100, 'Ange färre tecken'),
            storeType: Yup.number()
              .required('Ange en butikstyp')
              .oneOf(storeTypes.map((type) => type.value)),
            webActivationDate: Yup.date()
              .max(
                Yup.ref('openingDate'),
                'Aktiveringsdatum för web måste vara tidigare än öppningsdatum',
              )
              .nullable(true),
            openingDate: Yup.date().when('closingDate', {
              is: (closingDate) => closingDate !== null,
              then: () =>
                Yup.date()
                  .max(
                    Yup.ref('closingDate'),
                    'Öppningsdatum får endast infalla på ett tidigare datum än stängningsdatumet',
                  )
                  .required('Ange ett öppningsdatum'),
              otherwise: () => Yup.date().required('Ange ett öppningsdatum'),
            }),
            closingDate: Yup.date()
              .min(
                Yup.ref('openingDate'),
                'Stängningsdatum får endast infalla på ett senare datum än öppningsdatumet',
              )
              .nullable(true),
            slug: Yup.string()
              .required(
                'Ange en slug, denna visas i url:en för en butik på mio.se',
              )
              .max(50, 'Ange färre tecken')
              .matches(
                /^[a-zA-Z0-9-]*$/,
                'Får enbart innehålla tecken mellan a-z, 0-9 och -',
              ),
            address: Yup.string().required('Ange en adress'),
            zipCode: Yup.string()
              .required('Ange ett postnummer')
              .length(5, 'Postnummer ska innehålla 5 tecken'),
            city: Yup.string()
              .required('Ange en ort')
              .max(50, 'Ange färre tecken'),
            country: Yup.string().required('Ange ett land'),
            location: Yup.object().shape({
              longitude: Yup.string()
                .required('Ange ett värde för longitud')
                .matches(/^\d+[.,]\d+$/, 'fel format'),
              latitude: Yup.string()
                .required('Ange ett värde för latitud')
                .matches(/^\d+[.,]\d+$/, 'fel format'),
            }),
            email: Yup.string()
              .required('Ange en E-postadress')
              .email('Ange en giltig E-postadress'),
            phone: Yup.string().required('Ange ett telefonnummer'),
            keywords: Yup.string(),
            description: Yup.string().required(
              'Ange en beskrivning för butiken',
            ),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleReset,
          }) => (
            <Form onSubmit={handleSubmit} id={formId}>
              <PromptIfDirty />
              <div style={styles.StoreEditFormWrapperStoreInformation}>
                <div style={styles.StoreEditStoreInformationRow}>
                  <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                    Butiksinformation
                  </Typography>
                  <TextField
                    fullWidth
                    disabled
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="storeId"
                    value={values['storeId']}
                    label="Butiks Id"
                    helperText={
                      errors['storeId'] &&
                      touched['storeId'] &&
                      errors['storeId']
                    }
                    error={
                      !!errors['storeId'] &&
                      !!touched['storeId'] &&
                      !!errors['storeId']
                    }
                  />
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="storeName"
                    value={values['storeName']}
                    label="Butiksnamn"
                    helperText={
                      errors['storeName'] &&
                      touched['storeName'] &&
                      errors['storeName']
                    }
                    error={
                      !!errors['storeName'] &&
                      !!touched['storeName'] &&
                      !!errors['storeName']
                    }
                  />
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="ownerGroup"
                    value={values['ownerGroup']}
                    label="Ägargrupp"
                    helperText={
                      errors['ownerGroup'] &&
                      touched['ownerGroup'] &&
                      errors['ownerGroup']
                    }
                    error={
                      !!errors['ownerGroup'] &&
                      !!touched['ownerGroup'] &&
                      !!errors['ownerGroup']
                    }
                  />
                  <TextField
                    select
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="storeType"
                    value={values['storeType']}
                    label="Butikstyp"
                    helperText={
                      errors['storeType'] &&
                      touched['storeType'] &&
                      errors['storeType']
                    }
                    error={
                      !!errors['storeType'] &&
                      !!touched['storeType'] &&
                      !!errors['storeType']
                    }
                  >
                    {storeTypes.map((type, index) => (
                      <MenuItem key={index} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    <DatePicker
                      sx={styles.StoreEditActivationDate}
                      fullWidth
                      onChange={(value) => {
                        setFieldValue(
                          'webActivationDate',
                          new Date(value.setHours(0, 0, 0, 0)).toISOString(),
                        );
                      }}
                      onBlur={handleBlur}
                      inputVariant="outlined"
                      margin="normal"
                      name={'webActivationDate'}
                      value={
                        values['webActivationDate'] &&
                        parseISO(values['webActivationDate'])
                      }
                      label="Aktiveringsdatum (Web)"
                      maxDate={parseISO(values['openingDate'])}
                      autoOk
                      format="yyyy-MM-dd"
                      helperText={
                        errors['webActivationDate'] &&
                        touched['webActivationDate'] &&
                        errors['webActivationDate']
                      }
                      error={
                        !!errors['webActivationDate'] &&
                        !!touched['webActivationDate'] &&
                        !!errors['webActivationDate']
                      }
                    />
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <DatePicker
                      sx={styles.StoreEditOpeningAndCloseDate}
                      style={{ marginRight: 20 }}
                      fullWidth
                      onChange={(value) => {
                        setFieldValue(
                          'openingDate',
                          new Date(value).toISOString(),
                        );
                      }}
                      onBlur={handleBlur}
                      inputVariant="outlined"
                      margin="normal"
                      name={'openingDate'}
                      value={
                        values['openingDate'] && parseISO(values['openingDate'])
                      }
                      label="Öppningsdatum"
                      autoOk
                      format="yyyy-MM-dd"
                      helperText={
                        errors['openingDate'] &&
                        touched['openingDate'] &&
                        errors['openingDate']
                      }
                      error={
                        !!errors['openingDate'] &&
                        !!touched['openingDate'] &&
                        !!errors['openingDate']
                      }
                    />
                    <DatePicker
                      sx={styles.StoreEditOpeningAndCloseDate}
                      fullWidth
                      onChange={(value) => {
                        setFieldValue(
                          'closingDate',
                          new Date(value).toISOString(),
                        );
                      }}
                      onBlur={handleBlur}
                      inputVariant="outlined"
                      margin="normal"
                      name={'closingDate'}
                      value={
                        values['closingDate'] && parseISO(values['closingDate'])
                      }
                      label="Stängningsdatum"
                      autoOk
                      format="yyyy-MM-dd"
                      helperText={
                        errors['closingDate'] &&
                        touched['closingDate'] &&
                        errors['closingDate']
                      }
                      error={
                        !!errors['closingDate'] &&
                        !!touched['closingDate'] &&
                        !!errors['closingDate']
                      }
                    />
                  </Box>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="slug"
                    value={values['slug']}
                    label="Slug"
                    helperText={
                      errors['slug'] && touched['slug'] && errors['slug']
                    }
                    error={
                      !!errors['slug'] && !!touched['slug'] && !!errors['slug']
                    }
                  />
                </div>
                <div style={styles.StoreEditStoreInformationRow}>
                  <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                    Adressuppgifter
                  </Typography>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="address"
                    value={values['address']}
                    label="Adress"
                    helperText={
                      errors['address'] &&
                      touched['address'] &&
                      errors['address']
                    }
                    error={
                      !!errors['address'] &&
                      !!touched['address'] &&
                      !!errors['address']
                    }
                  />
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="zipCode"
                    value={values['zipCode']}
                    label="Postnummer"
                    helperText={
                      errors['zipCode'] &&
                      touched['zipCode'] &&
                      errors['zipCode']
                    }
                    error={
                      !!errors['zipCode'] &&
                      !!touched['zipCode'] &&
                      !!errors['zipCode']
                    }
                  />
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="city"
                    value={values['city']}
                    label="Ort"
                    helperText={
                      errors['city'] && touched['city'] && errors['city']
                    }
                    error={
                      !!errors['city'] && !!touched['city'] && !!errors['city']
                    }
                  />
                  <TextField
                    select
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="country"
                    value={values['country']}
                    label="Land"
                    helperText={
                      errors['country'] &&
                      touched['country'] &&
                      errors['country']
                    }
                    error={
                      !!errors['country'] &&
                      !!touched['country'] &&
                      !!errors['country']
                    }
                  >
                    <MenuItem value="Sverige">Sverige</MenuItem>
                    <MenuItem value="Åland">Åland</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="location.longitude"
                    value={values['location'].longitude}
                    label="Longitud"
                    helperText={
                      errors['location']?.longitude &&
                      touched['location']?.longitude &&
                      errors['location']?.longitude
                    }
                    error={
                      !!errors['location']?.longitude &&
                      !!touched['location']?.longitude &&
                      !!errors['location']?.longitude
                    }
                  />
                  <TextField
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    name="location.latitude"
                    value={values['location'].latitude}
                    label="Latitud"
                    helperText={
                      errors['location']?.latitude &&
                      touched['location']?.latitude &&
                      errors['location']?.latitude
                    }
                    error={
                      !!errors['location']?.latitude &&
                      !!touched['location']?.latitude &&
                      !!errors['location']?.latitude
                    }
                  />
                </div>
                <div style={styles.StoreEditStoreInformationSplitRow}>
                  <div>
                    <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                      Kontaktuppgifter
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="email"
                      value={values['email']}
                      label="E-postadress"
                      helperText={
                        errors['email'] && touched['email'] && errors['email']
                      }
                      error={
                        !!errors['email'] &&
                        !!touched['email'] &&
                        !!errors['email']
                      }
                    />
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="phone"
                      value={values['phone']}
                      label="Telefonnummer"
                      helperText={
                        errors['phone'] && touched['phone'] && errors['phone']
                      }
                      error={
                        !!errors['phone'] &&
                        !!touched['phone'] &&
                        !!errors['phone']
                      }
                    />
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="deliveryPlannerPhone"
                      value={values['deliveryPlannerPhone']}
                      label="Telefonnummer vid hemleverans"
                      helperText={
                        errors['deliveryPlannerPhone'] &&
                        touched['deliveryPlannerPhone'] &&
                        errors['deliveryPlannerPhone']
                      }
                      error={
                        !!errors['deliveryPlannerPhone'] &&
                        !!touched['deliveryPlannerPhone'] &&
                        !!errors['deliveryPlannerPhone']
                      }
                    />
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      sx={styles.StoreEditFormHeadersPresentation}
                    >
                      Presentation
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="keywords"
                      value={values['keywords']}
                      label="Relaterade sökord"
                      helperText={
                        errors['keywords'] &&
                        touched['keywords'] &&
                        errors['keywords']
                      }
                    />
                    <StoreEditDescriptionDialog
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      values={{ ...values }}
                      errors={errors}
                      touched={touched}
                      dirty={dirty}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default StoreInformationForm;
