import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  TextField,
  MenuItem,
  List,
  ListItem,
  Switch,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CreateIcon from '@mui/icons-material/Create';
import { storeEditFormsFunctions } from './functions/index';
import useHolydate from '../../utils/holydate';
import * as styles from './store-edit-forms-styles';
import { parseISO } from 'date-fns';

function ExceptionalOpeningHoursDialog(props) {
  const { anchorText, variant, store, index, edit, updateNewExceptionalHour } =
    props;

  const { dropdownHours, openingHourCell, closingHourCell, addHoursObject } =
    storeEditFormsFunctions();

  const { hd } = useHolydate();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const exceptionalOpeningHoursDates = store.exceptionalOpeningHours.map(
    (item) => new Date(new Date(item.date)).toISOString(),
  );

  const todayISOString = new Date(
    new Date().setHours(12, 0, 0, 0),
  ).toISOString();

  const openingHoursSchema = Yup.object().shape({
    edit: Yup.boolean(),
    date: Yup.string().when('edit', {
      is: false,
      then: () =>
        Yup.string()
          .typeError('Ange ett giltigt datum')
          .notOneOf(
            exceptionalOpeningHoursDates,
            'Det finns redan en avikande öppettid på detta datum, redigera befintlig eller välj annat datum.',
          )
          .required('Ange ett datum för den avvikande öppettiden.'),
      otherwise: () =>
        Yup.string()
          .typeError('Ange ett giltigt datum')
          .test(
            'does not already exist except the date of current edit object',
            'Det finns redan en avikande öppettid på detta datum, redigera med befintligt eller välj något annat datum.',
            (value) => {
              let removeEditedDate = exceptionalOpeningHoursDates.filter(
                (d) =>
                  d !==
                  new Date(
                    new Date(store.exceptionalOpeningHours[index].date),
                  ).toISOString(),
              );
              return !removeEditedDate.includes(value);
            },
          )
          .required('Ange ett datum för den avvikande öppettiden.'),
    }),
    name: Yup.string().max(100),
    closed: Yup.boolean(),
    hours: Yup.array().of(
      Yup.object().shape({
        openingTime: Yup.string()
          .required('Ange öppningstid för den avikande öppettiden')
          .oneOf(dropdownHours())
          .max(5, 'Får inte vara längre än 5 tecken'),

        closingTime: Yup.string()
          .required('Ange stängningstid för den avikande öppettiden')
          .oneOf(dropdownHours())
          .max(5, 'Får inte vara längre än 5 tecken'),
      }),
    ),
  });

  return (
    <>
      {edit ? (
        <CreateIcon
          onClick={handleClickOpen}
          sx={styles.StoreEditCreateIcon}
        ></CreateIcon>
      ) : (
        <Link
          onClick={handleClickOpen}
          component="button"
          type="button"
          variant={variant}
          align="right"
        >
          {anchorText}
        </Link>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik
          initialValues={{
            ...(store.exceptionalOpeningHours[index]
              ? { ...store.exceptionalOpeningHours[index], edit: edit }
              : {
                edit: edit,
                date: todayISOString,
                name: hd.get(new Date().toLocaleDateString())?.[0].name ?? '',
                closed: false,
                hours: [
                  {
                    openingTime: '10:00',
                    closingTime: '19:00',
                  },
                ],
              }),
          }}
          onSubmit={(values, { setSubmitting, setFieldValue }) => {
            if (values.closed) {values.hours = [];}
            setSubmitting(true);
            updateNewExceptionalHour({
              ...values,
              date: new Date(new Date(values.date)).toISOString(),
            });
            handleClose();
          }}
          validationSchema={openingHoursSchema}
        >
          {({
            values,
            touched,
            initialTouched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleReset,
          }) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {'Avvikande Öppettid'}
              </DialogTitle>
              <DialogContent>
                <DatePicker
                  sx={styles.StoreEditExceptionalDatePicker}
                  fullWidth
                  margin="normal"
                  autoOk
                  format="yyyy-MM-dd"
                  label="Datum"
                  inputVariant="outlined"
                  name="date"
                  value={values.date !== '' ? parseISO(values.date) : null}
                  onChange={(e) => {
                    setFieldValue(
                      'date',
                      new Date(new Date(e).setHours(12, 0, 0, 0)).toISOString(),
                    );
                    setFieldValue(
                      'name',
                      hd.get(e.toLocaleDateString())?.[0].name ?? '',
                    );
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  helperText={
                    errors['date'] && touched['date'] && errors['date']
                  }
                  error={
                    !!errors['date'] && !!touched['date'] && !!errors['date']
                  }
                  minDate={parseISO(todayISOString)}
                />

                <TextField
                  fullWidth
                  disabled={Object.keys(hd.register).includes(values.name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  margin="normal"
                  name={`name`}
                  value={values.name}
                  label="Benämning"
                  helperText={touched['name'] && errors['name']}
                  error={!!touched['name'] && !!errors['name']}
                />
                <List>
                  <ListItem
                    disableGutters={true}
                    sx={styles.StoreEditOpeningHoursListItem}
                  >
                    <div>
                      <Switch
                        name={`closed`}
                        value={values.closed}
                        onChange={(e) => {
                          setFieldValue(`closed`, !e.target.checked);
                        }}
                        onBlur={handleBlur}
                        color="primary"
                        checked={!values.closed}
                      />
                      <Typography
                        display="inline"
                        sx={styles.StoreEditExceptionalToggle}
                      >
                        {values.closed ? 'Stängt' : 'Öppet'}
                      </Typography>
                    </div>
                    <div>
                      {!values.closed
                        ? values.hours.map((object, index) => (
                          <div
                            style={
                              styles.StoreEditOpeningHoursDropdownWrapper
                            }
                            key={index}
                          >
                            <TextField
                              sx={styles.StoreEditOpeningHoursDropdown}
                              variant="outlined"
                              margin="none"
                              size="small"
                              name={`hours[${index}].openingTime`}
                              value={values.hours[index].openingTime}
                              onChange={handleChange}
                              disabled={values.closed}
                              onBlur={handleBlur}
                              select
                              required
                            >
                              {openingHourCell(values, index).map((hour) => (
                                <MenuItem key={hour} value={hour}>
                                  {hour}
                                </MenuItem>
                              ))}
                            </TextField>
                            <Typography display="inline" variant="overline">
                                &nbsp;-&nbsp;
                            </Typography>
                            <TextField
                              sx={styles.StoreEditOpeningHoursDropdown}
                              variant="outlined"
                              margin="none"
                              size="small"
                              name={`hours[${index}].closingTime`}
                              value={values.hours[index].closingTime}
                              onChange={handleChange}
                              disabled={values.closed}
                              onBlur={handleBlur}
                              select
                              required
                            >
                              {closingHourCell(values, index).map((hour) => (
                                <MenuItem key={hour} value={hour}>
                                  {hour}
                                </MenuItem>
                              ))}
                            </TextField>
                            {values.hours.length > 1 ? (
                              index === values.hours.length - 1 ? (
                                <>
                                  <Link
                                    component="button"
                                    variant="body2"
                                    color="error"
                                    type="button"
                                    sx={styles.StoreEditRemoveLink}
                                    onClick={() =>
                                      setFieldValue(
                                        values.hours.splice(index, 1),
                                      )
                                    }
                                  >
                                      TA BORT
                                  </Link>
                                  <Link
                                    component="button"
                                    type="button"
                                    variant="body2"
                                    sx={styles.StoreEditAddLink}
                                    onClick={() => {
                                      setFieldValue(
                                        addHoursObject(values, index),
                                      );
                                    }}
                                  >
                                      LÄGG TILL
                                  </Link>
                                </>
                              ) : (
                                <Link
                                  component="button"
                                  variant="body2"
                                  color="error"
                                  type="button"
                                  sx={styles.StoreEditRemoveLink}
                                  onClick={() =>
                                    setFieldValue(
                                      values.hours.splice(index, 1),
                                    )
                                  }
                                >
                                    TA BORT
                                </Link>
                              )
                            ) : (
                              <Link
                                component="button"
                                type="button"
                                variant="body2"
                                sx={styles.StoreEditAddLink}
                                onChange={handleChange}
                                onClick={() => {
                                  setFieldValue(
                                    addHoursObject(values, index),
                                  );
                                }}
                              >
                                  LÄGG TILL
                              </Link>
                            )}
                          </div>
                        ))
                        : null}
                    </div>
                  </ListItem>
                </List>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Avbryt
                </Button>
                <Button type="submit" disabled={isSubmitting} color="primary">
                  LÄGG TILL
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default ExceptionalOpeningHoursDialog;
