import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import { NewZipCodesFileForm, useNewZipCodesFileDialog } from './index';
import { FileUpload } from './index';
import * as styles from './new-zip-codes-file-dialog-styles';

function NewZipCodesFileDialog(props) {
  const {
    newZipCodesFileDialogOpen,
    toggleNewZipCodesFileDialog,
    handleRequestId,
    zipCodeFiles,
  } = props;
  const {
    file,
    onDrop,
    fileText,
    clearAcceptedFile,
    getRootProps,
    getInputProps,
    fileRejections,
    postNewFile,
  } = useNewZipCodesFileDialog();

  return (
    <Dialog
      fullWidth
      open={newZipCodesFileDialogOpen}
      onClose={() => {
        toggleNewZipCodesFileDialog();
        clearAcceptedFile();
      }}
    >
      <DialogTitle>Ladda upp fil</DialogTitle>
      <DialogContent>
        <div style={styles.newZipCodesFileDragAndDrop}>
          <FileUpload
            onDrop={onDrop}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            fileRejections={fileRejections}
          />
        </div>
        {!!file && !fileRejections.length ? (
          <NewZipCodesFileForm
            {...props}
            file={file}
            zipCodeFiles={zipCodeFiles}
            onDrop={onDrop}
            fileText={fileText}
            clearAcceptedFile={clearAcceptedFile}
            postNewFile={postNewFile}
            handleRequestId={handleRequestId}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export default NewZipCodesFileDialog;
