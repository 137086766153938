import React from 'react';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';

function EnhancedTableHead(props) {
  const { filteredFilesBySearch, setSelected, selected, totalFilesCount } =
    props;
  const tableHeadData = [
    { id: 'Filnamn', label: 'Filnamn', alignment: 'left' },
    { id: 'Beskrivning', label: 'Beskrivning', alignment: 'left' },
    { id: 'Aktiveringsdatum', label: 'Aktiveringsdatum', alignment: 'center' },
    { id: 'Status', label: 'Status', alignment: 'center' },
    { id: 'Åtgärder', label: 'Åtgärder', alignment: 'center' },
  ];

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = filteredFilesBySearch.map((file) => file.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onClick={handleSelectAll}
            checked={totalFilesCount > 0 && selected.length === totalFilesCount}
            color="primary"
          />
        </TableCell>
        {tableHeadData.map((cell) => (
          <TableCell key={cell.id} align={cell.alignment}>
            {cell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
