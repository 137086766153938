import React from 'react';
import { Toolbar, Tooltip, Button, InputBase } from '@mui/material';
import {
  Delete as DeleteIcon,
  Schedule as ScheduleIcon,
  Search as SearchIcon,
  Sync as SyncIcon,
} from '@mui/icons-material';

import * as styles from './store-list-styles';
import { FilterStoresList } from '../index';

const SearchField = ({ handleFilter }) => {
  return (
    <div style={styles.storeListSearch}>
      <div style={styles.storeListSearchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Sök butik..."
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleFilter}
        sx={(styles.storeListInputRoot, styles.storeListInputInput)}
      />
    </div>
  );
};

function EnhancedTableToolbar(props) {
  const {
    selected,
    toggleOpeningHoursDialog,
    handleFilter,
    selectedCategories,
    isSelectedCategory,
    addToSelectedCategories,
    filteredStoresBySearch,
    filteredStoresByCategories,
    stores,
    filteredStores,
    handleClearFilters,
    toggleDeleteDialog,
    toggleGoogleSyncDialog,
  } = props;

  return (
    <Toolbar disableGutters>
      <SearchField handleFilter={handleFilter} />
      <div style={{ flexGrow: 1 }} />
      <Tooltip
        title="Synka butiksinfo till Google för markerade butiker"
        style={styles.storeListToolbarItem}
      >
        <span>
          <Button
            disabled={!selected.length > 0}
            variant="outlined"
            startIcon={<SyncIcon />}
            onClick={toggleGoogleSyncDialog}
          >
            Synka till Google
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={
          <span>
            Öppettider har flyttat!{' '}
            <a href={window.env.OPENINGHOURS_API_URL} target="_blank">
              Gå till nya öppettider
            </a>
          </span>
        }
        style={styles.storeListToolbarItem}
      >
        <span>
          <Button
            // Disabled to use the new repo Stores.Ui
            disabled
            variant="outlined"
            startIcon={<ScheduleIcon />}
            onClick={toggleOpeningHoursDialog}
          >
            Uppdatera avvikande öppettider
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title="Ta bort markerade butiker"
        style={styles.storeListToolbarItem}
      >
        <span>
          <Button
            disabled={!selected.length > 0}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              toggleDeleteDialog();
            }}
          >
            Ta bort
          </Button>
        </span>
      </Tooltip>
      <FilterStoresList
        filteredStoresBySearch={filteredStoresBySearch}
        filteredStoresByCategories={filteredStoresByCategories}
        stores={stores}
        filteredStores={filteredStores}
        selectedCategories={selectedCategories}
        addToSelectedCategories={addToSelectedCategories}
        isSelectedCategory={isSelectedCategory}
        handleClearFilters={handleClearFilters}
      />
    </Toolbar>
  );
}

export default EnhancedTableToolbar;
