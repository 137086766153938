import React, { Fragment } from 'react';
import { OpeningHours } from './index';
import { useStoreEditForms } from './hooks/index';
import { storeEditFormsFunctions } from './functions/index';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import * as styles from './store-edit-forms-styles';

function StoreDepartmentForm(props) {
  const {
    store,
    handleRequestId,
    usePageTitle,
    getDepartmentName,
    departmentTypes,
    newDepartment,
    useFormId,
    PromptIfDirty,
  } = props;
  const { weekdays, dropdownHours } = storeEditFormsFunctions();
  const { updateStore } = useStoreEditForms();

  let { avdelning } = useParams();
  const navigate = useNavigate();

  const formId = 'store-department-form';
  useFormId(formId);

  const findDepartment = newDepartment
    ? {
      departmentType: '',
      description: '',
      email: '',
      phone: '',
      emailResponseTime: 120,
      address: '',
      zipCode: '',
      city: '',
      country: '',
      location: {
        longitude: null,
        latitude: null,
      },
      openingHours: weekdays.map((weekday) => ({
        closed: true,
        dayOfWeek: weekday.value,
        hours: [
          {
            openingTime: '10:00',
            closingTime: '19:00',
          },
        ],
      })),
    }
    : store.departments.find(
      (item) => item.departmentType.toString() === avdelning,
    ) ?? null;

  usePageTitle(
    newDepartment
      ? 'Ny avdelning'
      : findDepartment
        ? getDepartmentName(findDepartment.departmentType)
        : 'Finns inte',
  );

  return (
    <div>
      <Formik
        initialValues={findDepartment}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const { departments } = store;
          let departmentsObject = newDepartment
            ? { ...store, departments: [...departments, values] }
            : {
              ...store,
              departments: departments.map((x) =>
                x.departmentType.toString() === avdelning ? values : x,
              ),
            };
          updateStore(departmentsObject, handleRequestId, store.storeId);
          navigate(`/butiker/butik/${store.storeId}/avdelningar`);
        }}
        validationSchema={Yup.object().shape({
          departmentType: Yup.number()
            .required(
              'Välj en avdelningstyp, gråmarkerade avdelningstyper betyder att de redan finns registerade för butiken',
            )
            .oneOf(
              departmentTypes.map((type) => type.value),
              'Välj en giltig avdelningstyp',
            ),
          description: Yup.string().required(
            'Beskriv avdelningen med några ord',
          ),
          email: Yup.string()
            .email('Ange en giltig E-postadress')
            .required('Ange en E-postadress'),
          phone: Yup.string(),
          emailResponseTime: Yup.number().when('departmentType', {
            is: 1,
            otherwise: (schema) =>
              schema
                .required('Ange en svarstid för reklamationsärenden')
                .nullable(),
          }),
          address: Yup.string().required('Ange en adress'),
          zipCode: Yup.string()
            .required('Ange ett postnummer.')
            .length(5, 'Postnummer ska innehålla 5 tecken'),
          city: Yup.string()
            .required('Ange en ort')
            .max(50, 'Ange färre tecken'),
          country: Yup.string().required('Ange ett land'),
          location: Yup.object().shape({
            longitude: Yup.number().required('Ange ett värde för longitud'),
            latitude: Yup.number().required('Ange ett värde för latitud'),
          }),
          openingHours: Yup.array().of(
            Yup.object().shape({
              closed: Yup.bool(),
              dayOfWeek: Yup.number().min(0).max(6),
              hours: Yup.array().of(
                Yup.object().shape({
                  openingTime: Yup.string()
                    .required('Ange öppningstid')
                    .oneOf(dropdownHours())
                    .max(5, 'Får inte vara längre än 5 tecken'),
                  closingTime: Yup.string()
                    .required('Ange stängningstid')
                    .oneOf(dropdownHours())
                    .max(5, 'Får inte vara längre än 5 tecken'),
                }),
              ),
            }),
          ),
        })}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Fragment>
            {values ? (
              <Form onSubmit={handleSubmit} id={formId}>
                <PromptIfDirty />
                <div style={styles.StoreEditDepartmentFormWrapper}>
                  <div style={styles.StoreEditDepartmentRow}>
                    <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                      Adressuppgifter
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="address"
                      value={values['address']}
                      label="Adress"
                      helperText={
                        errors['address'] &&
                        touched['address'] &&
                        errors['address']
                      }
                      error={
                        !!errors['address'] &&
                        !!touched['address'] &&
                        !!errors['address']
                      }
                    />
                    <Box display="flex" justifyContent="space-between">
                      <TextField
                        style={{ marginRight: 20 }}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        margin="normal"
                        name="zipCode"
                        value={values['zipCode']}
                        label="Postnummer"
                        helperText={
                          errors['zipCode'] &&
                          touched['zipCode'] &&
                          errors['zipCode']
                        }
                        error={
                          !!errors['zipCode'] &&
                          !!touched['zipCode'] &&
                          !!errors['zipCode']
                        }
                      />
                      <TextField
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        margin="normal"
                        name="city"
                        value={values['city']}
                        label="Ort"
                        helperText={
                          errors['city'] && touched['city'] && errors['city']
                        }
                        error={
                          !!errors['city'] &&
                          !!touched['city'] &&
                          !!errors['city']
                        }
                      />
                    </Box>
                    <TextField
                      select
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="country"
                      value={values['country']}
                      label="Land"
                      helperText={
                        errors['country'] &&
                        touched['country'] &&
                        errors['country']
                      }
                      error={
                        !!errors['country'] &&
                        !!touched['country'] &&
                        !!errors['country']
                      }
                    >
                      <MenuItem value="Sverige">Sverige</MenuItem>
                      <MenuItem value="Åland">Åland</MenuItem>
                    </TextField>
                    <TextField
                      fullWidth
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="location.longitude"
                      value={
                        values['location'].longitude === null
                          ? ''
                          : values['location'].longitude
                      }
                      label="Longitud"
                      helperText={
                        errors['location']?.longitude &&
                        touched['location']?.longitude &&
                        errors['location']?.longitude
                      }
                      error={
                        !!errors['location']?.longitude &&
                        !!touched['location']?.longitude &&
                        !!errors['location']?.longitude
                      }
                    />
                    <TextField
                      fullWidth
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="location.latitude"
                      value={
                        values['location'].latitude === null
                          ? ''
                          : values['location'].latitude
                      }
                      label="Latitud"
                      helperText={
                        errors['location']?.latitude &&
                        touched['location']?.latitude &&
                        errors['location']?.latitude
                      }
                      error={
                        !!errors['location']?.latitude &&
                        !!touched['location']?.latitude &&
                        !!errors['location']?.latitude
                      }
                    />
                  </div>

                  <div style={styles.StoreEditDepartmentRow}>
                    <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                      Kontaktuppgifter
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="email"
                      value={values['email']}
                      label="E-postadress"
                      helperText={
                        errors['email'] && touched['email'] && errors['email']
                      }
                      error={
                        !!errors['email'] &&
                        !!touched['email'] &&
                        !!errors['email']
                      }
                    />
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="phone"
                      value={values['phone']}
                      label="Telefonnummer"
                      helperText={
                        errors['phone'] && touched['phone'] && errors['phone']
                      }
                      error={
                        !!errors['phone'] &&
                        !!touched['phone'] &&
                        !!errors['phone']
                      }
                    />
                    {values.departmentType === 2 && (
                      <TextField
                        select
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        margin="normal"
                        name="emailResponseTime"
                        value={values['emailResponseTime']}
                        label="Mejlsvarstid"
                        helperText={
                          errors['emailResponseTime'] &&
                          touched['emailResponseTime'] &&
                          errors['emailResponseTime']
                        }
                        error={
                          !!errors['emailResponseTime'] &&
                          !!touched['emailResponseTime'] &&
                          !!errors['emailResponseTime']
                        }
                      >
                        <MenuItem value={120}>5 dagar</MenuItem>
                        <MenuItem value={96}>4 dagar</MenuItem>
                        <MenuItem value={72}>72 timmar</MenuItem>
                        <MenuItem value={48}>48 timmar</MenuItem>
                      </TextField>
                    )}
                  </div>
                  <div style={styles.StoreEditDepartmentRow}>
                    <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                      Butiksinformation
                    </Typography>
                    <TextField
                      select
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="departmentType"
                      value={
                        values['departmentType'] === null
                          ? ''
                          : values['departmentType']
                      }
                      label="Avdelningstyp"
                      helperText={
                        errors['departmentType'] &&
                        touched['departmentType'] &&
                        errors['departmentType']
                      }
                      error={
                        !!errors['departmentType'] &&
                        !!touched['departmentType'] &&
                        !!errors['departmentType']
                      }
                    >
                      {departmentTypes.map((type) =>
                        store.departments.findIndex(
                          (t) => t.departmentType === type.value,
                        ) === -1 || type.value.toString() === avdelning ? (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ) : (
                            <MenuItem
                              key={type.value}
                              value={type.value}
                              disabled
                            >
                              {type.label}
                            </MenuItem>
                          ),
                      )}
                    </TextField>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      multiline
                      rows={4}
                      margin="normal"
                      name="description"
                      value={values['description']}
                      label="Presentationstext"
                      helperText={
                        errors['description'] &&
                        touched['description'] &&
                        errors['description']
                      }
                      error={
                        !!errors['description'] &&
                        !!touched['description'] &&
                        !!errors['description']
                      }
                    />
                  </div>
                </div>
                <OpeningHours
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Form>
            ) : (
              <p>Avdelningen du försöker nå är inte tillgänglig, </p>
            )}
          </Fragment>
        )}
      </Formik>
    </div>
  );
}

export default StoreDepartmentForm;
