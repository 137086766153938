import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import {
  StoreInformationForm,
  StoreOpeningHoursForm,
  StoreLogisticsAndSalesForm,
  StoreDepartmentsOverview,
} from '../index';
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import * as styles from './store-styles';
import { CustomPrompt } from '../index';

function SimpleTabs(props) {
  const {
    store,
    stores,
    handleRequestId,
    setPageTitle,
    setBreadcrumbs,
    setFormId,
    setIsDirty,
  } = props;

  const location = useLocation();
  const currentPath = location.pathname;

  const allTabs = [
    `/butiker/butik/${store.storeId}`,
    `/butiker/butik/${store.storeId}/oppettider`,
    `/butiker/butik/${store.storeId}/logistik-forsaljning`,
    `/butiker/butik/${store.storeId}/avdelningar`,
  ];

  const PromptIfDirty = () => {
    const formik = useFormikContext();

    useEffect(() => {
      setIsDirty(formik.dirty);
    }, [formik.dirty]);

    return (
      <CustomPrompt
        when={formik.dirty && formik.submitCount === 0}
        message="Är du säker på att du vill lämna? Du har osparade ändringar."
      />
    );
  };

  const usePageTitle = (string) => {
    useEffect(() => {
      setPageTitle(string);
    }, []);
  };

  const useBreadcrumbs = (array) => {
    useEffect(() => {
      setBreadcrumbs(array);
    }, []);
  };

  const useFormId = (formId) => {
    useEffect(() => {
      setFormId(formId);
    }, []);
  };

  return (
    <div style={styles.storeRouterRoot}>
      <div style={styles.storeTabs}>
        <Link
          to={allTabs[0]}
          style={
            currentPath === allTabs[0]
              ? { ...styles.storeLink, ...styles.selectedStoreLink }
              : styles.storeLink
          }
        >
          GRUNDINFORMATION
        </Link>

        <Link
          to={allTabs[1]}
          style={
            currentPath === allTabs[1]
              ? { ...styles.storeLink, ...styles.selectedStoreLink }
              : styles.storeLink
          }
        >
          ÖPPETTIDER
        </Link>

        <Link
          to={allTabs[2]}
          style={
            currentPath === allTabs[2]
              ? { ...styles.storeLink, ...styles.selectedStoreLink }
              : styles.storeLink
          }
        >
          LOGISTIK & FÖRSÄLJNING
        </Link>

        <Link
          to={allTabs[3]}
          style={
            currentPath === allTabs[3]
              ? { ...styles.storeLink, ...styles.selectedStoreLink }
              : styles.storeLink
          }
        >
          AVDELNINGAR
        </Link>
      </div>
      <Divider />
      <Routes>
        <Route
          path="avdelningar/*"
          element={
            <StoreDepartmentsOverview
              store={store}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              useBreadcrumbs={useBreadcrumbs}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
            />
          }
        />
        <Route
          path="logistik-forsaljning"
          element={
            <StoreLogisticsAndSalesForm
              store={store}
              stores={stores}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              useBreadcrumbs={useBreadcrumbs}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
            />
          }
        />
        <Route
          path="oppettider"
          element={
            <StoreOpeningHoursForm
              store={store}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              useBreadcrumbs={useBreadcrumbs}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
            />
          }
        />
        <Route
          path=""
          element={
            <StoreInformationForm
              store={store}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              useBreadcrumbs={useBreadcrumbs}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default SimpleTabs;
