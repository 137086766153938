import React from 'react';
import { Layout, Spinner, Store } from '../../components/index';
import { useStorePage } from '../store-page/hooks/index';
import { Box, Button, Link, List, ListItem, Typography } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import * as styles from './store-page-styles';

function StorePage(props) {
  const {
    stores,
    store,
    handleRequestId,
    pageTitle,
    setPageTitle,
    breadcrumbs,
    setBreadcrumbs,
    formId,
    setFormId,
    isDirty,
    setIsDirty,
  } = useStorePage();

  const headerButton = (
    <Button
      sx={styles.saveButton}
      variant="contained"
      type="submit"
      form={formId}
      disabled={!isDirty}
    >
      Spara
    </Button>
  );

  return (
    <>
      {stores.loading ? (
        <Layout
          headerButton={headerButton}
          pageTitle={pageTitle}
          title={pageTitle}
          crumbs={breadcrumbs}
        >
          <Spinner />
        </Layout>
      ) : stores.completed ? (
        store ? (
          <Layout
            headerButton={headerButton}
            pageTitle={pageTitle}
            title={pageTitle}
            crumbs={breadcrumbs}
          >
            <Store
              store={store}
              stores={stores.data}
              handleRequestId={handleRequestId}
              setPageTitle={setPageTitle}
              setBreadcrumbs={setBreadcrumbs}
              breadcrumbs={breadcrumbs}
              setFormId={setFormId}
              setIsDirty={setIsDirty}
            />
          </Layout>
        ) : (
          <div style={{ padding: 60 }}>
            <Typography variant="h3">
              Hoppsan! Butiken du försöker nå är inte tillgänglig{' '}
              <SentimentDissatisfiedIcon fontSize="large" />
            </Typography>
            <Box py={5}>
              <Typography>Pröva någon av nedan butiker istället : </Typography>
              <List>
                {stores.data &&
                  stores.data.map((store) => (
                    <ListItem key={store.storeId}>
                      <Link href={`/butiker/butik/${store.storeId}`}>
                        {store.storeName}
                      </Link>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </div>
        )
      ) : null}
    </>
  );
}

export default StorePage;
