import React from 'react';
import mioStoreImage from '../../utils/images/mio-butik.jpg';
import zipCodeImage from '../../utils/images/zipcode-image.jpg';
import openinghoursImage from '../../utils/images/openinghours-image.png';
import { Layout } from '../../components/index';
import { useNavigate } from 'react-router-dom';
import * as styles from './landing-page-styles';
import {
  CardMedia,
  CardContent,
  Typography,
  Card,
  CardActionArea,
} from '@mui/material';

function LandingPage() {
  const breadcrumbs = [{ text: 'Butiksregister', link: '/' }];
  const pageTitle = 'Butiksregister';
  const navigate = useNavigate();
  const handleRedirect = () => {
    window.location.href = window.env.OPENINGHOURS_API_URL;
  };

  return (
    <Layout pageTitle={pageTitle} title="Startsida" crumbs={breadcrumbs}>
      <div style={styles.landingPageWrapper}>
        <Card sx={styles.landingPageCard} onClick={() => navigate('/butiker')}>
          <CardActionArea>
            <CardMedia
              sx={styles.landingPageMedia}
              image={mioStoreImage}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                Butiker
              </Typography>
              {/* todo : Lägg till en beskrivning av appen */}
              <Typography variant="body2" color="textSecondary" component="p">
                Administrera butiksinformation för alla våra butiker.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          sx={styles.landingPageCard}
          onClick={() => navigate('/postnummer')}
        >
          <CardActionArea>
            <CardMedia
              sx={styles.landingPageMedia}
              image={zipCodeImage}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                Postnummer
              </Typography>
              {/* todo : Lägg till en beskrivning av appen */}
              <Typography variant="body2" color="textSecondary" component="p">
                Schemalägg när en ny postnummerfil ska aktiveras.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx={styles.landingPageCard} onClick={() => handleRedirect()}>
          <CardActionArea>
            <CardMedia
              sx={styles.landingPageMedia}
              image={openinghoursImage}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                Öppettider
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Redigera öppettider & avvikande öppettider för butiker samt
                avdelningar
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Layout>
  );
}

export default LandingPage;
