export const storeListRoot = {
  width: '100%',
};

export const storeListPaper = {
  width: '100%',
  marginBottom: '16px',
};

export const storeListToolbarItem = {
  marginRight: '10px',
};

export const storeListSearch = {
  position: 'relative',
  borderRadius: '4px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  marginRight: '16px',
};

export const storeListSearchHover = {
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
};

export const storeListSearchIcon = {
  padding: '0 8px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const storeListInputRoot = {
  color: 'inherit',
};

export const storeListInputInput = {
  padding: '8px 8px 8px 0',
  paddingLeft: 'calc(1em + 32px)',
  transition: 'background-color 300ms ease-in-out',
  width: '100%',
  height: '35px',
};

export const storeListInputInputPlaceholder = {
  opacity: 0.7,
};

export const storeListHoursTableCell = {
  opacity: 0.7,
};

export const storeListActiveStatusChip = {
  color: 'white',
  backgroundColor: '#f44336',
};

export const storeListComingStatusChip = {
  color: 'white',
  backgroundColor: '#ff9800',
};

export const storeListClosedStatusChip = {
  color: 'white',
  backgroundColor: '#4caf50',
};

export const storeListEditIcon = {
  color: 'black',
};

export const storeListClickableRow = {
  cursor: 'pointer',
};
