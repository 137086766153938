import axios from 'axios';
import { useStores } from '../../index';

function useNewStoreDialog() {
  const { showAlert, getStoresApiTokenRedirect } = useStores();

  async function postNewStore(store, handleRequestId) {
    let success = false;
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores`,
          data: store,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Ny butik skapad!', 'success');
            success = true;
            handleRequestId();
          })
          .catch(() => {
            showAlert('Något gick fel!', 'error');
            success = false;
            handleRequestId();
          });
        return success;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return {
    postNewStore,
  };
}

export default useNewStoreDialog;
