import React from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import StoresContext from './stores-context';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export default function StoresProvider(props) {
  const { children } = props;
  const { instance, accounts, inProgress } = useMsal();

  async function getStoresApiTokenRedirect() {
    if (inProgress === 'none' && accounts.length > 0) {
      var request = {
        account: accounts[0],
        scopes: ['api://886519cb-0eb0-4415-a55c-cd84ed2887ee/Reader'],
      };
      return await instance.acquireTokenSilent(request).catch(async (error) => {
        console.log('silent token acquisition fails.');
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log('acquiring token using redirect');
          instance.acquireTokenRedirect(request);
        } else {
          console.error(error);
        }
      });
    }
    return null;
  }

  async function getGraphApiTokenRedirect() {
    if (inProgress === 'none' && accounts.length > 0) {
      var request = {
        account: accounts[0],
        scopes: ['User.Read'],
      };
      return await instance.acquireTokenSilent(request).catch(async (error) => {
        console.log('silent token acquisition fails.');
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          console.log('acquiring token using redirect');
          instance.acquireTokenRedirect(request);
        } else {
          console.error(error);
        }
      });
    }
    return null;
  }

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const showAlert = (message, severity) => {
    setSnackbar({
      open: true,
      message: message,
      severity: severity,
    });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  const methods = {
    showAlert,
    getStoresApiTokenRedirect,
    getGraphApiTokenRedirect,
    accounts,
  };
  return (
    <StoresContext.Provider value={methods}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        open={snackbar.open}
        autoHideDuration={2000}
      >
        <Alert elevation={7} variant="filled" severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StoresContext.Provider>
  );
}
