import React from 'react';
import { StoreDepartmentsForm, StoreDepartmentForm } from './index';
import { Route, Routes } from 'react-router-dom';
import * as styles from './store-edit-forms-styles';

function StoreDepartmentsOverview(props) {
  const {
    store,
    handleRequestId,
    usePageTitle,
    useBreadcrumbs,
    useFormId,
    PromptIfDirty,
  } = props;

  const departmentTypes = [
    {
      value: 1,
      label: 'Lager & Varuutlämning',
    },
    {
      value: 2,
      label: 'Reklamationsavdelning',
    },
  ];

  const getDepartmentName = (type) => {
    return departmentTypes.find((x) => x.value === type).label;
  };

  useBreadcrumbs([
    { text: 'Butiksregister', link: '/' },
    { text: 'Butiker', link: '/butiker' },
    { text: store.storeName },
  ]);

  return (
    <div style={styles.StoreEditRoot}>
      <Routes>
        <Route
          path={'/ny-avdelning'}
          element={
            <StoreDepartmentForm
              store={store}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              getDepartmentName={getDepartmentName}
              departmentTypes={departmentTypes}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
              newDepartment
            />
          }
        />
        <Route
          path={'/:avdelning'}
          element={
            <StoreDepartmentForm
              store={store}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              getDepartmentName={getDepartmentName}
              departmentTypes={departmentTypes}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
            />
          }
        />
        <Route
          path={'/'}
          element={
            <StoreDepartmentsForm
              store={store}
              handleRequestId={handleRequestId}
              usePageTitle={usePageTitle}
              getDepartmentName={getDepartmentName}
              departmentTypes={departmentTypes}
              useFormId={useFormId}
              PromptIfDirty={PromptIfDirty}
            />
          }
        />
      </Routes>
    </div>
  );
}

export default StoreDepartmentsOverview;
