import {
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Toolbar,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import useHolydate from '../../utils/holydate';
import * as styles from './opening-hours-dialog-styles';
import { parseISO } from 'date-fns';

function EnhancedTableToolbar(props) {
  const {
    globalValues,
    setFieldValue,
    values,
    setGlobalValues,
    hours,
    handleBlur,
    setUpdatingValues,
    touched,
    errors,
  } = props;

  const { hd } = useHolydate();

  const dateNowLocale = new Date();

  const getHoursObject = (object, date) => {
    let hoursObject = object.find((obj) => {
      return obj.dayOfWeek === date.getDay();
    });

    return (
      hoursObject ?? {
        closed: false,
        dayOfWeek: date.getDay(),
        hours: [{ openingTime: '10:00', closingTime: '19:00' }],
      }
    );
  };

  const [currentValue, setCurrentValue] = useState('');

  let timer;
  let timeoutVal = 1000; // time it takes to wait for user to stop typing in ms

  function handleKeyDown(e) {
    window.clearTimeout(timer);
  }

  function handleKeyUp(e, values, setFieldValue) {
    window.clearTimeout(timer);
    // prevent errant multiple timeouts from being generated
    timer = window.setTimeout(() => {
      setGlobalValues({
        ...globalValues,
        name: currentValue,
      });
      setFieldValue(
        'stores',
        values.stores.map((store) => {
          return { ...store, name: currentValue };
        }),
      );
      setUpdatingValues(false);
    }, timeoutVal);
  }

  return (
    <>
      <Toolbar disableGutters sx={styles.tableToolbar}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  ...styles.tableCell1,
                  ...styles.noBorder,
                  marginLeft: '0',
                }}
              >
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  margin="normal"
                  autoOk
                  format="yyyy-MM-dd"
                  label="Datum"
                  inputVariant="outlined"
                  size="small"
                  name="date"
                  value={parseISO(globalValues.date)}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const selectedDate = e;
                    setGlobalValues({
                      ...globalValues,
                      date: selectedDate.toISOString(),
                    });

                    setCurrentValue(
                      hd.get(selectedDate.toLocaleDateString())?.[0].name ?? '',
                    );

                    setFieldValue(
                      'stores',
                      values.stores.map((store) => {
                        let hoursObject = getHoursObject(
                          store.openingHours,
                          selectedDate,
                        );
                        return {
                          ...store,
                          closed: hoursObject.closed,
                          openingTime: hoursObject.hours[0].openingTime,
                          closingTime:
                            hoursObject.hours[hoursObject.hours.length - 1]
                              .closingTime,
                          name:
                            hd.get(selectedDate.toLocaleDateString())?.[0]
                              .name ?? '',
                        };
                      }),
                    );

                    setFieldValue(
                      'date',
                      new Date(selectedDate.setHours(12, 0, 0, 0)).toISOString(),
                    );
                  }}
                  minDate={dateNowLocale}
                  error={
                    !!errors['date'] && !!touched['date'] && !!errors['date']
                  }
                />
              </TableCell>
              <TableCell sx={(styles.tableCell2, styles.noBorder)}>
                <Switch
                  name="closed"
                  value={globalValues.closed}
                  color="primary"
                  checked={!globalValues.closed}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setGlobalValues({
                      ...globalValues,
                      closed: !globalValues.closed,
                    });
                    setFieldValue(
                      'stores',
                      values.stores.map((store) => {
                        return { ...store, closed: !e.target.checked };
                      }),
                    );
                  }}
                />
              </TableCell>
              <TableCell sx={(styles.tableCell3, styles.noBorder)}>
                <TextField
                  sx={styles.openingHoursDropdown}
                  variant="outlined"
                  margin="normal"
                  size="small"
                  name="openingTime"
                  value={globalValues.openingTime}
                  disabled={globalValues.closed}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setGlobalValues({
                      ...globalValues,
                      openingTime: e.target.value,
                    });
                    setFieldValue(
                      'stores',
                      values.stores.map((store) => {
                        return { ...store, openingTime: e.target.value };
                      }),
                    );
                  }}
                  select
                >
                  {hours().map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell sx={(styles.tableCell4, styles.noBorder)}>
                <TextField
                  sx={styles.openingHoursDropdown}
                  variant="outlined"
                  margin="normal"
                  disabled={globalValues.closed}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setGlobalValues({
                      ...globalValues,
                      closingTime: e.target.value,
                    });
                    setFieldValue(
                      'stores',
                      values.stores.map((store) => {
                        return { ...store, closingTime: e.target.value };
                      }),
                    );
                  }}
                  size="small"
                  name="closingTime"
                  value={globalValues.closingTime}
                  select
                >
                  {hours().map((hour, hourIndex) =>
                    hourIndex > hours().indexOf(globalValues.openingTime) ? (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ) : null,
                  )}
                </TextField>
              </TableCell>
              <TableCell sx={(styles.tableCell5, styles.noBorder)}>
                <TextField
                  fullWidth
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  variant="outlined"
                  margin="normal"
                  label="Benämning"
                  size="small"
                  name="name"
                  disabled={Object.keys(hd.register).includes(currentValue)}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setCurrentValue(e.target.value);
                    setUpdatingValues(true);
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onKeyUp={(e) => handleKeyUp(e, values, setFieldValue)}
                  value={currentValue}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.noBorder}>Butik</TableCell>
              <TableCell sx={styles.noBorder} style={{ textAlign: 'center' }}>
                Stängt / öppet
              </TableCell>
              <TableCell sx={styles.noBorder} style={{ textAlign: 'center' }}>
                Öppnar
              </TableCell>
              <TableCell sx={styles.noBorder} style={{ textAlign: 'center' }}>
                Stänger
              </TableCell>
              <TableCell sx={styles.noBorder}>Benämning</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Toolbar>
    </>
  );
}

export default EnhancedTableToolbar;
