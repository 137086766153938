import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Button, DialogActions, Grid, MenuItem } from '@mui/material';
import { useNewStoreDialog } from './index';
import { InputField } from '../index';

function NewStoreDialogForm(props) {
  const storeTypes = [
    {
      value: 1,
      label: 'Varuhus',
    },
    {
      value: 2,
      label: 'Utlämningsställe',
    },
    {
      value: 3,
      label: 'Pop-up butik',
    },
    {
      value: 4,
      label: 'Reklamationsenhet',
    },
  ];

  const { postNewStore } = useNewStoreDialog();
  const { toggleNewStoreDialog, handleRequestId } = props;

  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));

  return (
    <Formik
      initialValues={{
        storeId: '',
        storeName: '',
        storeType: 1,
        webActivationDate: null,
        openingDate: todayMidnight,
        closingDate: null,
        slug: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        postNewStore(values, handleRequestId);
        toggleNewStoreDialog();
      }}
      validationSchema={Yup.object().shape({
        storeId: Yup.string()
          .required('Ange ett Id för butiken.')
          .min(3, 'Id måste vara minst 3 tecken långt.')
          .max(3, 'Id får inte vara mer än 3 tecken långt.'),
        storeName: Yup.string()
          .required('Ange ett namn för butiken.')
          .max(500, 'Ange färre tecken.'),
        storeType: Yup.number()
          .required('Ange en butikstyp.')
          .oneOf(storeTypes.map((type) => type.value)),
        webActivationDate: Yup.date()
          .max(
            Yup.ref('openingDate'),
            'Aktiveringsdatum för web måste vara tidigare än öppningsdatum',
          )
          .nullable(true),
        openingDate: Yup.date().when('closingDate', {
          is: (closingDate) => closingDate !== null,
          then: () =>
            Yup.date()
              .min(
                todayMidnight,
                'Öppningsdatum får infalla som tidigast dagens datum.',
              )
              .max(
                Yup.ref('closingDate'),
                'Öppningsdatum får endast infalla på ett tidigare datum än stängningsdatumet',
              )
              .required('Ange ett öppningsdatum'),
          otherwise: () =>
            Yup.date()
              .required('Ange ett öppningsdatum')
              .min(
                todayMidnight,
                'Öppningsdatum får infalla som tidigast dagens datum.',
              ),
        }),
        closingDate: Yup.date()
          .min(
            Yup.ref('openingDate'),
            'Stängningsdatum får endast infalla på ett senare datum än öppningsdatumet.',
          )
          .nullable(true),
        slug: Yup.string()
          .required(
            'Ange en slug. Denna visas i url:en för en butik på mio.se.',
          )
          .max(50, 'Ange färre tecken.')
          .matches(
            /^[a-zA-Z0-9-]*$/,
            'Får enbart innehålla tecken mellan a-z, 0-9 och -',
          ),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        const reuseableInputProps = {
          handleChange,
          handleBlur,
          touched,
          setFieldValue,
          errors,
          values: { ...values },
        };

        const minClosingDate = new Date(values.openingDate);
        minClosingDate.setDate(minClosingDate.getDate() + 1);

        return (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <InputField
                  name="storeId"
                  autoFocus
                  label="Butiks Id"
                  required
                  {...reuseableInputProps}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="storeName"
                  required
                  label="Butiksnamn"
                  {...reuseableInputProps}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="storeType"
                  label="Butikstyp"
                  required
                  select
                  {...reuseableInputProps}
                >
                  {storeTypes.map((type, index) => (
                    <MenuItem key={index} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={12} sx={{ mt: '15px' }}>
                <InputField
                  name="webActivationDate"
                  label="Aktiveringsdatum (Web)"
                  minDate={todayMidnight}
                  maxDate={values.openingDate}
                  date
                  {...reuseableInputProps}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: '15px' }}>
                <InputField
                  name="openingDate"
                  label="Öppningsdatum"
                  minDate={todayMidnight}
                  required
                  date
                  {...reuseableInputProps}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: '15px' }}>
                <InputField
                  name="closingDate"
                  label="Stängningsdatum"
                  minDate={minClosingDate}
                  date
                  {...reuseableInputProps}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  name="slug"
                  label="Slug"
                  required
                  {...reuseableInputProps}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Button color="primary" onClick={toggleNewStoreDialog}>
                Avbryt
              </Button>
              <Button
                type="submit"
                disabled={!dirty || isSubmitting}
                color="primary"
                autoFocus
              >
                Skapa
              </Button>
            </DialogActions>
          </form>
        );
      }}
    </Formik>
  );
}

export default NewStoreDialogForm;
