import React from 'react';
import * as styles from './spinner-styles';
import { CircularProgress } from '@mui/material';

function Spinner() {
  return (
    <div style={styles.spinnerRoot}>
      <CircularProgress />
    </div>
  );
}

export default Spinner;
