/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import LeftDrawer from './left-drawer';
import useStores from '../stores-context/use-stores';
import axios from 'axios';
import { Avatar } from '@mui/material';
import * as styles from './navbar-styles';

function Navbar(props) {
  const { accounts, getGraphApiTokenRedirect } = useStores();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userAvatar, setUserAvatar] = useState({ imageUrl: '' });
  const toggleDrawer = () => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    async function callMsGraphApi() {
      await getGraphApiTokenRedirect()
        .then((res) => {
          axios({
            method: 'GET',
            url: 'https://graph.microsoft.com/v1.0/me/photo/$value',
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          })
            .then((response) => {
              const url = window.URL || window.webkitURL;
              const downloadUrl = url.createObjectURL(
                new Blob([response.data]),
              );
              setUserAvatar({
                imageUrl: downloadUrl,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    callMsGraphApi();
  }, []);

  return (
    <div style={styles.navbarRoot}>
      <div style={styles.navbarNavbarIcons}>
        <div style={styles.navbarMenuButton} onClick={toggleDrawer()}>
          <MenuIcon />
        </div>
        <div>
          <div style={styles.navbarMenuButton} onClick={toggleDrawer()}>
            {userAvatar && (
              <Avatar
                alt={accounts[0].name}
                src={userAvatar.imageUrl}
                sx={styles.navbarAvatar}
              />
            )}
          </div>
        </div>
      </div>
      <LeftDrawer
        drawerOpen={drawerOpen}
        accounts={accounts}
        toggleDrawer={toggleDrawer}
      />
    </div>
  );
}

export default Navbar;
