import { useState } from 'react';
import axios from 'axios';
import { useStores } from '../../index';

function useOpeningHoursDialog() {
  const { showAlert, getStoresApiTokenRedirect } = useStores();

  const [globalValues, setGlobalValues] = useState({
    date: new Date().toISOString(),
    closed: false,
    openingTime: '',
    closingTime: '',
    name: '',
  });

  async function updateExceptionalOpeningHours(data) {
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/exceptionalopeninghours`,
          data: data,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Uppdatering lyckades!', 'success');
          })
          .catch(() => {
            showAlert('Uppdatering misslyckades!', 'error');
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function deleteExceptionalOpeningHours(data) {
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/exceptionalopeninghours/delete`,
          data: data,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Uppdatering lyckades!', 'success');
          })
          .catch(() => {
            showAlert('Uppdatering misslyckades!', 'error');
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [updatingValues, setUpdatingValues] = useState(false);

  return {
    globalValues,
    updateExceptionalOpeningHours,
    deleteExceptionalOpeningHours,
    setGlobalValues,
    updatingValues,
    setUpdatingValues,
  };
}

export default useOpeningHoursDialog;
