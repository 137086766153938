import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function DeleteDialog(props) {
  const {
    deleteDialogOpen,
    toggleDeleteDialog,
    deleteFunction,
    handleRequestId,
    setSelected,
    selected,
  } = props;

  const handleDelete = () => {
    deleteFunction(selected, handleRequestId);
    setSelected([]);
  };

  return (
    <div>
      <Dialog
        open={deleteDialogOpen}
        onClose={toggleDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Ta bort'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Är du säker på att du vill fortsätta? All data kommer permanent att
            försvinna.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDeleteDialog} color="primary">
            AVBRYT
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            TA BORT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteDialog;
