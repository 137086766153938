import { Box, Divider, MenuItem, Switch, TextField } from '@mui/material';
import React from 'react';
import { StoreErrorAlerts } from './index';
import useHolydate from '../../utils/holydate';
import * as styles from './opening-hours-dialog-styles';

const hours = () => {
  var arr = [];
  var i;
  var j;
  for (i = 0; i < 24; i++) {
    for (j = 0; j < 2; j++) {
      arr.push((i < 10 ? '0' : null) + i + ':' + (j === 0 ? '00' : 30 * j));
    }
  }
  return arr;
};

export const Store = React.memo(
  ({
    store,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    index,
    mappedErrors,
  }) => {
    const { hd } = useHolydate();
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Box p={2} sx={styles.tableCell1}>
            {store.storeName}
          </Box>
          <Box p={2} sx={styles.tableCell2}>
            <Switch
              name={`stores[${index}].closed`}
              value={values.stores[index].closed}
              onBlur={handleBlur}
              color="primary"
              checked={!values.stores[index].closed}
              onChange={(e) => {
                handleChange(e);
                setFieldValue(`stores[${index}].closed`, !e.target.checked);
              }}
            />
          </Box>
          <Box p={2}>
            <TextField
              sx={styles.openingHoursDropdown}
              variant="outlined"
              margin="normal"
              size="small"
              name={`stores[${index}].openingTime`}
              value={values.stores[index].openingTime}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={values.stores[index].closed}
              select
            >
              {hours().map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {!values.stores[index].closed ? hour : null}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box p={2}>
            <TextField
              sx={styles.openingHoursDropdown}
              variant="outlined"
              margin="normal"
              size="small"
              name={`stores[${index}].closingTime`}
              value={values.stores[index].closingTime}
              onChange={handleChange}
              disabled={values.stores[index].closed}
              onBlur={handleBlur}
              select
            >
              {hours().map((hour, hourIndex) =>
                hourIndex >
                hours().indexOf(values.stores[index].openingTime) ? (
                    <MenuItem key={hour} value={hour}>
                      {!values.stores[index].closed ? hour : null}
                    </MenuItem>
                  ) : null,
              )}
            </TextField>
          </Box>
          <Box p={2} sx={styles.tableCell5}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Benämning"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              name={`stores[${index}].name`}
              value={values.stores[index].name}
              disabled={Object.keys(hd.register).includes(
                values.stores[index].name,
              )}
            />
          </Box>
        </Box>
        <Box>
          {mappedErrors.stores[store.storeName] !== undefined ? (
            <Box p={2}>
              <StoreErrorAlerts store={store} mappedErrors={mappedErrors} />
            </Box>
          ) : null}
        </Box>
        <Divider variant="middle" />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    if (
      prevProps.store !== nextProps.store ||
      prevProps.mappedErrors !== nextProps.mappedErrors
    ) {
      return false;
    }
    return true;
  },
);
