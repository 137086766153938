import React from 'react';
import * as styles from './openinghours-file-upload-overview-styles';

const ColorBox = ({ color, children }) => {
  const boxStyle = {
    ...styles.openingHoursColorBox,
    backgroundColor: color,
  };

  return (
    <div className={styles.openingHoursColorBoxWrapper}>
      <div style={boxStyle} />
      <span className={styles.openingHoursColorBoxTextWrapper}>
        <span className={styles.openingHoursColorBoxText}>= {children}</span>
      </span>
    </div>
  );
};

const OpeningHoursFileUploadExplanation = () => {
  return (
    <div className={styles.openingHoursRoot}>
      <div className={styles.openingHoursExplanation}>
        <ColorBox color={styles.openingHoursWarningLight.color}>
          Öppettiderna har ändrats
        </ColorBox>
        <ColorBox color={styles.openingHoursInfoLight.color}>
          Datumets titel har ändrats
        </ColorBox>
        <ColorBox color={styles.openingHoursSuccessLight.color}>
          Öppettiderna och datumets titel har ändrats
        </ColorBox>
      </div>
    </div>
  );
};

export default OpeningHoursFileUploadExplanation;
