export const landingPageWrapper = {
  display: 'flex',
  marginTop: '24px',
};

export const landingPageCard = {
  maxWidth: '345px',
  marginRight: '50px',
};

export const landingPageMedia = {
  height: '140px',
};
