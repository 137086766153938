import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function StoreEditDescriptionDialog(props) {
  const { setFieldValue, values, errors, touched } = props;

  const [open, setOpen] = useState(false);

  const [description, setDescription] = useState('');

  const handleClickOpen = () => {
    setDescription(values.description);
    setOpen(true);
  };

  const handleClose = () => {
    setDescription('');
    setOpen(false);
  };

  return (
    <div>
      <TextField
        fullWidth
        inputProps={{
          readOnly: true,
        }}
        onClick={handleClickOpen}
        variant="outlined"
        multiline
        rows={6}
        margin="normal"
        name="description"
        value={values['description']}
        label="Presentationstext"
        helperText={
          errors['description'] &&
          touched['description'] &&
          errors['description']
        }
        error={
          !!errors['description'] &&
          !!touched['description'] &&
          !!errors['description']
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        style={{ marginLeft: 64 }}
      >
        <Box px={3} pt={3} pb={1} display="flex" justifyContent="space-between">
          <Typography variant="h6">
            {'Editera butikens presentationstext'}
          </Typography>
          <CloseIcon onClick={handleClose} style={{ color: 'grey' }} />
        </Box>
        <DialogContent>
          <TextField
            fullWidth
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            multiline
            rows={20}
            margin="normal"
            value={description}
            label="Presentationstext"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Kasta ändringar
          </Button>
          <Button
            onClick={() => {
              setFieldValue('description', description);
              handleClose();
            }}
            color="primary"
            autoFocus
            disabled={description === values.description}
          >
            Ändra
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default StoreEditDescriptionDialog;
