import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Checkbox,
  Divider,
  InputBase,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import * as styles from './store-edit-forms-styles';

function AddAdjacentStoresDialog(props) {
  const { stores, values, setFieldValue, currentStore } = props;
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState([]);

  const handleAdjacentStoreSearch = (e) => {
    var val = e.target.value;
    val.length > 0 ? setSearchValue(val) : setSearchValue(null);
  };

  const isSelected = (storeId) => {
    return selected.indexOf(storeId) >= 0;
  };

  const addToSelected = (storeId) => {
    if (isSelected(storeId)) {setSelected(selected.filter((s) => s !== storeId));} else {setSelected([...selected, storeId]);}
  };

  const filteredList =
    stores &&
    stores.filter((store) => {
      return (
        (store.storeName.toLowerCase().includes(searchValue) &&
          !isSelected(store.storeId) &&
          store.storeId !== currentStore) ||
        (store.storeId.includes(searchValue) &&
          !isSelected(store.storeId) &&
          store.storeId !== currentStore)
      );
    });

  const handleClickOpen = () => {
    setOpen(true);
    setSelected(values.adjacentStores);
  };

  const handleClose = () => {
    setSearchValue('');
    setOpen(false);
  };

  return (
    <>
      <Link
        onClick={handleClickOpen}
        component="button"
        type="button"
        variant="body2"
        align="right"
        style={{ textDecoration: 'none' }}
      >
        LÄGG TILL
      </Link>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Lägg till närliggande butiker'}
        </DialogTitle>
        <DialogContent sx={styles.StoreEditAddAdjacentStoresDialogContent}>
          <div style={styles.StoreEditSearch}>
            <div style={styles.StoreEditSearchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              fullWidth
              onChange={(e) => handleAdjacentStoreSearch(e)}
              placeholder="Sök efter butik…"
              sx={(styles.StoreEditInputRoot, styles.StoreEditInputInput)}
              inputProps={{ 'aria-label': 'Sök efter butik…' }}
            />
          </div>
          <List sx={styles.StoreEditAddAdjacentStoresDialogSearchList}>
            {filteredList &&
              filteredList.map((store) => (
                <ListItem
                  disableGutters
                  key={store.storeId}
                  onClick={() => addToSelected(store.storeId)}
                >
                  <Checkbox
                    checked={isSelected(store.storeId)}
                    color="primary"
                  />
                  <Typography>{store.storeName}</Typography>
                </ListItem>
              ))}
          </List>
          {selected.length > 0 ? (
            <>
              {' '}
              <Typography variant="body2" color="textSecondary">
                Valda butiker
              </Typography>
              <Divider />
            </>
          ) : null}

          <List>
            {stores &&
              selected.map((store) => (
                <ListItem
                  disableGutters
                  key={store}
                  onClick={() => addToSelected(store)}
                >
                  <Checkbox checked={isSelected(store)} color="primary" />
                  <Typography>
                    {stores.find(({ storeId }) => storeId === store).storeName}
                  </Typography>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Avbryt
          </Button>
          <Button
            onClick={() => {
              setFieldValue('adjacentStores', selected);
              handleClose();
            }}
            disabled={false}
            color="primary"
            autoFocus
          >
            LÄGG TILL
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddAdjacentStoresDialog;
