import { useState, useCallback } from 'react';
import { useStores } from '../../index';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

function useNewZipCodesFileDialog() {
  const [file, setFile] = useState('');
  const [fileText, setFileText] = useState('');

  const { showAlert, getStoresApiTokenRedirect } = useStores();

  async function postNewFile(
    values,
    toggleNewZipCodesFileDialog,
    handleRequestId,
  ) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('activationDate', values.activationDate);
    formData.append('description', values.description);
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'POST',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/zipCodes/upload`,
          data: formData,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(() => {
            showAlert('Filen uppladdad!', 'success');
            toggleNewZipCodesFileDialog();
            clearAcceptedFile();
            handleRequestId();
          })
          .catch(() => {
            showAlert('Något gick fel!', 'error');
          });
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onDrop = useCallback((acceptedFile) => {
    acceptedFile.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const readerResult = reader.result.split('\n');

        setFile(file);
        setFileText(readerResult.slice(1, readerResult.length - 1));
      };
      reader.readAsText(file);
    });
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
    },
  });

  const clearAcceptedFile = () => {
    setFile('');
    setFileText('');
    fileRejections.length = 0;
  };

  return {
    onDrop,
    file,
    fileText,
    clearAcceptedFile,
    getRootProps,
    getInputProps,
    fileRejections,
    postNewFile,
  };
}

export default useNewZipCodesFileDialog;
