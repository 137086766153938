import React, { useEffect, useRef, useState } from 'react';
import { Layout } from '../../../components';
import { Button, CircularProgress } from '@mui/material';
import { useOpeningHoursFileUploadOverview } from './hooks';
import {
  OpeningHoursFileUploadErrors,
  OpeningHoursFileUploadExplanation,
  OpeningHoursFileUploadTable,
  OpeningHoursFileUploadHelp,
} from './index';
import * as styles from './openinghours-file-upload-overview-styles';

const breadcrumbs = [
  { text: 'Butiksregister', link: '/' },
  { text: 'Butiker', link: '/butiker' },
  { text: 'Öppettider - Läs in fil', link: '/butiker/oppettider' },
];

const OpeningHoursFileUploadOverview = () => {
  const {
    fileUploading,
    postNewFile,
    fileUploadResponse,
    clearResponse,
    confirmOpeningHours,
    confirmLoading,
  } = useOpeningHoursFileUploadOverview();

  const [fileList, setFileList] = useState(null);

  useEffect(() => {
    if (fileList && fileList.length > 0) {
      postNewFile(fileList[0]);
    }
  }, [fileList]);

  useEffect(() => {
    if (!fileUploadResponse) {
      setFileList(null);
      if (openingHoursInputRef.current) {
        openingHoursInputRef.current.value = '';
      }
    }
  }, [fileUploadResponse]);

  const submitChanges = () => {
    if (fileUploadResponse?.result) {
      confirmOpeningHours(fileUploadResponse.result);
    }
  };

  const headerButtons =
    fileUploadResponse?.result?.rows &&
    fileUploadResponse.result.rows.length > 0 ? (
        <>
          <Button
            style={{ color: 'white', marginRight: '1rem' }}
            color="success"
            variant="contained"
            onClick={submitChanges}
            disabled={confirmLoading}
          >
          Godkänn förändringar
          </Button>
          <Button
            className={styles.openingHoursClearButton}
            style={{ color: 'white' }}
            variant="contained"
            onClick={clearResponse}
            disabled={confirmLoading}
          >
          Rensa
          </Button>
        </>
      ) : (
        <Button
          style={{ color: 'white' }}
          color="primary"
          variant="contained"
          onClick={() => openingHoursInputRef.current?.click()}
        >
        Välj fil
        </Button>
      );

  const openingHoursInputRef = useRef(null);

  return (
    <Layout
      crumbs={breadcrumbs}
      title={'Öppettider - Läs in fil'}
      pageTitle={'Öppettider - Läs in fil'}
      headerButton={headerButtons}
    >
      {fileUploading ? (
        <div className={styles.openingHoursSpinner}>
          <CircularProgress />
        </div>
      ) : fileUploadResponse ? (
        <>
          {fileUploadResponse.userErrorMessages.length > 0 && (
            <OpeningHoursFileUploadErrors
              errors={fileUploadResponse.userErrorMessages}
            />
          )}
          {fileUploadResponse.result && (
            <>
              <OpeningHoursFileUploadExplanation />
              <OpeningHoursFileUploadTable
                tableData={fileUploadResponse.result}
              />
            </>
          )}
        </>
      ) : (
        <OpeningHoursFileUploadHelp />
      )}
      <input
        type="file"
        ref={openingHoursInputRef}
        style={{ display: 'none' }}
        accept="text/plain, text/csv"
        onChange={(e) => setFileList(e.target.files ?? null)}
      />
    </Layout>
  );
};

export default OpeningHoursFileUploadOverview;
