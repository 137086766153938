import React from 'react';
import Router from './router';
import { StoresProvider } from '../components';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { Box, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function App() {
  useMsalAuthentication('redirect', {
    scopes: ['User.Read'],
  });

  return (
    <>
      <AuthenticatedTemplate>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StoresProvider>
            <Router />
          </StoresProvider>
        </LocalizationProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box display="flex" width={1000} height={1000} m="auto">
          <Box m="auto">
            <CircularProgress />
          </Box>
        </Box>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
