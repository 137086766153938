import React from 'react';

function GoogleErrorList({ error }) {
  return (
    <ul style={{ color: 'red' }}>
      {error.map((data, errorIndex) => (
        <li key={`error${errorIndex}`}>
          {`${data.storeId} - ${data.message}`}
          {data.details && data.details.length > 0 && (
            <ul>
              {data.details.map((detail, detailIndex) => (
                <li
                  key={`error${errorIndex}detail${detailIndex}`}
                >{`${detail.reason} (${detail.fieldMask})`}</li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}

export default GoogleErrorList;
