export const storeRoot = {
  width: '100%',
  overflow: 'hidden',
};

export const storePaper = {
  width: '100%',
  padding: '24px',
};

export const storeRouterRoot = {
  flexGrow: '1',
};

export const storeTabs = {
  display: 'flex',
  gap: '8px',
};

export const storeLink = {
  display: 'inline',
  color: 'rgba(0, 0, 0, 0.6)',
  textDecoration: 'none',
  padding: '16px',
};

export const selectedStoreLink = {
  display: 'inline',
  color: '#1976d2',
  borderBottom: '2px solid #1976d2',
};
