import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useStores } from '../../../components/index';

function useZipCodesOverview() {
  const [requestId, setRequestId] = useState(0);

  const [zipCodeFiles, setZipCodeFiles] = useState({
    data: null,
    loading: false,
    completed: false,
    error: false,
  });

  const { getStoresApiTokenRedirect } = useStores();

  const handleRequestId = () => {
    setRequestId(new Date().getUTCMilliseconds());
  };

  useEffect(() => {
    setZipCodeFiles({ ...zipCodeFiles, loading: true });
    async function getStores() {
      await getStoresApiTokenRedirect()
        .then((res) => {
          axios({
            method: 'GET',
            url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/zipCodes`,
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          })
            .then((res) =>
              setZipCodeFiles({
                data: res.data,
                loading: false,
                completed: true,
                error: false,
              }),
            )
            .catch((error) =>
              setZipCodeFiles({
                data: null,
                loading: false,
                completed: true,
                error: true,
              }),
            );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getStores();
  }, [requestId]);

  const [zipCodesFilesFilter, setZipCodesFilesFilter] = useState('');

  const sortByDate = (array) => {
    return (
      array &&
      array.sort((a, b) => {
        var keyA = new Date(a.activationDate);
        var keyB = new Date(b.activationDate);
        // Compare the 2 dates
        if (keyA > keyB) {return -1;}
        if (keyA < keyB) {return 1;}
        return 0;
      })
    );
  };

  // Handle search input (zipCodesFilesFilter)
  const handleFilter = (e) => {
    setZipCodesFilesFilter(e.target.value.toLowerCase().toString());
  };
  //--------------- end

  // Filter the array containing files by search string (zipCodesFilesFilter)
  const filteredFilesBySearch =
    zipCodeFiles.data &&
    sortByDate([
      ...zipCodeFiles.data.filter((file) =>
        file.fileName.toString().toLowerCase().includes(zipCodesFilesFilter),
      ),
    ]);
  // ------------- end

  // Toggle new file upload dialog
  const [newZipCodesFileDialogOpen, setNewZipCodesFileDialogOpen] =
    useState(false);
  const toggleNewZipCodesFileDialog = useCallback(() => {
    setNewZipCodesFileDialogOpen(!newZipCodesFileDialogOpen);
  });
  //------------ end

  return {
    zipCodeFiles,
    newZipCodesFileDialogOpen,
    toggleNewZipCodesFileDialog,
    filteredFilesBySearch,
    handleFilter,
    handleRequestId,
  };
}

export default useZipCodesOverview;
