import React from 'react';
import { useStoreEditForms } from './hooks/index';
import { Formik, Form } from 'formik';
import { Button, List, ListItem, Typography, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import * as styles from './store-edit-forms-styles';

function StoreDepartmentsForm(props) {
  const {
    store,
    handleRequestId,
    getDepartmentName,
    departmentTypes,
    usePageTitle,
    useFormId,
    PromptIfDirty,
  } = props;

  const { updateStore } = useStoreEditForms();

  const formId = 'store-departments-form';

  usePageTitle('Avdelningar');
  useFormId(formId);

  const allStoreDepartmentsTaken = departmentTypes.every(
    (item) =>
      store.departments.findIndex(
        (find) => find.departmentType === item.value,
      ) !== -1,
  );

  return (
    <div>
      <Formik
        initialValues={{
          ...store,
          departments: store.departments ?? [],
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          updateStore(values, handleRequestId, store.storeId);
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
        }) => (
          <Form sx={styles.StoreEditStoreDepartmentsOverviewForm} id={formId}>
            <PromptIfDirty />
            <List>
              {values.departments.map((department, departmentIndex) => (
                <ListItem
                  key={departmentIndex}
                  sx={styles.StoreEditListItemSpaceBetween}
                  divider
                >
                  <div>
                    <Typography variant="body1">
                      {getDepartmentName(department.departmentType)}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {department.description}
                    </Typography>
                  </div>
                  <div>
                    <Link
                      to={`/butiker/butik/${store.storeId}/avdelningar/${department.departmentType}`}
                      style={styles.StoreEditCreateIconLink}
                    >
                      <CreateIcon />
                    </Link>
                    <DeleteIcon
                      sx={styles.StoreEditDeleteIconLink}
                      onClick={() =>
                        setFieldValue(
                          'departments',
                          values.departments.filter(
                            (d, i) => i !== departmentIndex,
                          ),
                        )
                      }
                    />
                  </div>
                </ListItem>
              ))}
              {values.departments.length > 0 ? (
                <ListItem align="right" sx={styles.StoreEditListItemFlexEnd}>
                  <Tooltip
                    title={
                      allStoreDepartmentsTaken
                        ? 'Alla avdelningstyper är upptagna. Redigera eller ta bort befintlig avdelning. Glöm inte att spara om du väljer att ta bort befintlig avdelning!'
                        : ''
                    }
                  >
                    <span>
                      <Button
                        disabled={allStoreDepartmentsTaken}
                        color="primary"
                      >
                        <Link
                          to={`/butiker/butik/${store.storeId}/avdelningar/ny-avdelning`}
                          style={styles.StoreEditAddDepartmentLink}
                        >
                          <Typography variant="body2">LÄGG TILL</Typography>
                        </Link>
                      </Button>
                    </span>
                  </Tooltip>
                </ListItem>
              ) : (
                <ListItem align="left" sx={styles.StoreEditListItemFlexEnd}>
                  <Typography>
                    Det finns inga avdelningar registerade ännu.
                    <Button color="primary">
                      <Link
                        to={`/butiker/butik/${store.storeId}/avdelningar/ny-avdelning`}
                        style={styles.StoreEditAddDepartmentLink}
                      >
                        Klicka här
                      </Link>
                    </Button>
                    för att lägga till en avdelning.
                  </Typography>
                </ListItem>
              )}
            </List>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default StoreDepartmentsForm;
