import { useState } from 'react';
import { useStores } from '../../../../components/index';
import axios from 'axios';

export default function useOpeningHoursFileUploadOverview() {
  const [fileUploading, setFileUploading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);

  const { showAlert, getStoresApiTokenRedirect } = useStores();

  async function postNewFile(file) {
    setFileUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await getStoresApiTokenRedirect();
      const accessToken = res.accessToken;

      const uploadResponse = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores/opening-hours-upload`,
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'form-data',
        },
      });

      setFileUploadResponse(uploadResponse.data);
      setFileUploading(false);
    } catch (error) {
      if (error.response.status == 400) {
        setFileUploadResponse(error.response.data);
      } else {
        showAlert('Något gick fel!', 'error');
      }

      setFileUploading(false);
    }
  }

  async function confirmOpeningHours(openingHours) {
    setConfirmLoading(true);

    try {
      const res = await getStoresApiTokenRedirect();
      const accessToken = res.accessToken;

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores/opening-hours-confirm`,
        data: openingHours,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      showAlert('Öppettiderna uppdaterades!', 'success');
      clearResponse();
      setConfirmLoading(false);
    } catch (error) {
      showAlert('Något gick fel!', 'error');
      setConfirmLoading(false);
    }
  }

  const clearResponse = () => {
    setFileUploadResponse(null);
  };

  return {
    fileUploading,
    postNewFile,
    fileUploadResponse,
    clearResponse,
    confirmOpeningHours,
    confirmLoading,
  };
}
