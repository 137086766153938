import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { OpeningHoursControls } from './index';
import * as styles from './store-edit-forms-styles';

function OpeningHours(props) {
  const { values } = props;

  return (
    <div>
      <Typography variant="h5" sx={styles.StoreEditFormHeadersLeftPadding}>
        Ordinarie Öppettider
      </Typography>
      <List sx={styles.StoreEditOpeningHoursList}>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={1} {...props} />
        </ListItem>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={2} {...props} />
        </ListItem>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={3} {...props} />
        </ListItem>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={4} {...props} />
        </ListItem>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={5} {...props} />
        </ListItem>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={6} {...props} />
        </ListItem>
        <ListItem sx={styles.StoreEditOpeningHoursListItem}>
          <OpeningHoursControls values={values} dayOfWeek={0} {...props} />
        </ListItem>
      </List>
    </div>
  );
}

export default OpeningHours;
