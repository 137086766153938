import { Store } from './index';
import React from 'react';
import * as styles from './opening-hours-dialog-styles';

function Storeslist(props) {
  const { values, handleChange, handleBlur, setFieldValue, mappedErrors } =
    props;

  return (
    <div>
      <div style={styles.storesListContainer}>
        {values.stores.map((store, index) => (
          <Store
            key={store.storeId}
            values={values}
            store={store}
            handleChange={handleChange}
            handleBlur={handleBlur}
            index={index}
            setFieldValue={setFieldValue}
            mappedErrors={mappedErrors}
          />
        ))}
      </div>
    </div>
  );
}

export default Storeslist;
