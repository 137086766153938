import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  LandingPage,
  StoresOverview,
  StorePage,
  ZipCodesOverview,
  OpeningHoursFileUploadOverview,
} from '../pages/index';

const Router = () => {
  return (
    <Routes>
      <Route path="/butiker/butik/:id/*" element={<StorePage />} />
      <Route path="/butiker/oppettider" element={<OpeningHoursFileUploadOverview />} />
      <Route path="/butiker" element={<StoresOverview />} />
      <Route path="/postnummer" element={<ZipCodesOverview />} />
      <Route path="/" element={<LandingPage />} />
    </Routes>
  );
};

export default Router;
