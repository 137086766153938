import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  CircularProgress,
} from '@mui/material';
import GoogleErrorList from './google-error-list';

function GoogleSyncDialog({
  googleSyncDialogOpen,
  toggleGoogleSyncDialog,
  syncFunction,
  selected,
  errorData,
  loading,
}) {
  return (
    <div>
      <Dialog
        open={googleSyncDialogOpen}
        onClose={toggleGoogleSyncDialog}
        aria-labelledby="google-sync-dialog-title"
        aria-describedby="google-sync-dialog-description"
        aria-errormessage="google-sync-dialog-error-message"
      >
        <DialogTitle id="google-sync-dialog-title">
          {'Synka butiksinfo till Google'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill synkronisera information om dessa butiker
            till Google?
          </DialogContentText>
          <DialogContentText id="google-sync-dialog-description">
            Den information som delas är: <b>öppettider</b>,{' '}
            <b>avvikande öppettider</b>, <b>adress</b> samt <b>webbadress</b>.
            Vanligtvis synkas data per automatik, men denna funktion kan vara
            till hands om något gått fel och man behöver om synka.
          </DialogContentText>
          {errorData && (
            <div id="google-sync-dialog-error-message">
              <Divider style={{ marginBottom: '1rem' }} />
              <DialogContentText>
                Någonting gick fel vid synkroniseringen till Google. Nedan
                följer information som kan vara till hjälp för att ta reda på
                vad som gick fel:
              </DialogContentText>
              {Array.isArray(errorData) ? (
                <GoogleErrorList error={errorData} />
              ) : (
                <DialogContentText>{errorData}</DialogContentText>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: `${loading ? 'center' : 'flex-end'}`,
            paddingBottom: '.7rem',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={toggleGoogleSyncDialog} color="primary">
                {errorData ? 'STÄNG' : 'AVBRYT'}
              </Button>
              {!errorData && (
                <Button
                  onClick={() => syncFunction(selected)}
                  color="primary"
                  autoFocus
                >
                  JA, SYNKA
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GoogleSyncDialog;
