import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { App } from './app/index';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: '886519cb-0eb0-4415-a55c-cd84ed2887ee',
    authority:
      'https://login.microsoftonline.com/027d910f-d438-4b74-b99e-3d163b988963',
    redirectUri: window.location.origin,
  },
};

const pca = new PublicClientApplication(msalConfig);

const AppProvider = () => (
  <MsalProvider instance={pca}>
    <Router>
      <App />
    </Router>
  </MsalProvider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppProvider />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
