import React, { useEffect } from 'react';
import * as styles from './layout-styles';
import { Navbar, Header } from '../index';

function Layout(props) {
  const { title, children } = props;

  useEffect(() => {
    document.title = `Mio Butiker | ${title}`;
  });

  return (
    <div style={styles.layoutRoot}>
      <Navbar />
      <main style={styles.layoutMain}>
        <Header {...props} />
        {children}
      </main>
    </div>
  );
}

export default Layout;
