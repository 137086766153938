import React, { Fragment } from 'react';
import {
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableBody,
  Chip,
  Button,
} from '@mui/material';

import GetAppIcon from '@mui/icons-material/GetApp';
import CreateIcon from '@mui/icons-material/Create';
import CircularProgress from '@mui/material/CircularProgress';

import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  useZipCodesList,
} from './index';

import { DeleteDialog, EditFileDialog } from '../index';
import ProgressionModal from '../progression-modal/progression-modal.js';
import * as styles from './zip-codes-styles';

function ZipCodesList(props) {
  const {
    addToSelected,
    isSelected,
    selected,
    setSelected,
    deleteFiles,
    toggleDeleteDialog,
    deleteDialogOpen,
    toggleEditFileDialog,
    editFileDialogOpen,
    setFileIndex,
    fileIndex,
    activateFile,
    activateResponse,
  } = useZipCodesList();

  const { zipCodeFiles, filteredFilesBySearch, handleFilter, handleRequestId } =
    props;

  const formattedDateString = (activationDate) => {
    var d = new Date(activationDate);

    return (
      d.getFullYear() +
      '-' +
      ('0' + (d.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + d.getDate()).slice(-2) +
      ' - ' +
      ('0' + d.getHours()).slice(-2) +
      ':' +
      ('0' + d.getMinutes()).slice(-2)
    );
  };

  const FileStatus = ({ file }) => {
    const dateNowLocale = new Date();
    const activationDate = new Date(file.activationDate);
    const activeStatus = file.isActive;

    if (activeStatus) {
      return (
        <Chip
          sx={styles.zipCodesListActiveStatusChip}
          size="small"
          label="Aktiv"
        />
      );
    }

    if (activationDate > dateNowLocale) {
      return (
        <Chip
          sx={styles.zipCodesListComingStatusChip}
          size="small"
          label="Kommande"
        />
      );
    }

    return (
      <Chip
        sx={styles.zipCodesListInactiveStatusChip}
        size="small"
        label="Inaktiv"
      />
    );
  };

  return (
    <div sx={styles.zipCodesListRoot}>
      <TableContainer component={Paper} style={{ padding: '15px' }}>
        {zipCodeFiles && (
          <Fragment>
            <EnhancedTableToolbar
              selected={selected}
              zipCodeFiles={zipCodeFiles}
              handleFilter={handleFilter}
              toggleDeleteDialog={toggleDeleteDialog}
            />
            <Table>
              <EnhancedTableHead
                filteredFilesBySearch={filteredFilesBySearch}
                setSelected={setSelected}
                isSelected={isSelected}
                selected={selected}
                totalFilesCount={
                  filteredFilesBySearch &&
                  Object.keys(filteredFilesBySearch).length
                }
              />
              <TableBody>
                {filteredFilesBySearch &&
                  filteredFilesBySearch.map((file, fileIndex) => (
                    <TableRow hover key={file.id}>
                      <TableCell align="left" padding="checkbox">
                        <Checkbox
                          onClick={() => addToSelected(file.id)}
                          color="primary"
                          checked={isSelected(file.id)}
                        />
                      </TableCell>
                      <TableCell
                        sx={styles.zipCodesListFileNameCell}
                        align="left"
                      >
                        {file.fileName}
                      </TableCell>
                      <TableCell align="left">{file.description}</TableCell>
                      <TableCell align="center">
                        {formattedDateString(file.activationDate)}
                      </TableCell>

                      <TableCell align="center">
                        <FileStatus file={file} />
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => {
                            toggleEditFileDialog();
                            setFileIndex(fileIndex);
                          }}
                          disabled={file.isActive}
                        >
                          <CreateIcon />
                        </Button>
                        <Button href={file.uri}>
                          <GetAppIcon />
                        </Button>
                        <Button
                          disabled={file.isActive || activateResponse.loading}
                          onClick={() => activateFile(handleRequestId, file.id)}
                        >
                          {activateResponse.loading &&
                          activateResponse.id === file.id ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              'Aktivera'
                            )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Fragment>
        )}
      </TableContainer>
      <DeleteDialog
        deleteDialogOpen={deleteDialogOpen}
        toggleDeleteDialog={toggleDeleteDialog}
        deleteFunction={deleteFiles}
        handleRequestId={handleRequestId}
        setSelected={setSelected}
        selected={selected}
      />
      <EditFileDialog
        zipCodeFiles={zipCodeFiles}
        toggleEditFileDialog={toggleEditFileDialog}
        editFileDialogOpen={editFileDialogOpen}
        filteredFilesBySearch={filteredFilesBySearch}
        fileIndex={fileIndex}
        handleRequestId={handleRequestId}
      />
      <ProgressionModal loadingModal={activateResponse.loading} />
    </div>
  );
}

export default ZipCodesList;
