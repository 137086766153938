import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useStores } from '../../../components/index';

function useStoresOverview() {
  const [stores, setStores] = useState({
    data: null,
    loading: false,
    completed: false,
    error: false,
  });
  const [storeFilter, setStoreFilter] = useState('');
  const [selectedCategories, setSelectedCategories] = useState({
    storeType: [],
    ownerGroup: [],
  });

  const { getStoresApiTokenRedirect } = useStores();

  const [requestId, setRequestId] = useState(0);

  const handleRequestId = () => {
    setRequestId(new Date().getUTCMilliseconds());
  };

  const sortByName = (array) => {
    return (
      array &&
      array.sort((a, b) => {
        var keyA = a.storeName;
        var keyB = b.storeName;
        // Compare the 2 dates
        if (keyA < keyB) {return -1;}
        if (keyA > keyB) {return 1;}
        return 0;
      })
    );
  };

  useEffect(() => {
    setStores({ ...stores, loading: true });
    async function getStores() {
      await getStoresApiTokenRedirect()
        .then((res) => {
          axios({
            method: 'GET',
            url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores`,
            headers: {
              Authorization: `Bearer ${res.accessToken}`,
            },
          })
            .then((res) => {
              let sortedData = sortByName(res.data);
              setStores({
                ...stores,
                data: sortedData,
                loading: false,
                completed: true,
                error: false,
              });
            })
            .catch((error) =>
              setStores({
                ...stores,
                data: null,
                loading: false,
                completed: true,
                error: true,
              }),
            );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getStores();
  }, [requestId]);

  const handleFilter = (e) => {
    setStoreFilter(e.target.value.toLowerCase());
  };

  const handleClearFilters = () => {
    setSelectedCategories({
      storeType: [],
      storeId: [],
    });
  };

  const isSelectedCategory = (type, key) => {
    return selectedCategories[key].indexOf(type) !== -1;
  };

  const addToSelectedCategories = (type, key) => {
    if (isSelectedCategory(type, key)) {
      setSelectedCategories({
        ...selectedCategories,
        [key]: selectedCategories[key].filter((s) => s !== type),
      });
    } else {
      setSelectedCategories({
        ...selectedCategories,
        [key]: [...selectedCategories[key], type],
      });
    }
  };

  const filteredStoresBySearch =
    stores.data &&
    stores.data.filter((store) => {
      const { storeName, storeId } = store;
      const object = [storeName, storeId];
      return Object.values(object).some((val) =>
        val.toString().toLowerCase().includes(storeFilter),
      );
    });

  const filterKeys = Object.keys(selectedCategories);

  const filteredStoresByCategories =
    stores.data &&
    filteredStoresBySearch.filter((eachObj) => {
      return filterKeys.every((eachKey) => {
        if (!selectedCategories[eachKey].length) {return true;}
        return selectedCategories[eachKey].includes(eachObj[eachKey]);
      });
    });

  const filteredStores =
    selectedCategories.length !== 0
      ? filteredStoresByCategories
      : filteredStoresBySearch;

  const [newStoreDialogOpen, setNewStoreDialogOpen] = useState(false);
  const toggleNewStoreDialog = useCallback(() => {
    setNewStoreDialogOpen(!newStoreDialogOpen);
  });

  return {
    filteredStoresBySearch,
    filteredStoresByCategories,
    filteredStores,
    stores,
    handleFilter,
    newStoreDialogOpen,
    toggleNewStoreDialog,
    handleRequestId,
    selectedCategories,
    isSelectedCategory,
    addToSelectedCategories,
    handleClearFilters,
  };
}

export default useStoresOverview;
