import React, { Fragment } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Chip,
} from '@mui/material';

import { EnhancedTableHead, EnhancedTableToolbar } from './index';
import { useStoreList } from './hooks/index';
import * as styles from './store-list-styles';

import { OpeningHoursDialog, DeleteDialog, GoogleSyncDialog } from '../index';
import { useNavigate } from 'react-router-dom';

function StoreList(props) {
  const {
    deleteStores,
    addToSelected,
    isSelected,
    selected,
    setSelected,
    openingHoursDialogOpen,
    toggleOpeningHoursDialog,
    deleteDialogOpen,
    toggleDeleteDialog,
    googleSyncDialogOpen,
    toggleGoogleSyncDialog,
    syncStoresToGoogle,
    syncToGoogleResponse,
  } = useStoreList();
  const {
    filteredStoresBySearch,
    filteredStoresByCategories,
    stores,
    filteredStores,
    handleRequestId,
    handleFilter,
    selectedCategories,
    isSelectedCategory,
    addToSelectedCategories,
    handleClearFilters,
  } = props;

  const navigate = useNavigate();
  const handleRowClick = ({ storeId }) => {
    navigate(`/butiker/butik/${storeId}`);
  };

  const OpeningHours = ({ store, dayOfWeek }) => {
    if (!store.openingHours.length > 0 || !store.openingHours[dayOfWeek]) {return <Typography variant="body2">-</Typography>;}

    if (store.openingHours[dayOfWeek].closed) {return <Typography variant="body2">Stängt</Typography>;}

    return (
      <>
        {store.openingHours[dayOfWeek].hours.map((x, idx) => (
          <Typography variant="body2" key={idx}>
            {x.openingTime}-{x.closingTime}
          </Typography>
        ))}
      </>
    );
  };

  const StoreStatus = ({ store }) => {
    const openingDate = Date.parse(store.openingDate);
    const closingDate =
      store.closingDate !== null ? Date.parse(store.closingDate) : null;
    const dateNowLocale = Date.now();
    if (!store.fullyEnriched) {
      return (
        <Chip
          size="small"
          sx={styles.storeListClosedStatusChip}
          label="Ej fullberikad"
          style={{ color: 'white', backgroundColor: '#e53935' }}
        />
      );
    } else {
      return closingDate !== null && dateNowLocale > closingDate ? (
        <Chip
          size="small"
          sx={styles.storeListClosedStatusChip}
          label="Stängd"
          style={{ color: 'white', backgroundColor: '#e53935' }}
        />
      ) : dateNowLocale < openingDate ? (
        <Chip
          size="small"
          sx={styles.storeListComingStatusChip}
          label="Kommande"
          style={{ color: 'white', backgroundColor: '#ff9800' }}
        />
      ) : dateNowLocale >= openingDate &&
        (dateNowLocale <= closingDate || closingDate === null) ? (
          <Chip
            size="small"
            sx={styles.storeListActiveStatusChip}
            label="Aktiv"
            style={{ color: 'white', backgroundColor: '#66bb6a' }}
          />
        ) : null;
    }
  };

  return (
    <div style={styles.storeListRoot}>
      <TableContainer component={Paper} sx={{ p: '15px' }}>
        <EnhancedTableToolbar
          stores={stores}
          selected={selected}
          handleFilter={handleFilter}
          filteredStores={filteredStores}
          selectedCategories={selectedCategories}
          isSelectedCategory={isSelectedCategory}
          handleClearFilters={handleClearFilters}
          filteredStoresBySearch={filteredStoresBySearch}
          addToSelectedCategories={addToSelectedCategories}
          toggleOpeningHoursDialog={toggleOpeningHoursDialog}
          toggleDeleteDialog={toggleDeleteDialog}
          filteredStoresByCategories={filteredStoresByCategories}
          toggleGoogleSyncDialog={toggleGoogleSyncDialog}
        />
        <Table>
          <EnhancedTableHead
            setSelected={setSelected}
            isSelected={isSelected}
            filteredStores={filteredStores}
            storesCount={filteredStores && Object.keys(filteredStores).length}
            selected={selected}
          />
          <TableBody>
            {filteredStores &&
              filteredStores.map((store) => (
                <TableRow
                  hover
                  key={store.storeId}
                  sx={styles.storeListClickableRow}
                  onClick={() => handleRowClick(store)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(event) => {
                        event.stopPropagation();
                        addToSelected(store.storeId);
                      }}
                      color="primary"
                      checked={isSelected(store.storeId)}
                    />
                  </TableCell>
                  <TableCell align="left">{store.storeId}</TableCell>
                  <TableCell align="left">{store.storeName}</TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={1} />
                  </TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={2} />
                  </TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={3} />
                  </TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={4} />
                  </TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={5} />
                  </TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={6} />
                  </TableCell>
                  <TableCell align="left" sx={styles.storeListHoursTableCell}>
                    <OpeningHours store={store} dayOfWeek={0} />
                  </TableCell>
                  <TableCell align="left">
                    <StoreStatus store={store} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {stores && (
        <Fragment>
          <OpeningHoursDialog
            openingHoursDialogOpen={openingHoursDialogOpen}
            toggleOpeningHoursDialog={toggleOpeningHoursDialog}
            handleRequestId={handleRequestId}
            setSelected={setSelected}
            stores={stores.filter((store) => selected.includes(store.storeId))}
          />
          <DeleteDialog
            deleteDialogOpen={deleteDialogOpen}
            toggleDeleteDialog={toggleDeleteDialog}
            deleteFunction={deleteStores}
            handleRequestId={handleRequestId}
            setSelected={setSelected}
            selected={selected}
          />
          <GoogleSyncDialog
            googleSyncDialogOpen={googleSyncDialogOpen}
            toggleGoogleSyncDialog={toggleGoogleSyncDialog}
            syncFunction={syncStoresToGoogle}
            selected={selected}
            errorData={syncToGoogleResponse.errorData}
            loading={syncToGoogleResponse.loading}
          />
        </Fragment>
      )}
    </div>
  );
}

export default StoreList;
