export const openingHoursRoot = {
  marginBottom: '1.5rem',
};

export const openingHoursSpinner = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '3rem',
};

export const openingHoursTableCell = {
  whiteSpace: 'nowrap',
};

export const openingHoursTableCellTimeChanged = {
  backgroundColor: '#ff9800',
  whiteSpace: 'nowrap',
};

export const openingHoursTableCellNameChanged = {
  backgroundColor: '#03a9f4',
  whiteSpace: 'nowrap',
};

export const openingHoursTableCellTimeAndNameChanged = {
  backgroundColor: '#4caf50',
  whiteSpace: 'nowrap',
};

export const openingHoursExplanation = {
  display: 'flex',
  flexWrap: 'wrap',
};

export const openingHoursColorBox = {
  width: '2rem',
  height: '1.2rem',
  display: 'inline-block',
  marginRight: '0.5rem',
};

export const openingHoursColorBoxWrapper = {
  marginRight: '2rem',
  marginBottom: '0.5rem',
  display: 'flex',
  alignItems: 'end',
};

export const openingHoursColorBoxTextWrapper = {
  display: 'flex',
  alignItems: 'center',
};

export const openingHoursColorBoxText = {
  whiteSpace: 'nowrap',
};

export const openingHoursListItem = {
  color: '#d32f2f',
};

export const openingHoursClearButton = {
  backgroundColor: '#d32f2f',
};

export const openingHoursWarningLight = {
  color: '#ff9800',
};

export const openingHoursInfoLight = {
  color: '#03a9f4',
};

export const openingHoursSuccessLight = {
  color: '#4caf50',
};

export const openingHoursMarginBottom = {
  marginBottom: '0',
};

export const openingHoursMaxHeight = {
  maxHeight: '90vh',
};

export const openingHoursWidth = {
  width: '100%',
};