import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, DialogActions, TextField, Typography } from '@mui/material';
import { useEditFileDialog } from './index';
import InfoIcon from '@mui/icons-material/Info';

function EditFileDialogForm(props) {
  const {
    zipCodeFiles,
    toggleEditFileDialog,
    handleRequestId,
    filteredFilesBySearch,
    fileIndex,
  } = props;

  const { updateFile } = useEditFileDialog();

  const formattedDateAndTime = (dateValue) => {
    let d = new Date(dateValue.toString().split('GMT')[0] + ' UTC')
      .toISOString()
      .slice(0, -8);

    return d;
  };

  const existingActivationDate =
    zipCodeFiles &&
    zipCodeFiles
      .map((file) =>
        new Date(new Date(file.activationDate).setMinutes(0)).toISOString(),
      )
      .filter(
        (f) =>
          f.split('.')[0] + 'Z' !==
          filteredFilesBySearch[fileIndex].activationDate,
      );

  return (
    <Formik
      validationSchema={Yup.object().shape({
        activationDate: Yup.string()
          .required('Ange ett aktiveringsdatum')
          .notOneOf(
            existingActivationDate,
            'Det finns redan en fil med detta aktiveringsdatum, redigera befintlig eller välj annat datum.',
          ),
        description: Yup.string().max(500, 'Ange färre tecken'),
      })}
      initialValues={{
        ...filteredFilesBySearch[fileIndex],
        activationDate: filteredFilesBySearch[fileIndex].activationDate,
        description: filteredFilesBySearch[fileIndex].description,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        updateFile(values, values.id, handleRequestId);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon fontSize="small" style={{ paddingRight: 3 }} />
              <Typography variant="caption">
                Aktiveringsdatum anges endast i hel timme, alltså kommer minuter
                inte att registreras.
              </Typography>
            </div>
            <TextField
              fullWidth
              onChange={(e) => {
                setFieldValue(
                  'activationDate',
                  new Date(new Date(e.target.value).setMinutes(0)).toISOString(),
                );
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              margin="normal"
              id="datetime-local"
              label="Aktiveringsdatum"
              type="datetime-local"
              value={formattedDateAndTime(
                new Date(new Date(values.activationDate).setMinutes(0)),
              )}
              variant="outlined"
              name={'activationDate'}
              InputLabelProps={{
                shrink: true,
              }}
              error={
                !!errors['activationDate'] &&
                !!touched['activationDate'] &&
                !!errors['activationDate']
              }
              helperText={
                errors['activationDate'] &&
                touched['activationDate'] &&
                errors['activationDate']
              }
            />
            <TextField
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              multiline
              rows={4}
              margin="normal"
              placeholder="Beskriv filen med några ord"
              name="description"
              value={values['description']}
              label="Beskrivning"
              error={
                !!errors['description'] &&
                !!touched['description'] &&
                !!errors['description']
              }
              helperText={
                errors['description'] &&
                touched['description'] &&
                errors['description']
              }
            />
            <DialogActions>
              <Button color="primary" onClick={toggleEditFileDialog}>
                Avbryt
              </Button>
              <Button
                type="submit"
                disabled={!dirty || isSubmitting}
                color="primary"
                autoFocus
              >
                Uppdatera
              </Button>
            </DialogActions>
          </form>
        );
      }}
    </Formik>
  );
}

export default EditFileDialogForm;
