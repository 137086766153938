export const leftDrawerListWrapper = {
  minWidth: '250px',
  height: '100%',
  marginLeft: '64px',
};

export const leftDrawerList = {
  paddingBottom: '0',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const leftDrawerDrawer = {
  left: '64px',
};

export const leftDrawerAccount = {
  height: '60px',
  padding: '12px',
  flexWrap: 'wrap',
};

export const leftDrawerLogoutButton = {
  color: '#d32f2f',
  borderColor: '#d32f2f',
  marginLeft: '10px',
};

export const leftDrawerDrawerTitle = {
  paddingTop: '21px',
  paddingBottom: '10px',
};

export const navbarRoot = {
  height: '100%',
  minWidth: '64px',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '1301',
  backgroundColor: '#1e88e5',
  overflowX: 'hidden',
  color: '#f4f4f4',
  display: 'flex',
  flexDirection: 'row',
};

export const navbarNavbarIcons = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const navbarMenuButton = {
  width: '64px',
  height: '60px',
  padding: '12px',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const navbarAvatar = {
  height: '35px',
  width: '35px',
};
