export const headerRoot = {
  flex: '1',
};

export const headerDivider = {
  margin: '24px 0',
};

export const headerToolbar = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  marginBottom: '24px',
};
