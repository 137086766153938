/* eslint-disable consistent-return */
function storeEditFormsFunctions() {
  const weekdays = [
    {
      value: 1,
      label: 'Måndag',
    },
    {
      value: 2,
      label: 'Tisdag',
    },
    {
      value: 3,
      label: 'Onsdag',
    },
    {
      value: 4,
      label: 'Torsdag',
    },
    {
      value: 5,
      label: 'Fredag',
    },
    {
      value: 6,
      label: 'Lördag',
    },
    {
      value: 0,
      label: 'Söndag',
    },
  ];

  const dropdownHours = () => {
    var arr = [];
    var i;
    var j;
    for (i = 0; i < 24; i++) {
      for (j = 0; j < 2; j++) {
        arr.push((i < 10 ? '0' : null) + i + ':' + (j === 0 ? '00' : 30 * j));
      }
    }
    return arr;
  };

  const objectToArray = (values, key, zoneCounter) => {
    let array = [];
    Object.entries(values[key]).forEach(([key, value]) =>
      array.push({ [key]: value }),
    );
    return array.filter((item, index) => {
      return (
        Object.values(item).some((e) => !!e || e === 0) || index <= zoneCounter
      );
    });
  };

  const openingHourCell = (values, index) => {
    if (index === 0) {
      return dropdownHours().filter(
        (dropdownHour, dropdownHoursIndex) =>
          dropdownHours().indexOf(values.hours[index].closingTime) >
          dropdownHoursIndex,
      );
    }
    return dropdownHours().filter(
      (dropdownHour, dropdownHoursIndex) =>
        dropdownHours().indexOf(values.hours[index].closingTime) >
          dropdownHoursIndex &&
        dropdownHours().indexOf(values.hours[index - 1].closingTime) <
          dropdownHoursIndex,
    );
  };

  const closingHourCell = (values, index) => {
    if (index === values.hours.length - 1) {
      return dropdownHours().slice(
        dropdownHours().indexOf(values.hours[index].openingTime) + 1,
      );
    }

    return dropdownHours().slice(
      dropdownHours().indexOf(values.hours[index].openingTime) + 1,
      dropdownHours().indexOf(values.hours[index + 1].openingTime),
    );
  };

  const addHoursObject = (values, hoursIndex) => {
    if (
      dropdownHours().indexOf(values.hours[hoursIndex].closingTime) <
      dropdownHours().length - 2
    ) {
      return values.hours.push({
        openingTime:
          dropdownHours()[
            dropdownHours().indexOf(values.hours[hoursIndex].closingTime) + 1
          ],
        closingTime:
          dropdownHours()[
            dropdownHours().indexOf(values.hours[hoursIndex].closingTime) + 2
          ],
      });
    }
    return;
  };

  return {
    weekdays,
    dropdownHours,
    objectToArray,
    openingHourCell,
    closingHourCell,
    addHoursObject,
  };
}

export default storeEditFormsFunctions;
