import axios from 'axios';
import { useStores } from '../../index';

function useEditFileDialog() {
  const { showAlert, getStoresApiTokenRedirect } = useStores();

  async function updateFile(data, id, handleRequestId) {
    let success = false;
    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/zipCodes/${id}`,
          data: data,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then(() => {
            showAlert('Uppdateringen lyckades!', 'success');
            success = true;
            handleRequestId();
          })
          .catch(() => {
            showAlert('Uppdateringen misslyckades!', 'error');
            success = false;
            handleRequestId();
          });
        return success;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return {
    updateFile,
  };
}

export default useEditFileDialog;
