import React from 'react';
import {
  Layout,
  Spinner,
  ZipCodesList,
  NewZipCodesFileDialog,
} from '../../components/index';
import { useZipCodesOverview } from './index';
import { Button } from '@mui/material';

function ZipCodesOverview() {
  const {
    zipCodeFiles,
    newZipCodesFileDialogOpen,
    toggleNewZipCodesFileDialog,
    filteredFilesBySearch,
    handleFilter,
    handleRequestId,
  } = useZipCodesOverview();

  const breadcrumbs = [
    { text: 'Butiksregister', link: '/' },
    { text: 'Postnummer', link: '/postnummer' },
  ];

  const pageTitle = 'Postnummer';

  const headerButton = (
    <Button
      style={{ color: 'white', backgroundColor: 'rgb(102, 187, 106)' }}
      color="secondary"
      variant="contained"
      onClick={toggleNewZipCodesFileDialog}
    >
      Ladda upp fil
    </Button>
  );

  return (
    <Layout
      headerButton={headerButton}
      pageTitle={pageTitle}
      title={pageTitle}
      crumbs={breadcrumbs}
    >
      {zipCodeFiles.loading ? (
        <Spinner />
      ) : zipCodeFiles.completed ? (
        <ZipCodesList
          zipCodeFiles={zipCodeFiles.data}
          filteredFilesBySearch={filteredFilesBySearch}
          handleFilter={handleFilter}
          handleRequestId={handleRequestId}
        />
      ) : null}
      <NewZipCodesFileDialog
        zipCodeFiles={zipCodeFiles.data}
        newZipCodesFileDialogOpen={newZipCodesFileDialogOpen}
        toggleNewZipCodesFileDialog={toggleNewZipCodesFileDialog}
        handleRequestId={handleRequestId}
      />
    </Layout>
  );
}

export default ZipCodesOverview;
