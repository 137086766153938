import React from 'react';
import { Dialog, LinearProgress, Box } from '@mui/material';
import { useOpeningHoursDialog, TabsPanel } from './index';

function OpeningHoursDialog(props) {
  const {
    openingHoursDialogOpen,
    toggleOpeningHoursDialog,
    handleRequestId,
    setSelected,
    stores,
  } = props;

  const { updatingValues, setUpdatingValues } = useOpeningHoursDialog();

  return (
    <Dialog
      maxWidth="lg"
      open={openingHoursDialogOpen}
      onClose={toggleOpeningHoursDialog}
    >
      <Box maxWidth="834px">
        {updatingValues ? <LinearProgress /> : null}
        <Box>
          <TabsPanel
            openingHoursDialogOpen={openingHoursDialogOpen}
            toggleOpeningHoursDialog={toggleOpeningHoursDialog}
            handleRequestId={handleRequestId}
            setUpdatingValues={setUpdatingValues}
            setSelected={setSelected}
            stores={stores}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
export default OpeningHoursDialog;
