export const StoreEditRoot = {
  width: '100%',
};

export const StoreEditFormHeaders = {
  margin: '30px 0px 15px 0px',
};

export const StoreEditFormHeadersLeftPadding = {
  margin: '30px 0px 15px 0px',
  paddingLeft: '16px',
};

export const StoreEditRow = {
  maxWidth: '500px',
  width: '100%',
};

export const StoreEditFormWrapper = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const StoreEditSplitRow = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '500px',
};

export const StoreEditCapitalize = {
  textTransform: 'capitalize',
};

export const StoreEditWeekdaysLabel = {
  width: '60px',
};

export const StoreEditCreateIcon = {
  marginRight: '10px',
  cursor: 'pointer',
};

export const StoreEditCreateIconLink = {
  marginRight: '10px',
  textDecoration: 'none',
  color: 'black',
};

export const StoreEditDeleteIconLink = {
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'black',
};

export const StoreEditListItemSpaceBetween = {
  justifyContent: 'space-between',
};

export const StoreEditListItemFlexEnd = {
  justifyContent: 'flex-end',
};

export const StoreEditAddLink = {
  paddingLeft: '20px',
  textDecoration: 'none',
};

export const StoreEditRemoveLink = {
  marginLeft: '20px',
  height: '20px',
};

// Store Information styles
export const StoreEditFormHeadersPresentation = {
  margin: '0px 0px 15px 0px',
};

export const StoreEditFormWrapperStoreInformation = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const StoreEditStoreInformationRow = {
  maxWidth: '500px',
  width: '100%',
  marginRight: '25px',
  marginTop: '30px',
};

export const StoreEditStoreInformationSplitRow = {
  maxWidth: '500px',
  width: '100%',
  marginTop: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

// Store OpeningHours styles
export const StoreEditOpeningHoursList = {
  maxWidth: '640px',
};

export const StoreEditOpeningHoursListItem = {
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};

export const StoreEditOpeningHoursControls = {
  display: 'flex',
  width: '200px',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const StoreEditOpeningHoursDropdownsWrapper = {
  display: 'flex',
};

export const StoreEditOpeningHoursDropdownWrapper = {
  width: '360px',
  marginBottom: '15px',
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
};

export const StoreEditOpeningHoursDropdown = {
  minWidth: '87px',
};

// Store LogisticsAndSalesForm Styles
export const StoreEditLogisticsAndSalesFormWrapper = {
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '80px',
};

export const StoreEditLogisticsInformationWrapper = {
  marginBottom: '100px',
};

export const StoreEditLogisticsAndSalesControls = {
  marginTop: '30px',
};

export const StoreEditAdjacentStoresWrapper = {
  marginTop: '30px',
  width: '100%',
};

export const StoreEditAddAdjacentStores = {
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: '20px',
};

export const StoreEditAddAdjacentStoresh6 = {
  flexGrow: '0.4',
  marginBottom: '30px',
};

export const StoreEditAdjacentStores = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
};

export const StoreEditAddAdjacentStoresDialogContent = {
  minHeight: '100px',
  width: '500px',
};

export const StoreEditAddAdjacentStoresDialogSearchList = {
  height: '200px',
  overflowY: 'auto',
};

export const StoreEditSearch = {
  position: 'relative',
  borderRadius: '5px',
  border: '2px solid lightgrey',
  marginRight: '16px',
  marginLeft: '0',
  width: '100%',
};

export const StoreEditSearchIcon = {
  padding: '0 16px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const StoreEditInputRoot = {
  color: 'inherit',
};

export const StoreEditInputInput = {
  padding: '8px 8px 8px 0',
  paddingLeft: 'calc(1em + 32px)',
  transition: 'width 300ms ease-in-out',
  width: '100%',
};

export const StoreEditAdditionalCostAndleadtimeRow = {
  flexGrow: '1',
};

export const StoreEditAdditionalCostAndLeadtimeFieldsWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const StoreEditAdditionalCostAndLeadtimeField = {
  width: '32%',
};

export const StoreEditZoneLengthControls = {
  paddingTop: '15px',
  paddingRight: '10px',
  textAlign: 'right',
};

// Store DepartmentsForm Styles
export const StoreEditStoreDepartmentsOverviewForm = {
  paddingTop: '50px',
};

export const StoreEditAddDepartmentLink = {
  color: 'inherit',
  textDecoration: 'none',
};

export const StoreEditDepartmentFormWrapper = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

export const StoreEditDepartmentRow = {
  maxWidth: '500px',
  width: '100%',
  marginRight: '25px',
};

export const StoreEditAssemblyCostContainer = {
  display: 'flex',
  alignItems: 'center',
  height: '76px',
};

export const StoreEditAssemblyCostSwitch = {
  marginRight: '4px',
};

export const StoreEditAssemblyCostActive = {
  marginRight: '12px',
};

export const StoreEditAssemblyCostTextFieldContainer = {
  display: 'flex',
  marginBottom: '12px',
};

export const StoreEditAssemblyCostType = {
  width: '140px',
  marginRight: '7px',
};

export const StoreEditAssemblyCostPrice = {
  width: '162px',
};

export const StoreEditAssemblyCostPercentage = {
  width: '162px',
  marginRight: '7px',
};

export const StoreEditAssemblyCostLowestPrice = {
  width: '220px',
  marginRight: '7px',
};

export const StoreEditActivationDate = {
  width: '500px',
  height: '56px',
};

export const StoreEditOpeningAndCloseDate = {
  width: '240px',
};

export const StoreEditExceptionalDatePicker = {
  marginTop: '20px',
};

export const StoreEditExceptionalToggle = {
  marginRight: '10px',
};

export const DisableLinkButton = {
  opacity: 0.5,
};

export const RedirectionTab = {
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
};

export const RedirectionText = {
  fontSize: 'md',
  whiteSpace: 'pre-line',
  textAlign: 'center',
};

export const RedirectionButton = {
  marginTop: '30px',
  marginBottom: '30px',
};
