import axios from 'axios';
import { useStores } from '../../index';

function useStoreEditForms() {
  const { showAlert, getStoresApiTokenRedirect } = useStores();

  async function updateStore(data, handleRequestId, id) {
    const parseLatLong = (data) => {
      if (data.location === null) {
        return { ...data };
      } else {
        return {
          ...data,
          location: {
            longitude: parseFloat(
              data.location.longitude.toString().replace(',', '.'),
            ),
            latitude: parseFloat(
              data.location.latitude.toString().replace(',', '.'),
            ),
          },
        };
      }
    };

    const fixedData = parseLatLong(data);

    await getStoresApiTokenRedirect()
      .then((res) => {
        axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_STORES_RELATIVE_API_PATH}/stores/${id}`,
          data: fixedData,
          headers: {
            Authorization: `Bearer ${res.accessToken}`,
          },
        })
          .then((res) => {
            handleRequestId();
            showAlert('Sparat!', 'success');
          })
          .catch((error) => {
            handleRequestId();
            showAlert('Något gick fel', 'error');
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return {
    updateStore,
  };
}

export default useStoreEditForms;
