import { useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomPrompt = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when, message]);

  return null;
};

CustomPrompt.propTypes = {
  when: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default CustomPrompt;
