import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@mui/material';

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

function ProgressionModal(props) {
  const {
    loadingModal,
  } = props;
  return (loadingModal && (

    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={loadingModal}
    >
      <DialogTitle id="alert-dialog-title">{'Aktiverar ny postnummerfil.'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={loadingStyle}>
          <CircularProgress />
          <br />
          Detta kan ta en stund.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  ));
}
export default ProgressionModal;