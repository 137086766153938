import React, { Fragment, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  Checkbox,
  Chip,
  Link,
  MenuItem,
} from '@mui/material';
import { AddAdjacentStoresDialog } from './index';
import { useStoreEditForms } from './hooks/index';
import { storeEditFormsFunctions } from './functions/index';
import * as styles from './store-edit-forms-styles';

function StoreLogisticsAndSalesForm(props) {
  const {
    handleRequestId,
    store,
    stores,
    usePageTitle,
    useBreadcrumbs,
    useFormId,
    PromptIfDirty,
  } = props;
  const { updateStore } = useStoreEditForms();
  const { objectToArray } = storeEditFormsFunctions();
  const formId = 'store-logistics-and-sales-form';

  usePageTitle('Logistik & Försäljning');
  useBreadcrumbs([
    { text: 'Butiksregister', link: '/' },
    { text: 'Butiker', link: '/butiker' },
    { text: store.storeName },
  ]);
  useFormId(formId);

  const findStore = (adjacentStore) =>
    stores.find((x) => x.storeId === adjacentStore);

  const [zoneCounter, setZoneCounter] = useState(null);

  useEffect(() => {
    const deliveryArrays = {
      validHomeDeliveryCostValues: Object.values(store.homeDeliveryCost).filter(
        (val) => val !== null,
      ),
      validAdditionalDeliveryCostValues: Object.values(
        store.additionalDeliveryCost,
      ).filter((val) => val !== null),
      validAdditionalDeliveryTime: Object.values(
        store.additionalDeliveryTime,
      ).filter((val) => val !== null),
    };

    const highestLength = Object.values(deliveryArrays)
      .map((a) => a.length)
      .reduce((a, b) => Math.max(a, b));

    highestLength > 0 ? setZoneCounter(highestLength - 1) : setZoneCounter(0);
  }, []);

  return (
    <div>
      {store && (
        <Formik
          initialValues={{
            ...store,
            warehouse: store.warehouse ?? '',
            deliveringWarehouse: store.deliveringWarehouse ?? '',
            secondaryDeliveringWarehouse:
              store.secondaryDeliveringWarehouse ?? '',
            loanTrailerServiceType: store.loanTrailerServiceType ?? 'None',
            showBalanceOnWeb: store.showBalanceOnWeb ?? false,
            webOrderAvailable: store.webOrderAvailable ?? false,
            pickupPossible: store.pickupPossible ?? false,
            driveThru: store.driveThru ?? false,
            driveThruInstructions: store.driveThruInstructions ?? '',
            driveThruPhone: store.driveThruPhone ?? '',
            driveThruPlace: store.driveThruPlace ?? '',
            adjacentStores: store.adjacentStores ?? [],

            homeDeliveryCost: store.homeDeliveryCost ?? {
              zone1: null,
              zone2: null,
              zone3: null,
              zone4: null,
              zone5: null,
              zone6: null,
            },
            additionalDeliveryCost: store.additionalDeliveryCost ?? {
              zone1: null,
              zone2: null,
              zone3: null,
              zone4: null,
              zone5: null,
              zone6: null,
            },
            additionalDeliveryTime: store.additionalDeliveryTime ?? {
              zone1: null,
              zone2: null,
              zone3: null,
              zone4: null,
              zone5: null,
              zone6: null,
            },
            assemblyCost: {
              PG1: store.assemblyCost.PG1 ?? {
                type: 'Price',
                percentage: null,
                price: null,
                isActive: false,
              },
              PG2: store.assemblyCost.PG2 ?? {
                type: 'Price',
                percentage: null,
                price: null,
                isActive: false,
              },
              PG3: store.assemblyCost.PG3 ?? {
                type: 'Price',
                percentage: null,
                price: null,
                isActive: false,
              },
              PG4: store.assemblyCost.PG4 ?? {
                type: 'Price',
                percentage: null,
                price: null,
                isActive: false,
              },
              PG5: store.assemblyCost.PG5 ?? {
                type: 'Price',
                percentage: null,
                price: null,
                isActive: false,
              },
              PG6: store.assemblyCost.PG6 ?? {
                type: 'Price',
                percentage: null,
                price: null,
                isActive: false,
              },
            },
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            updateStore(values, handleRequestId, store.storeId);
          }}
          validationSchema={Yup.object().shape({
            warehouse: Yup.string()
              .required('Ange en lagerkod för butikssaldo')
              .length(3, 'Lagerkod för butikssaldo ska innehålla 3 tecken'),
            deliveringWarehouse: Yup.string()
              .required('Ange en lagerkod för hemleverans')
              .length(3, 'Lagerkod för butikssaldo ska innehålla 3 tecken'),
            secondaryDeliveringWarehouse: Yup.string().length(
              3,
              'Lagerkod för butikssaldo ska innehålla 3 tecken',
            ),
            loanTrailerServiceType: Yup.string(),
            showBalanceOnWeb: Yup.bool(),
            webOrderAvailable: Yup.bool(),
            pickupPossible: Yup.bool(),
            driveThru: Yup.bool(),
            driveThruInstructions: Yup.string().max(500, 'Ange färre tecken'),
            driveThruPhone: Yup.string(),
            driveThruPlace: Yup.string(),
            homeDeliveryCost: Yup.object().shape({
              zone1: Yup.number()
                .nullable()
                .required('zon 1 måste ha ett värde'),
              zone2: Yup.number().nullable(),
              zone3: Yup.number().nullable(),
              zone4: Yup.number().nullable(),
              zone5: Yup.number().nullable(),
              zone6: Yup.number().nullable(),
            }),
            additionalDeliveryCost: Yup.object().shape({
              zone1: Yup.number()
                .nullable()
                .required('zon 1 måste ha ett värde'),
              zone2: Yup.number().nullable(),
              zone3: Yup.number().nullable(),
              zone4: Yup.number().nullable(),
              zone5: Yup.number().nullable(),
              zone6: Yup.number().nullable(),
            }),
            additionalDeliveryTime: Yup.object().shape({
              zone1: Yup.number()
                .nullable()
                .required('Zon 1 måste ha ett värde'),
              zone2: Yup.number().nullable(),
              zone3: Yup.number().nullable(),
              zone4: Yup.number().nullable(),
              zone5: Yup.number().nullable(),
              zone6: Yup.number().nullable(),
            }),
            assemblyCost: Yup.object().shape({
              PG1: Yup.object().shape({
                isActive: Yup.boolean(),
                type: Yup.string(),
                price: Yup.number()
                  .positive('Måste vara positivt')
                  .when('isActive', {
                    is: (isActive) => isActive === false,
                    then: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .notRequired('Måste ha ett pris'),
                    otherwise: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .required('Måste ha ett pris'),
                  }),
                percentage: Yup.number()
                  .integer('Måste vara heltal')
                  .min(0, 'Måste vara större än noll')
                  .max(100, 'Får vara max 100%')
                  .when(['type', 'isActive'], {
                    is: (type, isActive) =>
                      (type === 'Price' && isActive) ||
                      (type === 'Price' && !isActive) ||
                      (type === 'Percent' && !isActive),
                    then: () => Yup.number().notRequired().nullable(),
                    otherwise: () =>
                      Yup.number()
                        .integer('Måste vara heltal')
                        .min(0, 'Måste vara större än noll')
                        .max(100, 'Får vara max 100%')
                        .required('Måste ha en procent i siffror')
                        .nullable(),
                  }),
              }),

              PG2: Yup.object().shape({
                isActive: Yup.boolean(),
                type: Yup.string(),
                price: Yup.number()
                  .positive('Måste vara positivt')
                  .when('isActive', {
                    is: (isActive) => isActive === false,
                    then: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .notRequired('Måste ha ett pris'),
                    otherwise: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .required('Måste ha ett pris'),
                  }),
                percentage: Yup.number()
                  .integer('Måste vara heltal')
                  .min(0, 'Måste vara större än noll')
                  .max(100, 'Får vara max 100%')
                  .when(['type', 'isActive'], {
                    is: (type, isActive) =>
                      (type === 'Price' && isActive) ||
                      (type === 'Price' && !isActive) ||
                      (type === 'Percent' && !isActive),
                    then: () => Yup.number().notRequired().nullable(),
                    otherwise: () =>
                      Yup.number()
                        .integer('Måste vara heltal')
                        .min(0, 'Måste vara större än noll')
                        .max(100, 'Får vara max 100%')
                        .required('Måste ha en procent i siffror')
                        .nullable(),
                  }),
              }),

              PG3: Yup.object().shape({
                isActive: Yup.boolean(),
                type: Yup.string(),
                price: Yup.number()
                  .positive('Måste vara positivt')
                  .when('isActive', {
                    is: (isActive) => isActive === false,
                    then: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .notRequired('Måste ha ett pris'),
                    otherwise: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .required('Måste ha ett pris'),
                  }),
                percentage: Yup.number()
                  .integer('Måste vara heltal')
                  .min(0, 'Måste vara större än noll')
                  .max(100, 'Får vara max 100%')
                  .when(['type', 'isActive'], {
                    is: (type, isActive) =>
                      (type === 'Price' && isActive) ||
                      (type === 'Price' && !isActive) ||
                      (type === 'Percent' && !isActive),
                    then: () => Yup.number().notRequired().nullable(),
                    otherwise: () =>
                      Yup.number()
                        .integer('Måste vara heltal')
                        .min(0, 'Måste vara större än noll')
                        .max(100, 'Får vara max 100%')
                        .required('Måste ha en procent i siffror')
                        .nullable(),
                  }),
              }),

              PG4: Yup.object().shape({
                isActive: Yup.boolean(),
                type: Yup.string(),
                price: Yup.number()
                  .positive('Måste vara positivt')
                  .when('isActive', {
                    is: (isActive) => isActive === false,
                    then: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .notRequired('Måste ha ett pris'),
                    otherwise: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .required('Måste ha ett pris'),
                  }),
                percentage: Yup.number()
                  .integer('Måste vara heltal')
                  .min(0, 'Måste vara större än noll')
                  .max(100, 'Får vara max 100%')
                  .when(['type', 'isActive'], {
                    is: (type, isActive) =>
                      (type === 'Price' && isActive) ||
                      (type === 'Price' && !isActive) ||
                      (type === 'Percent' && !isActive),
                    then: () => Yup.number().notRequired().nullable(),
                    otherwise: () =>
                      Yup.number()
                        .integer('Måste vara heltal')
                        .min(0, 'Måste vara större än noll')
                        .max(100, 'Får vara max 100%')
                        .required('Måste ha en procent i siffror')
                        .nullable(),
                  }),
              }),

              PG5: Yup.object().shape({
                isActive: Yup.boolean(),
                type: Yup.string(),
                price: Yup.number()
                  .positive('Måste vara positivt')
                  .when('isActive', {
                    is: (isActive) => isActive === false,
                    then: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .notRequired('Måste ha ett pris'),
                    otherwise: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .required('Måste ha ett pris'),
                  }),
                percentage: Yup.number()
                  .integer('Måste vara heltal')
                  .min(0, 'Måste vara större än noll')
                  .max(100, 'Får vara max 100%')
                  .when(['type', 'isActive'], {
                    is: (type, isActive) =>
                      (type === 'Price' && isActive) ||
                      (type === 'Price' && !isActive) ||
                      (type === 'Percent' && !isActive),
                    then: () => Yup.number().notRequired().nullable(),
                    otherwise: () =>
                      Yup.number()
                        .integer('Måste vara heltal')
                        .min(0, 'Måste vara större än noll')
                        .max(100, 'Får vara max 100%')
                        .required('Måste ha en procent i siffror')
                        .nullable(),
                  }),
              }),

              PG6: Yup.object().shape({
                isActive: Yup.boolean(),
                type: Yup.string(),
                price: Yup.number()
                  .positive('Måste vara positivt')
                  .when('isActive', {
                    is: (isActive) => isActive === false,
                    then: () =>
                      Yup.number()
                        .positive('Måste vara positivt')
                        .nullable()
                        .notRequired('Måste ha ett pris'),
                    otherwise: () =>
                      Yup.number()
                        .positive('Måste vara positivt.')
                        .nullable()
                        .required('Måste ha ett pris'),
                  }),
                percentage: Yup.number()
                  .integer('Måste vara heltal')
                  .min(0, 'Måste vara större än noll')
                  .max(100, 'Får vara max 100%')
                  .when(['type', 'isActive'], {
                    is: (type, isActive) =>
                      (type === 'Price' && isActive) ||
                      (type === 'Price' && !isActive) ||
                      (type === 'Percent' && !isActive),
                    then: () => Yup.number().notRequired().nullable(),
                    otherwise: () =>
                      Yup.number()
                        .integer('Måste vara heltal')
                        .min(0, 'Måste vara större än noll')
                        .max(100, 'Får vara max 100%')
                        .required('Måste ha en procent i siffror')
                        .nullable(),
                  }),
              }),
            }),
          })}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} id={formId}>
              <PromptIfDirty />
              <div style={styles.StoreEditLogisticsAndSalesFormWrapper}>
                <div
                  style={
                    (styles.StoreEditLogisticsInformationWrapper,
                    styles.StoreEditSplitRow)
                  }
                >
                  <div>
                    <Typography
                      variant="h5"
                      sx={styles.StoreEditFormHeaders}
                      gutterBottom
                    >
                      Lager & logistik
                    </Typography>
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="warehouse"
                      value={values['warehouse'].toUpperCase()}
                      label="Lagerkod för butikssaldo"
                      InputLabelProps={{
                        shrink: touched['warehouse'],
                      }}
                      helperText={
                        errors['warehouse'] &&
                        touched['warehouse'] &&
                        errors['warehouse']
                      }
                      error={
                        !!errors['warehouse'] &&
                        !!touched['warehouse'] &&
                        !!errors['warehouse']
                      }
                    />
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="deliveringWarehouse"
                      value={values['deliveringWarehouse'].toUpperCase()}
                      label="Lagerkod för hemleverans"
                      InputLabelProps={{
                        shrink: touched['deliveringWarehouse'],
                      }}
                      helperText={
                        errors['deliveringWarehouse'] &&
                        touched['deliveringWarehouse'] &&
                        errors['deliveringWarehouse']
                      }
                      error={
                        !!errors['deliveringWarehouse'] &&
                        !!touched['deliveringWarehouse'] &&
                        !!errors['deliveringWarehouse']
                      }
                    />
                    <TextField
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      margin="normal"
                      name="secondaryDeliveringWarehouse"
                      value={values[
                        'secondaryDeliveringWarehouse'
                      ].toUpperCase()}
                      label="Sekundär lagerkod för hemleverans"
                      InputLabelProps={{
                        shrink: touched['secondaryDeliveringWarehouse'],
                      }}
                      helperText={
                        errors['secondaryDeliveringWarehouse'] &&
                        touched['secondaryDeliveringWarehouse'] &&
                        errors['secondaryDeliveringWarehouse']
                      }
                      error={
                        !!errors['secondaryDeliveringWarehouse'] &&
                        !!touched['secondaryDeliveringWarehouse'] &&
                        !!errors['secondaryDeliveringWarehouse']
                      }
                    />
                  </div>
                  <div>
                    <div style={styles.StoreEditLogisticsAndSalesControls}>
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        name="loanTrailerServiceType"
                        value={values.loanTrailerServiceType}
                        label="Typ av lånesläptjänst"
                        onChange={handleChange}
                        sx={{ mb: 4 }}
                      >
                        <MenuItem value="None">Ingen</MenuItem>
                        <MenuItem value="Own">Egen</MenuItem>
                        <MenuItem value="External">Extern</MenuItem>
                        <MenuItem value="Both">Egen & extern</MenuItem>
                      </TextField>
                      <Typography
                        variant="h6"
                        display="inline"
                        sx={{ fontWeight: '400' }}
                      >
                        Visa butik i lista för lagerstatus
                      </Typography>
                      <Switch
                        checked={values.showBalanceOnWeb}
                        onChange={handleChange}
                        name="showBalanceOnWeb"
                        color="primary"
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        display="block"
                      >
                        Välj om butiken ska visas i butiklistan för
                        &apos;Lagerstatus i butik&apos;
                      </Typography>
                    </div>
                    <div style={styles.StoreEditLogisticsAndSalesControls}>
                      <Typography
                        variant="h6"
                        display="inline"
                        sx={{ fontWeight: '400' }}
                      >
                        Webborder kan läggas i denna butik
                      </Typography>
                      <Switch
                        checked={values.webOrderAvailable}
                        onChange={() => {
                          setFieldValue(
                            'webOrderAvailable',
                            !values.webOrderAvailable,
                          );
                          if (values.webOrderAvailable) {
                            setFieldValue('pickupPossible', false);
                            setFieldValue('driveThru', false);
                          }
                        }}
                        name="webOrderAvailable"
                        color="primary"
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        display="block"
                      >
                        Välj om kunder kan lägga webborder i denna butik. Om
                        detta val är inaktiverat kan butiken inte sälja varor på
                        mio.se.
                      </Typography>

                      <FormControlLabel
                        disabled={!values.webOrderAvailable}
                        control={
                          <Checkbox
                            checked={values.pickupPossible}
                            onChange={() => {
                              setFieldValue(
                                'pickupPossible',
                                !values.pickupPossible,
                              );
                              if (values.pickupPossible) {
                                setFieldValue('driveThru', false);
                              }
                            }}
                            name="pickupPossible"
                            color="primary"
                          />
                        }
                        label="Webborder kan hämtas i denna butik (click & collect)"
                        sx={{ display: 'block' }}
                      />
                      <FormControlLabel
                        disabled={
                          !values.webOrderAvailable || !values.pickupPossible
                        }
                        control={
                          <Checkbox
                            checked={values.driveThru}
                            onChange={handleChange}
                            name="driveThru"
                            color="primary"
                          />
                        }
                        label="Denna butik erbjuder Mio Drive-Thru"
                      />
                      {values.driveThru ? (
                        <div>
                          <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            margin="normal"
                            name="driveThruPhone"
                            value={values['driveThruPhone']}
                            label="Drive-Thru telefonnummer"
                            InputLabelProps={{
                              shrink: touched['driveThruPhone'],
                            }}
                            helperText={
                              errors['driveThruPhone'] &&
                              touched['driveThruPhone'] &&
                              errors['driveThruPhone']
                            }
                            error={
                              !!errors['driveThruPhone'] &&
                              !!touched['driveThruPhone'] &&
                              !!errors['driveThruPhone']
                            }
                          />
                          <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            margin="normal"
                            name="driveThruPlace"
                            value={values['driveThruPlace']}
                            label="Drive-Thru plats"
                            InputLabelProps={{
                              shrink: touched['driveThruPlace'],
                            }}
                            helperText={
                              errors['driveThruPlace'] &&
                              touched['driveThruPlace'] &&
                              errors['driveThruPlace']
                            }
                            error={
                              !!errors['driveThruPlace'] &&
                              !!touched['driveThruPlace'] &&
                              !!errors['driveThruPlace']
                            }
                          />
                          <TextField
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            multiline
                            rows={5}
                            variant="outlined"
                            margin="normal"
                            name="driveThruInstructions"
                            value={values['driveThruInstructions']}
                            label="Drive-Thru instruktioner"
                            InputLabelProps={{
                              shrink: touched['driveThruInstructions'],
                            }}
                            helperText={
                              errors['driveThruInstructions'] &&
                              touched['driveThruInstructions'] &&
                              errors['driveThruInstructions']
                            }
                            error={
                              !!errors['driveThruInstructions'] &&
                              !!touched['driveThruInstructions'] &&
                              !!errors['driveThruInstructions']
                            }
                          />
                        </div>
                      ) : null}
                    </div>

                    <div style={styles.StoreEditAdjacentStoresWrapper}>
                      <div
                        style={
                          (styles.StoreEditAddAdjacentStores,
                          styles.StoreEditAddAdjacentStoresh6)
                        }
                      >
                        <Typography
                          variant="h6"
                          display="inline"
                          sx={{ mr: '100px' }}
                        >
                          Närliggande butiker
                        </Typography>

                        <AddAdjacentStoresDialog
                          stores={stores}
                          currentStore={store.storeId}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div style={styles.StoreEditAdjacentStores}>
                        {values.adjacentStores.length > 0
                          ? values.adjacentStores.map(
                            (adjacentStore, adjacentStoreIndex) => (
                              <Chip
                                key={adjacentStoreIndex}
                                label={
                                  findStore(adjacentStore)
                                    ? findStore(adjacentStore).storeName
                                    : 'Denna butik är inte tillgänglig'
                                }
                                onDelete={() =>
                                  setFieldValue(
                                    values.adjacentStores.splice(
                                      adjacentStoreIndex,
                                      1,
                                    ),
                                  )
                                }
                                color={
                                  findStore(adjacentStore)
                                    ? 'primary'
                                    : 'default'
                                }
                              />
                            ),
                          )
                          : 'Det finns inga närliggande butiker registrerat för denna butik'}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={styles.StoreEditAdditionalCostAndleadtimeRow}>
                  <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                    Leveranskostnad & utökad leveranstid
                  </Typography>
                  <div
                    style={
                      styles.StoreEditAdditionalCostAndLeadtimeFieldsWrapper
                    }
                  >
                    <div style={styles.StoreEditAdditionalCostAndLeadtimeField}>
                      {objectToArray(
                        { ...values },
                        'homeDeliveryCost',
                        zoneCounter,
                      ).map((zone, zoneIndex) => (
                        <Fragment key={zoneIndex}>
                          <TextField
                            key={
                              Object.keys(values.homeDeliveryCost)[zoneIndex]
                            }
                            fullWidth
                            onChange={(e) => {
                              e.target.value === ''
                                ? setFieldValue(
                                  `homeDeliveryCost[${Object.keys(zone)[0]}]`,
                                  null,
                                )
                                : handleChange(e);
                            }}
                            onBlur={handleBlur}
                            type="number"
                            variant="outlined"
                            margin="normal"
                            name={`homeDeliveryCost[${Object.keys(zone)[0]}]`}
                            value={
                              values.homeDeliveryCost[Object.keys(zone)[0]] ===
                              null
                                ? ''
                                : values.homeDeliveryCost[Object.keys(zone)[0]]
                            }
                            label={`Kostnad för hemleverans - zon ${
                              zoneIndex + 1
                            }`}
                            InputLabelProps={{
                              shrink:
                                touched['homeDeliveryCost']?.[
                                  Object.keys(zone)[0]
                                ],
                            }}
                            helperText={
                              touched['homeDeliveryCost']?.[
                                Object.keys(zone)[0]
                              ] &&
                              errors['homeDeliveryCost']?.[Object.keys(zone)[0]]
                            }
                            error={
                              !!touched['homeDeliveryCost']?.[
                                Object.keys(zone)[0]
                              ] &&
                              !!errors['homeDeliveryCost']?.[
                                Object.keys(zone)[0]
                              ]
                            }
                          />
                        </Fragment>
                      ))}
                    </div>
                    <div style={styles.StoreEditAdditionalCostAndLeadtimeField}>
                      {objectToArray(
                        { ...values },
                        'additionalDeliveryCost',
                        zoneCounter,
                      ).map((zone, zoneIndex) => (
                        <TextField
                          key={
                            Object.keys(values.additionalDeliveryCost)[
                              zoneIndex
                            ]
                          }
                          fullWidth
                          onChange={(e) => {
                            e.target.value === ''
                              ? setFieldValue(
                                `additionalDeliveryCost[${
                                  Object.keys(zone)[0]
                                }]`,
                                null,
                              )
                              : handleChange(e);
                          }}
                          onBlur={handleBlur}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          name={`additionalDeliveryCost[${
                            Object.keys(zone)[0]
                          }]`}
                          value={
                            values.additionalDeliveryCost[
                              Object.keys(zone)[0]
                            ] === null
                              ? ''
                              : values.additionalDeliveryCost[
                                Object.keys(zone)[0]
                              ]
                          }
                          label={`Tilläggskostnad för inbärning - zon ${
                            zoneIndex + 1
                          }`}
                          InputLabelProps={{
                            shrink:
                              touched['additionalDeliveryCost']?.[
                                Object.keys(zone)[0]
                              ],
                          }}
                          helperText={
                            touched['additionalDeliveryCost']?.[
                              Object.keys(zone)[0]
                            ] &&
                            errors['additionalDeliveryCost']?.[
                              Object.keys(zone)[0]
                            ]
                          }
                          error={
                            !!touched['additionalDeliveryCost']?.[
                              Object.keys(zone)[0]
                            ] &&
                            !!errors['additionalDeliveryCost']?.[
                              Object.keys(zone)[0]
                            ]
                          }
                        />
                      ))}
                    </div>
                    <div style={styles.StoreEditAdditionalCostAndLeadtimeField}>
                      {objectToArray(
                        { ...values },
                        'additionalDeliveryTime',
                        zoneCounter,
                      ).map((zone, zoneIndex) => (
                        <TextField
                          key={
                            Object.keys(values.additionalDeliveryTime)[
                              zoneIndex
                            ]
                          }
                          fullWidth
                          onChange={(e) => {
                            e.target.value === ''
                              ? setFieldValue(
                                `additionalDeliveryTime[${
                                  Object.keys(zone)[0]
                                }]`,
                                null,
                              )
                              : handleChange(e);
                          }}
                          onBlur={handleBlur}
                          type="number"
                          variant="outlined"
                          margin="normal"
                          name={`additionalDeliveryTime[${
                            Object.keys(zone)[0]
                          }]`}
                          value={
                            values.additionalDeliveryTime[
                              Object.keys(zone)[0]
                            ] === null
                              ? ''
                              : values.additionalDeliveryTime[
                                Object.keys(zone)[0]
                              ]
                          }
                          label={`Utökad leveranstid - zon ${zoneIndex + 1}`}
                          InputLabelProps={{
                            shrink:
                              touched['additionalDeliveryTime']?.[
                                Object.keys(zone)[0]
                              ],
                          }}
                          helperText={
                            touched['additionalDeliveryTime']?.[
                              Object.keys(zone)[0]
                            ] &&
                            errors['additionalDeliveryTime']?.[
                              Object.keys(zone)[0]
                            ]
                          }
                          error={
                            !!touched['additionalDeliveryTime']?.[
                              Object.keys(zone)[0]
                            ] &&
                            !!errors['additionalDeliveryTime']?.[
                              Object.keys(zone)[0]
                            ]
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div style={styles.StoreEditZoneLengthControls}>
                    {zoneCounter ? (
                      <Link
                        component="button"
                        type="button"
                        variant="body2"
                        color="error"
                        onClick={() => {
                          setFieldValue(
                            `homeDeliveryCost.${
                              Object.keys(values.homeDeliveryCost)[zoneCounter]
                            }`,
                            null,
                          );
                          setFieldValue(
                            `additionalDeliveryCost.${
                              Object.keys(values.additionalDeliveryCost)[
                                zoneCounter
                              ]
                            }`,
                            null,
                          );
                          setFieldValue(
                            `additionalDeliveryTime.${
                              Object.keys(values.additionalDeliveryTime)[
                                zoneCounter
                              ]
                            }`,
                            null,
                          );
                          setZoneCounter(zoneCounter - 1);
                        }}
                      >
                        TA BORT
                      </Link>
                    ) : null}
                    <Link
                      component="button"
                      type="button"
                      variant="body2"
                      sx={styles.StoreEditAddLink}
                      onClick={() =>
                        zoneCounter < 5 ? setZoneCounter(zoneCounter + 1) : null
                      }
                    >
                      LÄGG TILL
                    </Link>
                  </div>
                  <Typography variant="h5" sx={styles.StoreEditFormHeaders}>
                    Prisgrupper montering
                  </Typography>
                  <div>
                    {['PG1', 'PG2', 'PG3', 'PG4', 'PG5', 'PG6'].map(
                      (priceGroup) => {
                        const isActive =
                          values.assemblyCost[priceGroup].isActive;

                        const handleTypeOfPrice = (event) => {
                          const value = event.target.value;
                          setFieldValue(
                            `assemblyCost.${priceGroup}.type`,
                            value,
                          );

                          setFieldValue(
                            `assemblyCost.${priceGroup}.percentage`,
                            null,
                          );
                          setFieldValue(
                            `assemblyCost.${priceGroup}.price`,
                            null,
                          );
                        };

                        return (
                          <div
                            key={`assemblyCost-${priceGroup}-switch`}
                            style={styles.StoreEditAssemblyCostContainer}
                          >
                            <div style={styles.StoreEditAssemblyCostSwitch}>
                              <Typography variant="body1" display="inline">
                                {priceGroup}
                              </Typography>
                              <Switch
                                checked={
                                  values.assemblyCost[priceGroup].isActive
                                }
                                onChange={() => {
                                  setFieldValue(
                                    `assemblyCost.${priceGroup}.isActive`,
                                    !isActive,
                                  );
                                }}
                                value={values.assemblyCost[priceGroup].isActive}
                                color="primary"
                              />
                              <Typography
                                sx={styles.StoreEditAssemblyCostActive}
                                display="inline"
                              >
                                {isActive ? 'Aktiv' : 'Inaktiv'}
                              </Typography>
                            </div>
                            {values.assemblyCost[priceGroup].isActive ===
                            true ? (
                                <div
                                  style={
                                    styles.StoreEditAssemblyCostTextFieldContainer
                                  }
                                >
                                  <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    size="small"
                                    name={`priceGroup-${priceGroup}-type`}
                                    value={values.assemblyCost[priceGroup].type}
                                    label="Typ av prissättning"
                                    onChange={handleTypeOfPrice}
                                    sx={styles.StoreEditAssemblyCostType}
                                  >
                                    <MenuItem value="Price">Fast</MenuItem>
                                    <MenuItem value="Percent">Procent</MenuItem>
                                  </TextField>

                                  {values.assemblyCost[priceGroup].type ===
                                'Price' ? (
                                      <Fragment>
                                        <TextField
                                          fullWidth
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                              `assemblyCost.${priceGroup}.price`,
                                              value,
                                            );
                                          }}
                                          sx={styles.StoreEditAssemblyCostPrice}
                                          onBlur={handleBlur}
                                          type="number"
                                          variant="outlined"
                                          margin="normal"
                                          size="small"
                                          name={`assemblyCost.${priceGroup}.price`}
                                          value={
                                            values.assemblyCost[priceGroup]
                                              .price === null
                                              ? ''
                                              : values.assemblyCost[priceGroup]
                                                .price
                                          }
                                          helperText={
                                            touched.assemblyCost?.[priceGroup]
                                              ?.price &&
                                        errors.assemblyCost?.[priceGroup]?.price
                                          }
                                          error={
                                            !!touched.assemblyCost?.[priceGroup]
                                              ?.price &&
                                        !!errors.assemblyCost?.[priceGroup]
                                          ?.price
                                          }
                                          label={`Monteringskostnad`}
                                          InputLabelProps={{
                                            shrink:
                                          touched.assemblyCost?.[priceGroup]
                                            ?.price,
                                          }}
                                        />
                                      </Fragment>
                                    ) : (
                                      <div>
                                        <TextField
                                          fullWidth
                                          sx={
                                            styles.StoreEditAssemblyCostPercentage
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                              `assemblyCost.${priceGroup}.percentage`,
                                              value,
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          type="number"
                                          variant="outlined"
                                          margin="normal"
                                          size="small"
                                          name={`assemblyCost.${priceGroup}.percentage`}
                                          value={
                                            values.assemblyCost[priceGroup]
                                              .percentage === null
                                              ? ''
                                              : values.assemblyCost[priceGroup]
                                                .percentage
                                          }
                                          helperText={
                                            touched.assemblyCost?.[priceGroup]
                                              ?.percentage &&
                                        errors.assemblyCost?.[priceGroup]
                                          ?.percentage
                                          }
                                          error={
                                            !!touched.assemblyCost?.[priceGroup]
                                              ?.percentage &&
                                        !!errors.assemblyCost?.[priceGroup]
                                          ?.percentage
                                          }
                                          label={`% av produktpris`}
                                          InputLabelProps={{
                                            shrink:
                                          touched.assemblyCost?.[priceGroup]
                                            ?.percentage,
                                          }}
                                        />
                                        <TextField
                                          fullWidth
                                          sx={
                                            styles.StoreEditAssemblyCostLowestPrice
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                              `assemblyCost.${priceGroup}.price`,
                                              value,
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          type="number"
                                          variant="outlined"
                                          margin="normal"
                                          size="small"
                                          name={`assemblyCost.${priceGroup}.price`}
                                          value={
                                            values.assemblyCost[priceGroup]
                                              .price === null
                                              ? ''
                                              : values.assemblyCost[priceGroup]
                                                .price
                                          }
                                          helperText={
                                            touched.assemblyCost?.[priceGroup]
                                              ?.price &&
                                        errors.assemblyCost?.[priceGroup]?.price
                                          }
                                          error={
                                            !!touched.assemblyCost?.[priceGroup]
                                              ?.price &&
                                        !!errors.assemblyCost?.[priceGroup]
                                          ?.price
                                          }
                                          label={`Lägsta monteringskostnad`}
                                          InputLabelProps={{
                                            shrink:
                                          touched.assemblyCost?.[priceGroup]
                                            ?.price,
                                          }}
                                        />
                                      </div>
                                    )}
                                </div>
                              ) : (
                                ((values.assemblyCost[priceGroup].price = null),
                                (values.assemblyCost[priceGroup].percentage =
                                null))
                              )}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default StoreLogisticsAndSalesForm;
