import React from 'react';
import { List, ListItem  } from '@mui/material';
import * as styles from './openinghours-file-upload-overview-styles';

const OpeningHoursFileUploadErrors = ({ errors }) => {
  return (
    <div className={styles.openingHoursRoot}>
      <h3 className={styles.openingHoursMarginBottom}>Fel påträffades</h3>
      <List>
        {errors.map((error, index) => (
          <ListItem key={index} dense className={styles.openingHoursListItem}>
            {error}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default OpeningHoursFileUploadErrors;
