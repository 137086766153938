import { Box } from '@mui/material';
import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import * as styles from './opening-hours-dialog-styles';

function ErrorAlerts(props) {
  const { errors, values, setMappedErrors, mappedErrors } = props;

  useEffect(() => {
    var keys = Object.keys(errors);
    var mappedErrorsObject = {
      toolbar: [],
      stores: {},
    };

    keys.forEach((k) => {
      if (k === 'stores') {
        errors[k].forEach((store, index) => {
          if (store !== undefined) {
            var keys = Object.keys(store);

            keys.map((key) => {
              mappedErrorsObject.stores[values.stores[index].storeName] !==
              undefined
                ? mappedErrorsObject.stores[
                  values.stores[index].storeName
                ].push(store[key])
                : (mappedErrorsObject.stores[values.stores[index].storeName] = [
                  store[key],
                ]);
            });
          }
        });
      } else {
        mappedErrorsObject.toolbar.push(errors[k]);
      }
    });

    setMappedErrors(mappedErrorsObject);
  }, [errors]);

  return (
    <Box pl={2} pr={4}>
      {mappedErrors.toolbar.map((err, index) => (
        <Alert
          variant="outlined"
          severity="error"
          sx={styles.errorAlert}
          key={err + index}
        >
          {err}
        </Alert>
      ))}
      {Object.keys(mappedErrors.stores).length > 0 ? (
        <Alert variant="outlined" severity="error" sx={styles.errorAlert}>
          En eller flera butiker har valideringsfel.
        </Alert>
      ) : null}
    </Box>
  );
}

export default ErrorAlerts;
