import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  TextField,
  List,
  ListItem,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as styles from './new-zip-codes-file-dialog-styles';

function NewZipCodesFileForm(props) {
  const {
    toggleNewZipCodesFileDialog,
    file,
    fileText,
    clearAcceptedFile,
    postNewFile,
    handleRequestId,
    zipCodeFiles,
  } = props;

  const formattedDateAndTime = (dateValue) => {
    let d = new Date(dateValue.toString().split('GMT')[0] + ' UTC')
      .toISOString()
      .slice(0, -8);

    return d;
  };

  const existingActivationDate =
    zipCodeFiles &&
    zipCodeFiles.map((file) =>
      new Date(new Date(file.activationDate).setMinutes(0)).toISOString(),
    );

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={Yup.object().shape({
          activationDate: Yup.string()
            .required('Ange ett aktiveringsdatum')
            .notOneOf(
              existingActivationDate,
              'Det finns redan en fil med detta aktiveringsdatum, redigera befintlig eller välj annat datum.',
            ),
          description: Yup.string().max(500, 'Ange färre tecken'),
        })}
        initialValues={{
          /*           activationDate: formattedDateAndTime(
            new Date(new Date().setMinutes(0))
          ), */
          activationDate: new Date(
            new Date().setHours(new Date().getHours(), 0, 0, 0),
          ).toISOString(),
          description: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          postNewFile(values, toggleNewZipCodesFileDialog, handleRequestId);
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
        }) => (
          <Form onSubmit={handleSubmit}>
            <List sx={styles.newZipCodesFileFileInfoList}>
              <ListItem>Filnamn: {file.name}</ListItem>
              <ListItem>
                Unika rader: {fileText.length.toLocaleString()} st
              </ListItem>
              <ListItem>
                Storlek: {Math.ceil(file.size / 1024).toLocaleString()} kB
              </ListItem>
              <ListItem>Filtyp: {file.type}</ListItem>
            </List>
            <div style={styles.newZipCodesFileInputFieldsWrapper}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoIcon fontSize="small" style={{ paddingRight: 3 }} />
                <Typography variant="caption">
                  Aktiveringsdatum kan endast anges i hel timme, alltså kommer
                  minuter inte registreras.
                </Typography>
              </div>
              <TextField
                fullWidth
                onChange={(e) => {
                  setFieldValue(
                    'activationDate',
                    new Date(
                      new Date(e.target.value).setMinutes(0),
                    ).toISOString(),
                  );
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                margin="normal"
                id="datetime-local"
                label="Aktiveringsdatum"
                type="datetime-local"
                variant="outlined"
                name={'activationDate'}
                value={formattedDateAndTime(
                  new Date(new Date(values.activationDate).setMinutes(0)),
                )}
                InputLabelProps={{
                  shrink: true,
                }}
                error={
                  !!errors['activationDate'] &&
                  !!touched['activationDate'] &&
                  !!errors['activationDate']
                }
                helperText={
                  errors['activationDate'] &&
                  touched['activationDate'] &&
                  errors['activationDate']
                }
              />
              <TextField
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                multiline
                rows={4}
                margin="normal"
                name="description"
                value={values['description']}
                placeholder="Beskriv filen med några ord"
                label="Beskrivning"
                error={
                  !!errors['description'] &&
                  !!touched['description'] &&
                  !!errors['description']
                }
                helperText={
                  errors['description'] &&
                  touched['description'] &&
                  errors['description']
                }
              />
            </div>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  toggleNewZipCodesFileDialog();
                  clearAcceptedFile();
                }}
              >
                Avbryt
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Ladda upp
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewZipCodesFileForm;
