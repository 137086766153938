export const newZipCodesFileDragAndDrop = {
  borderRadius: '10px',
  border: '3px dashed lightgrey',
};

export const newZipCodesFileInputBaseStyle = {
  outline: 'none',
  transition: 'border 0.24s ease-in-out',
};

export const newZipCodesFileDragAndDropZone = {
  paddingBottom: '35px',
};

export const newZipCodesFileInputFieldsWrapper = {
  paddingBottom: '25px',
};

export const newZipCodesFileFileInfoList = {
  backgroundColor: '#f1f1f1',
  marginBottom: '25px',
};

export const newZipCodesFileFileRejectionMessageBox = {
  display: 'flex',
  alignItems: 'baseline',
};

export const newZipCodesFileFileRejectionMessageBoxP = {
  paddingLeft: '3px',
};
