import React from 'react';
import {
  Layout,
  Spinner,
  NewStoreDialog,
  StoreList,
} from '../../components/index';
import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { useStoresOverview } from './hooks';

const StoresOverview = () => {
  const {
    filteredStoresBySearch,
    filteredStoresByCategories,
    filteredStores,
    stores,
    newStoreDialogOpen,
    toggleNewStoreDialog,
    handleRequestId,
    handleFilter,
    selectedCategories,
    addToSelectedCategories,
    isSelectedCategory,
    handleClearFilters,
  } = useStoresOverview();

  const breadcrumbs = [
    { text: 'Butiksregister', link: '/' },
    { text: 'Butiker', link: '/butiker' },
  ];

  const props = {
    newStoreDialogOpen,
    toggleNewStoreDialog,
    handleRequestId,
    isSelectedCategory,
    addToSelectedCategories,
  };

  // const navigate = useNavigate();

  const pageTitle = 'Butiker';

  const headerButton = (
    <>
      {/*
      Bortkommenterat tillsvidare, blev problematik med synkning till google när man ändrade en csv-uppladdad 
      exceptionalOpeningHour (2024-05-28T00:00:00 blir 2024-05-27T23:00:00:00Z), detta gör att google-API blir missnöjd 
      <Button
        style={{ color: 'white', backgroundColor: '#66bb6a', marginRight: '1rem' }}
        color="primary"
        variant="contained"
        onClick={() => navigate('/butiker/oppettider')}
      >
        Öppettider - Läs in fil
      </Button> */}
      <Button
        style={{ color: 'white', backgroundColor: '#66bb6a' }}
        color="secondary"
        variant="contained"
        onClick={toggleNewStoreDialog}
      >
        Skapa ny butik
      </Button>
    </>
  );

  return (
    <Layout
      headerButton={headerButton}
      pageTitle={pageTitle}
      title={pageTitle}
      crumbs={breadcrumbs}
    >
      {stores.loading ? (
        <Spinner />
      ) : stores.completed ? (
        <StoreList
          filteredStoresBySearch={filteredStoresBySearch}
          filteredStoresByCategories={filteredStoresByCategories}
          stores={stores.data}
          filteredStores={filteredStores}
          handleFilter={handleFilter}
          handleRequestId={handleRequestId}
          selectedCategories={selectedCategories}
          addToSelectedCategories={addToSelectedCategories}
          isSelectedCategory={isSelectedCategory}
          handleClearFilters={handleClearFilters}
        />
      ) : null}
      <NewStoreDialog {...props} />
    </Layout>
  );
};

export default StoresOverview;
