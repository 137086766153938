export const filterStoresListRoot = {
  textAlign: 'right',
};

export const filterListRoot = {
  width: '495px',
};

export const filterListNested = {
  paddingLeft: '8px',
};

export const filterListClearFiltersButton = {
  color: '#d32f2f',
  borderColor: '#d32f2f',
};
