import React from 'react';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';

function EnhancedTableHead(props) {
  const { filteredStores, setSelected, selected, storesCount } = props;
  const tableHeadData = [
    { id: 'id', label: 'Id', numeric: false },
    { id: 'storeName', label: 'Namn', numeric: false },
    { id: 'monday', label: 'Måndag', numeric: false },
    { id: 'tuesday', label: 'Tisdag', numeric: false },
    { id: 'wednesday', label: 'Onsdag', numeric: false },
    { id: 'thursday', label: 'Torsdag', numeric: false },
    { id: 'friday', label: 'Fredag', numeric: false },
    { id: 'saturday', label: 'Lördag', numeric: false },
    { id: 'sunday', label: 'Söndag', numeric: false },
    { id: 'status', label: 'Status', numeric: false },
  ];

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = filteredStores.map((store) => store.storeId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            onClick={handleSelectAll}
            checked={storesCount > 0 && selected.length === storesCount}
            color="primary"
          />
        </TableCell>
        {tableHeadData.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.numeric ? 'right' : 'left'}
            style={{ fontWeight: '600' }}
          >
            {cell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
